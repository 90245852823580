import { usePreferredLanguages } from "@vueuse/core"
import { cloneDeep, isNumber, isObject } from "lodash"
import {
  getLastKeyInObject,
  getIsSameIso,
  findFirstAvailableKeyInObject,
  findFirstAvailableItemInObject,
} from "~/utils/tools/object"
import { hasProp } from "~/utils/screener/screenerConditionUtils"
import { objsNeedUsdConversion } from "~/utils/ciq/common"

import {
  convertToUsdObject,
  scaleCiqValueObject,
  scaleEstimateDataObject,
} from "~/utils/values/scale"
import {
  calculateCumulativeDps,
  calculateDateDifference,
  calculateIrr,
  calculatePercentage,
} from "~/utils/valuationTransform/valuationMathUtils"
import { calculateCAGR, getObjectNormalized } from "~/modules/ratios"
import dayjs from "~/utils/tools/dayjs"
import { createUtcEpochToShortDateTimeFormatter } from "~/utils/tools/date"
import {
  createCurrencyFormatter,
  createHistoricalTable,
  createNumberFormatter,
  createPercentFormatter,
} from "~/utils/valuationTransform/peModel"
import { getYearKey } from "~/utils/tools"
import { templates } from "~/utils/valuationTransform/peValuationTemplates"

const getMostFilledYearKey = (objects) => {
  if (!objects) {
    return []
  }
  let maxKeys = 0
  let maxKey = null

  Object.keys(objects).forEach((objKey) => {
    const numKeys = Object.keys(objects[objKey]).length
    if (numKeys > maxKeys) {
      maxKeys = numKeys
      maxKey = objKey
    }
  })
  if (!maxKey) {
    return []
  }
  return Object.keys(objects?.[maxKey])
}

const generateYearsKeys = (currentYear, yearsForward) => {
  const years = []
  for (let i = 1; i <= yearsForward; i++) {
    years.push(`${currentYear + i}##FY`)
  }
  return years
}

const getTwoYearAvg = (source, key) => {
  const yearPrior = getYearKey(key, -1)
  const twoYearsPrior = getYearKey(key, -2)
  if (!source[yearPrior]?.v || !source[twoYearsPrior]?.v) {
    return null
  }
  const average = (source[yearPrior].v + source[twoYearsPrior].v) / 2
  return average
}

const getInitialValue = (
  isEstimate,
  source,
  key,
  lastAvailableValue,
  mostRecentActualDateKeyStr,
  priceOverEarningsThreeYearAvg,
  metricKey
) => {
  const isPriceOverEarningsMultiple = metricKey === "priceOverEarningsMultiple"
  const isRevenueGrowth = metricKey === "revenueChangeYoY"
  const isTaxes = metricKey === "taxesPercentOfEBT"
  const isDSO = metricKey === "dsoChangeYoY"
  const value = source?.[key]?.v
  if (!isEstimate) {
    return value
  }
  const numEstimates = source?.[key]?.numEst
  if (numEstimates >= 3) {
    return value || lastAvailableValue
  }
  const [mostRecentActualDateKeyYear] = mostRecentActualDateKeyStr
    .split("##")
    .map(Number)
  const [itemYear] = key.split("##").map(Number)
  const isFirstEstimate = itemYear === mostRecentActualDateKeyYear + 1
  // if is the first estimate
  if (isFirstEstimate && isPriceOverEarningsMultiple) {
    return value || lastAvailableValue
  } else if (!isFirstEstimate && isRevenueGrowth) {
    // 90% of the first estimate
    const newValue = calculatePercentage(lastAvailableValue, 90) / 100
    return newValue
  } else if (isPriceOverEarningsMultiple) {
    const lastCloseValue = source?.[mostRecentActualDateKeyStr]?.v
    const isAveragePositive = priceOverEarningsThreeYearAvg > 0
    if (isAveragePositive) {
      return Math.min(priceOverEarningsThreeYearAvg, lastCloseValue)
    } else {
      return lastCloseValue
    }
  } else if (isTaxes) {
    if (numEstimates < 2 || !numEstimates) {
      return -0.2
    }
  } else if (isDSO) {
    if (numEstimates < 2 || !numEstimates) {
      return 0
    }
  }
  const averageFromLastTwoYears = getTwoYearAvg(source, key)
  return averageFromLastTwoYears || lastAvailableValue
}

const getMultiplicator = (
  currentMultiplicatorValueOrFn,
  initialValue,
  metricKey,
  multipliersObject,
  dateKey
) => {
  const multiplier = multipliersObject?.[metricKey]?.[dateKey]
  if (isFinite(multiplier)) {
    return multiplier
  }
  if (typeof currentMultiplicatorValueOrFn === "function") {
    return currentMultiplicatorValueOrFn(initialValue)
  }
  return currentMultiplicatorValueOrFn
}

// Right now this is only retrieving the data from the original
// initialVendorDataObj from the respective year.
// TODO: some of these values will be an average

const getUserInputDataObject = (
  initialVendorDataObj = {},
  editableRowsArr = [],
  mostRecentActualDateKeyStr,
  amountOfYearsForward,
  multiplicatorsObj = {},
  isDPSAvailable,
  priceOverEarningsThreeYearAvg,
  multipliersObject = {}
) => {
  if (!mostRecentActualDateKeyStr) {
    return {}
  }
  const [mostRecentActualYear] = mostRecentActualDateKeyStr.split("##")
  const estimatesYearArr = generateYearsKeys(
    Number(mostRecentActualYear),
    amountOfYearsForward
  )
  const assumptionCaseMultipliers = {}
  const data = editableRowsArr.reduce((acc, item) => {
    const isPriceOverEarningsMultiple =
      item.metricKey === "priceOverEarningsMultiple"
    const isDPSPayoutRatio = item.metricKey === "dividendsPerSharePayoutRatio"
    const defaultInputValue = isDPSPayoutRatio && !isDPSAvailable ? 0 : 0.01
    const source = initialVendorDataObj?.[item.metricKey]
    const keys = isPriceOverEarningsMultiple
      ? getMostFilledYearKey(acc)
      : Object.keys(source)

    acc[item.metricKey] = {}
    let lastAvailableValue = item.defaultValue || defaultInputValue
    keys.forEach((key) => {
      const isEstimate = key > mostRecentActualDateKeyStr
      const initialValue = getInitialValue(
        isEstimate,
        source,
        key,
        lastAvailableValue,
        mostRecentActualDateKeyStr,
        priceOverEarningsThreeYearAvg,
        item.metricKey
      )
      if (isEstimate && isNumber(initialValue)) {
        lastAvailableValue = initialValue
        const multiplicator = getMultiplicator(
          multiplicatorsObj?.[item.metricKey],
          initialValue,
          item.metricKey,
          multipliersObject,
          key
        )
        const assumptionMultiplicator = multiplicator || 1
        if (!assumptionCaseMultipliers[item.metricKey]) {
          assumptionCaseMultipliers[item.metricKey] = {}
        }
        assumptionCaseMultipliers[item.metricKey][key] = assumptionMultiplicator
        acc[item.metricKey][key] = initialValue * assumptionMultiplicator
      }
    })
    estimatesYearArr.forEach((estDateKey) => {
      if (!hasProp(acc[item.metricKey], estDateKey)) {
        acc[item.metricKey][estDateKey] = {}
        acc[item.metricKey][estDateKey] = lastAvailableValue
      }
    })
    return acc
  }, {})

  return { data, assumptionCaseMultipliers }
}

const getCellCalcFn = (
  key,
  initialEstimatesObj = [],
  computedMetricsArr = [],
  valuationVendorObj = []
) => {
  const currentInitialEstimateObj = initialEstimatesObj?.find(
    (item) => item.metricKey === key
  )
  if (currentInitialEstimateObj?.cellCalc) {
    return {
      fn: currentInitialEstimateObj?.cellCalc,
      cascade: currentInitialEstimateObj?.cascade,
    }
  }
  const currentValuationObj = valuationVendorObj?.find(
    (item) => item.metricKey === key
  )
  if (currentValuationObj?.cellCalc) {
    return {
      fn: currentValuationObj?.cellCalc,
      cascade: currentValuationObj?.cascade,
    }
  }

  const calculatedFromVendor = computedMetricsArr?.find(
    (item) => item.newCalculatedMetricKey === key
  )
  return {
    fn: calculatedFromVendor?.cellCalc || null,
    cascade: calculatedFromVendor?.cascade || null,
  }
}

const cascadeThroughDeps = (
  cascKey,
  initialEstimatesObj,
  computedMetricsArr,
  initVendorDataObj,
  dateKeyStr,
  userInputDataObj,
  acc,
  lastActualDateKeyStr,
  fullfilledDates
) => {
  const cascKeyCalcObj = getCellCalcFn(
    cascKey,
    initialEstimatesObj,
    computedMetricsArr
  )
  if (cascKeyCalcObj.fn) {
    if (initVendorDataObj?.[cascKey]?.[dateKeyStr]) {
      const updated = cascKeyCalcObj.fn({
        dateKeyStr,
        vendorObj: initVendorDataObj,
        userObj: userInputDataObj,
        accObj: acc,
        lastActualDateKeyStr,
        dates: fullfilledDates,
      })
      const accObj = acc?.[cascKey]?.[dateKeyStr] || {}
      const vendorObj = initVendorDataObj?.[cascKey]?.[dateKeyStr] || {}
      if (!acc?.[cascKey]) {
        acc[cascKey] = {}
      }
      if (!acc?.[cascKey]?.[dateKeyStr]) {
        acc[cascKey][dateKeyStr] = {}
      }
      acc[cascKey][dateKeyStr] = {
        ...vendorObj,
        ...accObj,
        ...updated,
      }
    }
  }
}

const generateUpdatedRow = (
  dateKeyStr,
  idx,
  key,
  initialEstimatesObj,
  computedMetricsArr,
  initVendorDataObj,
  userInputDataObj,
  acc,
  lastActualDateKeyStr,
  userState,
  valuationMetrics,
  fullfilledDates
) => {
  const { fn, cascade } = getCellCalcFn(
    key,
    initialEstimatesObj,
    computedMetricsArr,
    valuationMetrics
  )

  if (fn) {
    const updatedValue = fn({
      dateKeyStr,
      vendorObj: initVendorDataObj,
      userObj: userInputDataObj,
      accObj: acc,
      lastActualDateKeyStr,
      dates: fullfilledDates,
    })

    const isEstimateKey = dateKeyStr > lastActualDateKeyStr
    const isValidNumber = !Number.isNaN(updatedValue?.v)

    if (isEstimateKey && isValidNumber) {
      if (!acc[key][dateKeyStr]) {
        acc[key][dateKeyStr] = {}
      }
      if (isObject(updatedValue)) {
        acc[key][dateKeyStr] = {
          ...acc[key][dateKeyStr],
          ...updatedValue,
        }
      } else {
        acc[key][dateKeyStr].v = updatedValue
      }
    }
    if (cascade) {
      cascade.forEach((cascKey) =>
        cascadeThroughDeps(
          cascKey,
          initialEstimatesObj,
          computedMetricsArr,
          initVendorDataObj,
          dateKeyStr,
          userInputDataObj,
          acc,
          lastActualDateKeyStr,
          fullfilledDates
        )
      )
    }
  }
  // this one might not exist
  const userObjExists = Object.keys(userState).length > 0
  if (userObjExists && idx === 0) {
    Object.keys(userState).forEach((userDateKeyStr) => {
      const userVal = userState[userDateKeyStr]
      // todo: since some of this rows dont exists in the
      // estimates, this object may only contain one value

      if (!hasProp(acc[key][userDateKeyStr], "v")) {
        acc[key][userDateKeyStr] = {}
      }
      acc[key][userDateKeyStr].v = userVal
    })
  }
}

const updateValuationOutput = (
  initVendorDataObj,
  userInputDataObj,
  initialEstimatesObj,
  lastActualDateKeyStr,
  computedMetricsArr,
  valuationMetrics,
  fullfilledDates
) => {
  const keys = Object.keys(initVendorDataObj)
  const index = keys.indexOf("valuationEpsNormalized")
  const netIncomeMarginIdx = keys.indexOf("netIncomeMargin")

  if (netIncomeMarginIdx !== -1) {
    keys.splice(netIncomeMarginIdx, 1)
    keys.push("netIncomeMargin")
  }

  if (index !== -1) {
    keys.splice(index, 1)
    keys.push("valuationEpsNormalized")
  }
  const objs = keys.reduce((acc, key) => {
    const vendorState = cloneDeep(initVendorDataObj[key])
    const userState = userInputDataObj?.[key] || {}
    acc[key] = {
      ...vendorState,
    }
    Object.keys(vendorState)
      .sort()
      .filter((key) => key > lastActualDateKeyStr)
      .forEach((dateKeyStr, idx) => {
        return generateUpdatedRow(
          dateKeyStr,
          idx,
          key,
          initialEstimatesObj,
          computedMetricsArr,
          initVendorDataObj,
          userInputDataObj,
          acc,
          lastActualDateKeyStr,
          userState,
          valuationMetrics,
          fullfilledDates
        )
      })
    return acc
  }, {})
  return objs
}

const normalizeStockPriceObject = (obj) => {
  return {
    v: obj?.c,
    iso: obj?.mciso,
    pc: Number(obj?.mcpc),
    unauth: false,
    dT: obj?.mcd,
    dV: dayjs.utc(obj?.mcd).valueOf(),
  }
}

const createValuationOutputFromHubData = ({
  hubData,
  latestStockPrice,
  cases,
  yearsAhead,
}) => {
  const mostRecentActualDateKeyStr = hubData?.mostRecentActualDateKeyStr
  const initVendor = hubData?.valuationOutput || {}
  const userInputDataObjs = hubData?.userInputs || []
  const { calculatedMetrics, estimatesDataArray, valuationDataArray } =
    templates
  const fullfilledDates = hubData?.fullfilledDates
  const normalizedStockPrice = normalizeStockPriceObject(latestStockPrice)
  const stockPriceObj = initVendor?.stockPrice || {}
  const updatedStockPriceObj = Object.keys(stockPriceObj).reduce((acc, key) => {
    if (key === "latest" || key > mostRecentActualDateKeyStr) {
      acc[key] = normalizedStockPrice
      return acc
    }
    acc[key] = stockPriceObj[key]
    return acc
  }, {})
  const updatedVendor = {
    ...initVendor,
    stockPrice: updatedStockPriceObj,
  }

  const assumptionsOutput = cases.map((_, idx) => {
    return updateValuationOutput(
      updatedVendor,
      userInputDataObjs[idx],
      estimatesDataArray,
      mostRecentActualDateKeyStr,
      calculatedMetrics,
      valuationDataArray,
      fullfilledDates
    )
  })
  const [mostRecentActualYear] = mostRecentActualDateKeyStr
    .split("##")
    .map(Number)
  const yearStart = mostRecentActualYear
  const yearEnd = mostRecentActualYear + yearsAhead + 1
  const midCase = assumptionsOutput[1]
  const keyToLookup = `${yearEnd - 1}##FY`
  const targetPrice = midCase?.totalStockPriceIncludingDividends[keyToLookup]
  const stockPrice = midCase?.stockPrice[keyToLookup]
  const irr = midCase?.annualizedReturn[keyToLookup]
  const realizedAtDateObj = fullfilledDates.find(
    (item) => item.calendarYear === yearEnd
  )

  const revenueCagr = getRevenueCagr(midCase, yearStart, yearEnd)
  const epsCagr = getEpsCagr(midCase, yearStart, yearEnd)
  const peChange = getPeChange(midCase, yearStart, yearEnd)

  const estReturn = {
    v: targetPrice?.v / stockPrice?.v - 1,
    unauth: targetPrice?.unauth || stockPrice?.unauth,
  }
  return {
    latest: stockPriceObj.latest,
    realizedAtDate: realizedAtDateObj,
    targetPrice,
    estimatedReturn: estReturn,
    irr,
    forecastRevenueCagr: revenueCagr,
    forecastEpsCagr: epsCagr,
    forecastPeChange: peChange,
  }
}

const getRevenueCagr = (caseOutput, yearStart, yearEnd) => {
  const yearStartKey = `${yearStart}##FY`
  const yearEndKey = `${yearEnd}##FY`
  const revenueStart = caseOutput?.revenue[yearStartKey]
  const revenueEnd = caseOutput?.revenue[yearEndKey]
  const { cagr } = calculateCAGR({
    initialValue: revenueStart?.v,
    lastValue: revenueEnd?.v,
    firstDate: revenueStart?.timeVal,
    lastDate: revenueEnd?.timeVal,
  })
  return {
    v: cagr,
    unauth: revenueStart?.unauth || revenueEnd?.unauth,
  }
}

const getEpsCagr = (caseOutput, yearStart, yearEnd) => {
  const yearStartKey = `${yearStart}##FY`
  const yearEndKey = `${yearEnd}##FY`
  const epsStart = caseOutput?.eps[yearStartKey]
  const epsEnd = caseOutput?.eps[yearEndKey]
  const { cagr } = calculateCAGR({
    initialValue: epsStart?.v,
    lastValue: epsEnd?.v,
    firstDate: epsStart?.timeVal,
    lastDate: epsEnd?.timeVal,
  })
  return {
    v: cagr,
    unauth: epsStart?.unauth || epsEnd?.unauth,
  }
}

const getPeChange = (caseOutput, yearStart, yearEnd) => {
  const yearStartKey = `${yearStart}##FY`
  const yearEndKey = `${yearEnd - 1}##FY`
  const peStart = caseOutput?.priceOverEarningsMultiple[yearStartKey]
  const peEnd = caseOutput?.priceOverEarningsMultiple[yearEndKey]
  const epsStart = caseOutput.eps[yearStartKey]
  const epsEnd = caseOutput.eps[yearEndKey]
  const { cagr } = calculateCAGR({
    initialValue: peStart?.v,
    lastValue: peEnd?.v,
    firstDate: epsStart?.timeVal,
    lastDate: epsEnd?.timeVal,
  })
  return {
    v: cagr,
    unauth: peStart?.unauth || peEnd?.unauth,
  }
}

const getFullfilledDatesFromState = (state) => {
  const $newStoreDataObject = state.data.storeDataObject
  const currentDataset = state.data.currentDataset
  return $newStoreDataObject?.datasets?.[currentDataset]?.fullfilledDates
}

const generateAssumptionsOutput = (state) => {
  const $newStoreDataObject = state.data.storeDataObject
  const currentDataset = state.data.currentDataset
  const allDates =
    $newStoreDataObject?.datasets?.[currentDataset]?.fullfilledDates
  const initVendorDataObj =
    $newStoreDataObject?.datasets?.[currentDataset]?.initVendorDataObj
  const estimatesDataArray = state.templates.estimatesDataArray
  const mostRecentActualDateKeyStr =
    $newStoreDataObject?.datasets?.[currentDataset]?.mostRecentActualDateKeyStr
  const cases =
    state.templates.assumptionCases.length > 0
      ? state.templates.assumptionCases
      : [{}]
  const assumptionsOutputArray = cases.map((_, idx) => {
    const currentUserInputDataObj = state.data.userInputsDataArray[idx]
    return updateValuationOutput(
      initVendorDataObj,
      currentUserInputDataObj,
      estimatesDataArray,
      mostRecentActualDateKeyStr,
      state.templates.calculatedMetrics,
      state.templates.valuationDataArray,
      allDates
    )
  })
  return assumptionsOutputArray
}

const getFormatters = (state) => {
  const langs = usePreferredLanguages()
  const decimals = state.data.filters.decimals
  const $newStoreDataObject = state.data.storeDataObject
  const currentDataset = state.data.currentDataset
  const currencies = $newStoreDataObject?.datasets?.[currentDataset]?.currencies
  const currentIso = currencies?.[state.data.filters.currency]?.code
  const defaultObj = {
    minDigits: decimals,
    maxDigits: decimals,
    lang: langs.value[0],
  }

  const utcEpochToShortDate = createUtcEpochToShortDateTimeFormatter(
    langs.value[0]
  )
  const numberFormatter = createNumberFormatter(defaultObj)
  const percentFormatter = createPercentFormatter(defaultObj)
  const currencyFormatter = createCurrencyFormatter({
    ...defaultObj,
    isocode: currentIso,
  })

  return {
    dateFormatter: utcEpochToShortDate,
    formatNumber: numberFormatter,
    formatPercent: percentFormatter,
    formatCurrency: currencyFormatter,
  }
}

const generateHistoricalTable = (
  state,
  assumptionsOutput,
  formatters,
  currentUserTier
) => {
  const templateRows = state.templates.historicalRows
  const $newStoreDataObject = state.data.storeDataObject
  const currentDataset = state.data.currentDataset
  const mostRecentActualDateKeyStr =
    $newStoreDataObject?.datasets?.[currentDataset]?.mostRecentActualDateKeyStr
  const fullfilledDates =
    $newStoreDataObject?.datasets?.[currentDataset]?.fullfilledDates
  const currentAssumptionCase = state.data.filters.assumptionCase
  const valuationOutput = assumptionsOutput[currentAssumptionCase]
  return createHistoricalTable({
    templateRows,
    valuationOutput,
    mostRecentActualDateKeyStr,
    dateFormatter: formatters.dateFormatter,
    numberFormatter: formatters.formatNumber,
    percentFormatter: formatters.formatPercent,
    currencyFormatter: formatters.formatCurrency,
    fullfilledDates,
    currentUserTier,
  })
}

const getLastStockPriceObj = (state) => {
  const $newStoreDataObject = state.data.storeDataObject
  return $newStoreDataObject.stockValues?.latest
}

const calculateDivision = (
  dataVendorObj,
  metricKey,
  newCalculatedMetricKey,
  denominatorKey,
  valuationDataObj,
  accObj,
  allKeys,
  mostRecentActualDateKey
) => {
  const num =
    dataVendorObj?.[metricKey] ||
    valuationDataObj?.[metricKey] ||
    accObj?.[metricKey] ||
    {}
  const den =
    dataVendorObj?.[denominatorKey] ||
    valuationDataObj?.[denominatorKey] ||
    accObj?.[denominatorKey] ||
    {}

  const items = allKeys.reduce((acc, dateKey) => {
    const numObj = num?.[dateKey]
    let denObj = den?.[dateKey]
    if (denominatorKey === "valuationEpsNormalized" && !denObj) {
      denObj = dataVendorObj.eps[dateKey]
    }
    const isSameIso = getIsSameIso([numObj, denObj])
    const normalizedNum = getObjectNormalized(numObj, isSameIso)
    const normalizedDen = getObjectNormalized(denObj, isSameIso)
    const numValue = scaleCiqValueObject({
      dataObj: normalizedNum,
      fallbackValue: null,
    })
    const denValue = scaleCiqValueObject({
      dataObj: normalizedDen,
      fallbackValue: null,
    })
    const objArr = [numObj, denObj]
    const datesArr = [dateKey]
    const isEstimates = checkIfEstimatesArr(mostRecentActualDateKey, datesArr)
    let division = numValue / denValue
    const unauth = numObj?.unauth || denObj?.unauth

    // prevent the ntm p/e from checking if has a
    // number of estimates because it wont have
    if (isEstimates && metricKey !== "stockPrice") {
      const isValidEstimates = objArr.every((i) => i?.numEst >= 3)
      const value = isValidEstimates
        ? division
        : getTwoYearPriorAvg(acc, dateKey)

      acc[dateKey] = {
        ...numObj,
        v: value,
        unauth,
      }
      return acc
    }

    const isInvalidDivision =
      unauth ||
      !denObj ||
      !numObj ||
      Number.isNaN(numValue) ||
      Number.isNaN(denValue)

    if (isInvalidDivision) {
      division = null
    }

    acc[dateKey] = {
      ...numObj,
      v: division,
      unauth,
    }
    return acc
  }, {})

  return {
    [newCalculatedMetricKey]: items,
  }
}

const calculateDivisionMinusOne = (
  dataVendorObj,
  metricKey,
  newCalculatedMetricKey,
  denominatorKey,
  valuationDataObj,
  accDataObj,
  allKeys,
  mostRecentActualDateKey
) => {
  const num =
    dataVendorObj[metricKey] ||
    valuationDataObj[metricKey] ||
    accDataObj[metricKey]
  const den =
    dataVendorObj[denominatorKey] ||
    valuationDataObj[denominatorKey] ||
    accDataObj[denominatorKey]
  const items = allKeys.reduce((acc, dateKey) => {
    const numObj = num?.[dateKey]
    const denObj = den?.[dateKey]
    const isSameIso = getIsSameIso([numObj, denObj])
    const normalizedNum = getObjectNormalized(numObj, isSameIso)
    const normalizedDen = getObjectNormalized(denObj, isSameIso)
    const numValue = scaleCiqValueObject({
      dataObj: normalizedNum,
      fallbackValue: null,
    })
    const denValue = scaleCiqValueObject({
      dataObj: normalizedDen,
      fallbackValue: null,
    })
    const unauth = numObj?.unauth || denObj?.unauth
    let division = numValue / denValue - 1
    const objArr = [numObj, denObj]
    const datesArr = [dateKey]
    const isEstimates = checkIfEstimatesArr(mostRecentActualDateKey, datesArr)

    if (isEstimates) {
      const isValidEstimates = objArr.every((i) => i?.numEst >= 3)
      const value = isValidEstimates
        ? division
        : getTwoYearPriorAvg(acc, dateKey)
      acc[dateKey] = {
        ...numObj,
        v: value,
        unauth,
      }
      return acc
    }

    if (unauth || !denObj || !numObj) {
      division = null
    }
    acc[dateKey] = {
      ...numObj,
      v: division,
      unauth,
    }
    return acc
  }, {})

  return {
    [newCalculatedMetricKey]: items,
  }
}

const calculateMultiplication = (
  dataVendorObj,
  valuationVendorObj,
  accDataObj,
  firstMetric,
  secondMetric,
  newCalculatedMetricKey,
  allKeys,
  mostRecentActualDateKey
) => {
  const firstMetricObj =
    dataVendorObj?.[firstMetric] ||
    valuationVendorObj?.[firstMetric] ||
    accDataObj?.[firstMetric] ||
    {}
  const secondMetricObj =
    dataVendorObj?.[secondMetric] ||
    valuationVendorObj?.[secondMetric] ||
    accDataObj?.[secondMetric] ||
    {}
  const items = allKeys.reduce((acc, dateKey) => {
    const firstObj =
      firstMetricObj?.[dateKey] ||
      valuationVendorObj?.[firstMetric]?.latest ||
      accDataObj?.[dateKey] ||
      {}
    const scaledFirstObjVal = scaleCiqValueObject({
      dataObj: firstObj,
      fallbackValue: null,
    })
    const secondObj =
      secondMetricObj?.[dateKey] ||
      valuationVendorObj?.[secondMetric]?.latest ||
      accDataObj?.[dateKey] ||
      {}
    const isSameIso = getIsSameIso([firstObj, secondObj])
    const normalizedFirstObj = getObjectNormalized(firstObj, isSameIso)
    const normalizedSecondObj = getObjectNormalized(secondObj, isSameIso)
    const firstObjectValue = scaleCiqValueObject({
      dataObj: normalizedFirstObj,
      fallbackValue: null,
    })
    const secondObjectValue = scaleCiqValueObject({
      dataObj: normalizedSecondObj,
      fallbackValue: null,
    })

    const scaledSecondObjVal = scaleCiqValueObject({
      dataObj: secondObj,
      fallbackValue: null,
    })

    const unauth = firstObj?.unauth || secondObj?.unauth
    let finalValue = firstObjectValue * secondObjectValue
    const objArr = [firstObj, secondObj]
    const datesArr = [dateKey]
    const isEstimates = checkIfEstimatesArr(mostRecentActualDateKey, datesArr)

    if (isEstimates) {
      const isValidEstimates = objArr.every((i) => i?.numEst >= 3)
      const value = isValidEstimates
        ? finalValue
        : getTwoYearPriorAvg(acc, dateKey)
      acc[dateKey] = {
        ...firstObj,
        v: value,
        unauth,
      }
      return acc
    }

    if (unauth || !firstObj || !secondObj) {
      finalValue = null
    }
    acc[dateKey] = {
      ...firstObj,
      v: finalValue,
      unauth,
      dependencies: {
        firstObj,
        scaledFirstObjVal,
        secondObj,
        scaledSecondObjVal,
        finalValue,
      },
    }
    return acc
  }, {})
  return {
    [newCalculatedMetricKey]: items,
  }
}

const calculateMinus = (
  dataVendorObj,
  firstMetric,
  secondMetric,
  newCalculatedMetricKey,
  allKeys,
  mostRecentActualDateKey
) => {
  const items = allKeys.reduce((acc, dateKey) => {
    const firstObj = dataVendorObj?.[firstMetric]?.[dateKey] || {}
    const secondObj = dataVendorObj?.[secondMetric]?.[dateKey] || {}
    const isSameIso = getIsSameIso([firstObj, secondObj])
    const normalizedFirstObj = getObjectNormalized(firstObj, isSameIso)
    const normalizedSecondObj = getObjectNormalized(secondObj, isSameIso)
    const scaledFirstVal = scaleCiqValueObject({
      dataObj: normalizedFirstObj,
      fallbackValue: null,
    })
    const scaledSecondVal = scaleCiqValueObject({
      dataObj: normalizedSecondObj,
      fallbackValue: null,
    })

    const unauth = firstObj?.unauth || secondObj?.unauth
    let finalValue = scaledFirstVal - scaledSecondVal
    const isValid = !Number.isNaN(finalValue)
    const objArr = [firstObj, secondObj]
    const datesArr = [dateKey]
    const isEstimates = checkIfEstimatesArr(mostRecentActualDateKey, datesArr)

    if (isEstimates) {
      const isValidEstimates = objArr.every((i) => i?.numEst >= 3)
      const value = isValidEstimates
        ? finalValue
        : getTwoYearPriorAvg(acc, dateKey)
      acc[dateKey] = {
        ...firstObj,
        v: value,
        unauth,
      }
      return acc
    }

    if (unauth || !firstObj || !secondObj) {
      finalValue = null
    }

    if (isValid) {
      acc[dateKey] = {
        ...firstObj,
        v: finalValue,
        unauth,
      }
    } else {
      acc[dateKey] = {}
    }

    return acc
  }, {})
  return {
    [newCalculatedMetricKey]: items,
  }
}

// TODO: This isn't returning exactly 50% of the dps.
// Can this be an issue?
const calculateComputedCumulativeDPS = (
  allDates,
  newCalculatedMetricKey,
  dpsObj,
  allKeys
) => {
  const items = allKeys.reduce((acc, key, index) => {
    const item = allDates[index]
    const dpsDateObjectFromPriorYear = dpsObj?.[allKeys[index - 1]]
    const scaledDpsDateObjFromPriorYearVal = scaleCiqValueObject({
      dataObj: dpsDateObjectFromPriorYear,
      fallbackValue: null,
    })
    const date = dayjs(item.epoch).format("DD/MM/YY")
    let diff = calculateDateDifference(date, scaledDpsDateObjFromPriorYearVal)

    if (index === 0 || dpsDateObjectFromPriorYear?.unauth) {
      diff = null
    }

    if (!item.isEstimate) {
      diff = 0
    }
    if (index > 0) {
      acc[item.dateKey] = {
        ...dpsDateObjectFromPriorYear,
        v: diff,
        dependencies: { diff },
      }
    }

    return acc
  }, {})
  return {
    [newCalculatedMetricKey]: items,
  }
}

const calculateSum = (
  dataVendorObj,
  firstMetric,
  secondMetric,
  newCalculatedMetricKey,
  accDataObj,
  allKeys,
  mostRecentActualDateKey
) => {
  const firstMetricObj =
    accDataObj?.[firstMetric] || dataVendorObj?.[firstMetric]
  const secondMetricObj =
    accDataObj?.[secondMetric] || dataVendorObj?.[secondMetric]

  const items = allKeys.reduce((acc, dateKey) => {
    const firstObj = firstMetricObj?.[dateKey] || {}
    const secondObj = secondMetricObj?.[dateKey] || {}
    const isSameIso = getIsSameIso([firstObj, secondObj])
    const normalizedFirstObj = getObjectNormalized(firstObj, isSameIso)
    const normalizedSecondObj = getObjectNormalized(secondObj, isSameIso)

    const firstValue = scaleCiqValueObject({
      dataObj: normalizedFirstObj,
      fallbackValue: null,
    })
    const secondValue = scaleCiqValueObject({
      dataObj: normalizedSecondObj,
      fallbackValue: null,
    })

    let finalValue = firstValue + secondValue
    const unauth = firstObj?.unauth || secondObj?.unauth
    const isValid = isFinite(firstObj?.v) && isFinite(secondObj?.v)
    const objArr = [firstObj, secondObj]
    const datesArr = [dateKey]
    const isEstimates = checkIfEstimatesArr(mostRecentActualDateKey, datesArr)

    if (isEstimates) {
      const isValidEstimates = objArr.every((i) => i?.numEst >= 3)
      const value = isValidEstimates
        ? finalValue
        : getTwoYearPriorAvg(acc, dateKey)
      acc[dateKey] = {
        ...firstObj,
        v: value,
        unauth,
      }
      return acc
    }

    if (unauth || !isValid) {
      finalValue = null
    }

    if (isValid) {
      acc[dateKey] = {
        ...firstObj,
        v: finalValue,
        unauth,
      }
    } else {
      acc[dateKey] = {}
    }

    return acc
  }, {})
  return {
    [newCalculatedMetricKey]: items,
  }
}

const calculateAnnualizedReturn = (
  accDataObj,
  datesArr,
  newCalculatedMetricKey,
  allKeys
) => {
  const upsideOrDownside = accDataObj.upsideOrDownsideToCurrentStockPrice
  const items = allKeys.reduce((acc, _, index) => {
    const item = datesArr[index]
    const currentUpsideOrDownside = upsideOrDownside?.[item.dateKey] || {}
    const scaledUpsideOrDownsideVal = scaleCiqValueObject({
      dataObj: currentUpsideOrDownside,
    })
    let finalDateObj = dayjs(item.epoch).endOf("year")
    const finalDateYear = finalDateObj.year()
    const currentYear = datesArr[index]
    if (finalDateYear !== currentYear.calendarYear) {
      const yearDiff = currentYear.calendarYear - finalDateYear
      finalDateObj = dayjs(finalDateObj.valueOf()).add(yearDiff, "year")
    }
    let finalValue = calculateIrr(
      scaledUpsideOrDownsideVal,
      finalDateObj.format("DD/MM/YY"),
      new Date()
    )
    const isValidObj = Object.keys(currentUpsideOrDownside).length > 0

    if (
      currentUpsideOrDownside?.unauth ||
      Number.isNaN(scaledUpsideOrDownsideVal) ||
      !isValidObj
    ) {
      finalValue = null
    }
    acc[item.dateKey] = {
      ...currentUpsideOrDownside,
      v: finalValue,
      dV: finalDateObj.valueOf(),
    }

    return acc
  }, {})
  return {
    [newCalculatedMetricKey]: items,
  }
}

const getTwoYearPriorAvg = (acc, dateKey) => {
  const yearPrior = getYearKey(dateKey, -1)
  const twoYearsPrior = getYearKey(dateKey, -2)
  const yearPriorValue = acc?.[yearPrior]?.v
  const twoYearsPriorValue = acc?.[twoYearsPrior]?.v
  if (yearPriorValue && twoYearsPriorValue) {
    return (yearPriorValue + twoYearsPriorValue) / 2
  }
  return null
}

const checkIfEstimatesArr = (mostRecentDateKey, datesArr) => {
  const isEveryDateAnEstimate = datesArr.every((key) => key > mostRecentDateKey)
  return isEveryDateAnEstimate
}

const calculateYoYPctChange = (
  dataVendorObj,
  metricKey,
  newCalculatedMetricKey,
  accDataObj,
  currentIso,
  allKeys,
  mostRecentActualDateKey
) => {
  const sourceObj = dataVendorObj?.[metricKey] || accDataObj?.[metricKey]
  const items = allKeys.reduce((acc, dateKey, index) => {
    const item = sourceObj?.[dateKey]
    const currentYearValueFallback = scaleCiqValueObject({
      dataObj: item,
      fallbackValue: null,
    })
    const yearPriorKey = index === 0 ? false : allKeys[index - 1]
    const yearPrior = sourceObj?.[yearPriorKey]
    const needUsdConversion = objsNeedUsdConversion(currentIso, [
      item,
      yearPrior,
    ])
    const yearPriorValueFallback = scaleCiqValueObject({
      dataObj: yearPrior,
      fallbackValue: null,
    })
    const currentYearValue = scaleAndConvertObj(
      item,
      needUsdConversion,
      currentYearValueFallback
    )
    const yearPriorValue = scaleAndConvertObj(
      yearPrior,
      needUsdConversion,
      yearPriorValueFallback
    )
    let yoyChange = currentYearValue / yearPriorValue - 1
    const objsArr = [item, yearPrior]
    const unauth = objsArr.some((i) => i?.unauth === true)
    const datesArr = [dateKey, yearPriorKey]
    const isEstimates = checkIfEstimatesArr(mostRecentActualDateKey, datesArr)

    if (isEstimates) {
      const isValidEstimates = objsArr.every((i) => i?.numEst >= 3)
      const value = isValidEstimates
        ? yoyChange
        : getTwoYearPriorAvg(acc, dateKey)
      acc[dateKey] = {
        ...item,
        v: value,
        unauth,
      }
      return acc
    }

    // if it has no real data to compare
    if (unauth || !item || !yearPrior) {
      yoyChange = null
    }
    if (isFinite(yoyChange)) {
      acc[dateKey] = {
        ...item,
        v: yoyChange,
        unauth,
      }
    }

    return acc
  }, {})
  return {
    [newCalculatedMetricKey]: items,
  }
}

const scaleAndConvertObj = (
  dataObj,
  needUsdConversion = false,
  fallbackValue = 0
) => {
  if (dataObj?.unauth) {
    return fallbackValue
  }

  if (needUsdConversion) {
    const objInUSD = convertToUsdObject(dataObj, fallbackValue)
    return scaleEstimateDataObject({ dataObj: objInUSD, fallbackValue })
  }

  return scaleEstimateDataObject({ dataObj, fallbackValue })
}

const calculateIncrementalMargin = (
  dataVendorObj,
  metricKey,
  newCalculatedMetricKey,
  denominatorKey,
  dateKeysArr
) => {
  const num = dataVendorObj[metricKey]
  const den = dataVendorObj[denominatorKey]
  const items = dateKeysArr.reduce((acc, dateKey, index) => {
    const yearPriorKey = index === 0 ? false : dateKeysArr[index - 1]
    let numObj = num?.[dateKey] || {}
    let numYearPriorObj = num?.[yearPriorKey] || {}
    let denObj = den?.[dateKey] || {}
    let denYearPriorObj = den?.[yearPriorKey] || {}
    const isSameIso = getIsSameIso([
      numObj,
      numYearPriorObj,
      denObj,
      denYearPriorObj,
    ])
    let numValue = scaleCiqValueObject({
      dataObj: numObj,
      fallbackValue: null,
    })
    let numYearPriorValue = scaleCiqValueObject({
      dataObj: numYearPriorObj,
      fallbackValue: null,
    })
    let denValue = scaleCiqValueObject({
      dataObj: denObj,
      fallbackValue: null,
    })
    let denYearPriorValue = scaleCiqValueObject({
      dataObj: denYearPriorObj,
      fallbackValue: null,
    })
    if (!isSameIso) {
      numObj = convertToUsdObject(numObj, numValue)
      numYearPriorObj = convertToUsdObject(numYearPriorObj, numYearPriorValue)
      denObj = convertToUsdObject(denObj, denValue)
      denYearPriorObj = convertToUsdObject(denYearPriorObj, denYearPriorValue)

      numValue = scaleEstimateDataObject({
        dataObj: numObj,
        fallbackValue: null,
      })
      numYearPriorValue = scaleEstimateDataObject({
        dataObj: numYearPriorObj,
        fallbackValue: null,
      })
      denValue = scaleEstimateDataObject({
        dataObj: denObj,
        fallbackValue: null,
      })
      denYearPriorValue = scaleEstimateDataObject({
        dataObj: denYearPriorObj,
        fallbackValue: null,
      })
    }
    const numFinalVal = numValue - numYearPriorValue
    const denFinalVal = denValue - denYearPriorValue
    const unauth = [numObj, numYearPriorObj, denObj, denYearPriorObj].some(
      (obj) => obj.unauth === true
    )

    let finalValue = numFinalVal / denFinalVal
    const isValidNumber = !Number.isNaN(finalValue)
    const isInvalidObject =
      unauth || !numObj || !numYearPriorObj || !denObj || !denYearPriorObj

    if (isInvalidObject) {
      finalValue = null
    }
    if (isValidNumber) {
      acc[dateKey] = {
        ...numObj,
        v: finalValue,
        unauth,
      }
    } else {
      acc[dateKey] = {
        unauth,
        v: null,
      }
    }

    return acc
  }, {})
  return {
    [newCalculatedMetricKey]: items,
  }
}

const updateCalculatedEstimateDataObj = (
  computedMetricsArr,
  estimateDataObj,
  valuationDataObj,
  allDates,
  currentIso,
  mostRecentActualDateKey
) => {
  const allKeys = allDates.map((item) => item.dateKey).sort()
  return computedMetricsArr.reduce(
    (acc, { metricKey, newCalculatedMetricKey, calculation }) => {
      // TODO: watch out for units and ISO
      const splitCalc = calculation.split(" ")
      if (splitCalc[1] === "DIV") {
        const denominator = splitCalc[2]
        const calculatedDataObj = calculateDivision(
          estimateDataObj,
          splitCalc[0],
          newCalculatedMetricKey,
          denominator,
          valuationDataObj,
          acc,
          allKeys,
          mostRecentActualDateKey
        )
        return {
          ...acc,
          ...calculatedDataObj,
        }
      } else if (splitCalc[1] === "DIVMINUSONE") {
        const calculatedDataObj = calculateDivisionMinusOne(
          estimateDataObj,
          splitCalc[0],
          newCalculatedMetricKey,
          splitCalc[2],
          valuationDataObj,
          acc,
          allKeys,
          mostRecentActualDateKey
        )
        return {
          ...acc,
          ...calculatedDataObj,
        }
      } else if (splitCalc[1] === "TIMES") {
        const calculatedDataObj = calculateMultiplication(
          estimateDataObj,
          valuationDataObj,
          acc,
          splitCalc[0],
          splitCalc[2],
          newCalculatedMetricKey,
          allKeys,
          mostRecentActualDateKey
        )
        return {
          ...acc,
          ...calculatedDataObj,
        }
      }

      // if theres no metricKey it means its a new one
      // based on the vendorDataObj
      if (!metricKey) {
        if (splitCalc[1] === "MINUS") {
          const calculatedDataObj = calculateMinus(
            estimateDataObj,
            splitCalc[0],
            splitCalc[2],
            newCalculatedMetricKey,
            allKeys,
            mostRecentActualDateKey
          )
          return {
            ...acc,
            ...calculatedDataObj,
          }
        } else if (calculation === "cumulativeDps") {
          const calculatedDataObj = calculateComputedCumulativeDPS(
            allDates,
            newCalculatedMetricKey,
            estimateDataObj?.dividendsPerShare,
            allKeys
          )
          return {
            ...acc,
            ...calculatedDataObj,
          }
        } else if (splitCalc[1] === "PLUS") {
          const calculatedDataObj = calculateSum(
            estimateDataObj,
            splitCalc[0],
            splitCalc[2],
            newCalculatedMetricKey,
            acc,
            allKeys,
            mostRecentActualDateKey
          )
          return {
            ...acc,
            ...calculatedDataObj,
          }
        }
        if (calculation === "annualizedReturn") {
          const calculatedDataObj = calculateAnnualizedReturn(
            acc,
            allDates,
            newCalculatedMetricKey,
            allKeys
          )
          return {
            ...acc,
            ...calculatedDataObj,
          }
        }
      } else {
        if (calculation === "yoy") {
          const calculatedDataObj = calculateYoYPctChange(
            estimateDataObj,
            metricKey,
            newCalculatedMetricKey,
            acc,
            currentIso,
            allKeys,
            mostRecentActualDateKey
          )
          return {
            ...acc,
            ...calculatedDataObj,
          }
        }
        if (splitCalc[0] === "DIV") {
          const denominator = splitCalc[1]
          const calculatedDataObj = calculateDivision(
            estimateDataObj,
            metricKey,
            newCalculatedMetricKey,
            denominator,
            valuationDataObj,
            acc,
            allKeys,
            mostRecentActualDateKey
          )
          return {
            ...acc,
            ...calculatedDataObj,
          }
        }
        if (splitCalc[0] === "incrementalMargin") {
          const denominator = splitCalc[1]
          const calculatedDataObj = calculateIncrementalMargin(
            estimateDataObj,
            metricKey,
            newCalculatedMetricKey,
            denominator,
            allKeys,
            mostRecentActualDateKey
          )
          return {
            ...acc,
            ...calculatedDataObj,
          }
        }
      }

      return acc
    },
    {}
  )
}

const getNumberOfEstimates = (numEstDataObj) => {
  const value = findFirstAvailableItemInObject({
    item: numEstDataObj,
    keys: ["dataitemvalue", "v"],
    defaultEmpty: null,
  })
  const valueAsNumber = Number(value)
  return Number.isNaN(valueAsNumber) ? null : valueAsNumber
}

const transformEstimatesDataObjectForValuation = (
  estimateDataObj = {},
  numEstDataObj = {}
) => {
  /**
   * estimateDataObj from the raw CIQ data response has the following structure
    2022##FY:Object
    currencyid:160
    currencyname:"US Dollar"
    dataitemid:100179
    dataitemvalue:"4.560000000"
    effectivedate:"2023-02-02T21:01:29.000Z"
    estimateconsensusid:6018258
    estimatescaleid:3
    isocode:"USD"
    priceclose:"1.000000000"
    splitfactor:"1.000000000000000"
    timeVal:1672444800000
    unauth: true | false
   */
  const curr = estimateDataObj.currencyname // currency name
  const iso = estimateDataObj.isocode // currency ISO code
  const exRate = parseFloat(estimateDataObj.priceclose) // currency conversion rate to USD
  const u = estimateDataObj.estimatescaleid // estimate scale id
  const sf = parseFloat(estimateDataObj.splitfactor) // split factor to adjust for historical stock splits
  const v = parseFloat(estimateDataObj.dataitemvalue)
  const unauth = !!estimateDataObj.unauth
  const timeVal = estimateDataObj.timeVal
  const numEst = getNumberOfEstimates(numEstDataObj)
  return {
    curr,
    iso,
    exRate,
    u,
    v,
    unauth,
    est: true, // does this dataObject come from the CIQ Estimates Data Source (instead of financials, different scaleId)
    sf,
    timeVal,
    numEst,
  }
}

const getKeysToLookup = (storeMetricKeyArr, forceGaap) => {
  return storeMetricKeyArr.filter((i) => (forceGaap ? i.isGaap : !i.isGaap))
}

const getStoreDataObj = (
  storeResData,
  storeMetricKey,
  rowLabel,
  forceGaapMetrics
) => {
  if (Array.isArray(storeMetricKey)) {
    const keys = getKeysToLookup(storeMetricKey, forceGaapMetrics)
    const key = findFirstAvailableKeyInObject({
      item: storeResData,
      keys: keys.map((i) => i.key),
      defaultEmpty: null,
    })
    const validMetricObj = storeMetricKey.find((i) => i.key === key)
    const validStoreObj = storeResData?.[key]
    return {
      storeDataObj: validStoreObj,
      label: validMetricObj?.label || rowLabel,
    }
  }
  return { storeDataObj: storeResData[storeMetricKey], label: rowLabel }
}

/**
 * if you are trying to create this initDataVendor object and have the
 * structure where the first keys of the object are metricKeys (keys for the rows fo the table)
 * and the second nested keys are dateKeys (keys for the columns of the table)
 * and you are getting this information from ESTIMATES you need to know
 * which dates are actuals and which dates are estimates
 */
const fetchEstimatesDataFromVuex = (
  storeResData,
  storeMetricKey,
  newMetricKey,
  mostRecentActualDateKey,
  dev,
  rowLabel,
  forceGaapMetrics
) => {
  /**
    actual: Object
    format:"normal"
    formula:"val"
    mean: Object
    median: Object
    name:"EPS Normalized "
    numEst:Object
    tikrdisplay:"1"
   */
  // need to make sure to make deep copies of this I think?
  // we need to avoid passing the information from the store by reference
  // to this component
  const { storeDataObj, label } = getStoreDataObj(
    storeResData,
    storeMetricKey,
    rowLabel,
    forceGaapMetrics
  )
  const storeActualObj = storeDataObj?.actual || {}
  const storeMeanObj = storeDataObj?.mean || {}
  const storeNumEst = storeDataObj?.numEst || {}

  const actuals = Object.keys(storeActualObj).reduce((acc, dateKey) => {
    try {
      if (dateKey > mostRecentActualDateKey) {
        return acc
      }
      const actualDataObj = transformEstimatesDataObjectForValuation(
        storeActualObj[dateKey]
      )
      return {
        ...acc,
        [dateKey]: actualDataObj,
      }
    } catch (error) {
      if (dev) {
        console.error("Error transforming estimate actual for valuation model")
      }
      return acc
    }
  }, {})

  const estimates = Object.keys(storeMeanObj).reduce((acc, dateKey) => {
    try {
      if (dateKey <= mostRecentActualDateKey) {
        return acc
      }
      const meanDataObj = transformEstimatesDataObjectForValuation(
        storeMeanObj[dateKey],
        storeNumEst[dateKey]
      )
      return {
        ...acc,
        [dateKey]: meanDataObj,
      }
    } catch (error) {
      if (dev) {
        console.error("Error transforming forward estimate for valuation model")
      }
      return acc
    }
  }, {})

  const resultObject = {}
  resultObject[newMetricKey] = { ...actuals, ...estimates }
  return { data: resultObject, label }
}

const fetchFinancialsDataFromVuex = (
  storeResData,
  storeMetricKey,
  newMetricKey
) => {
  const storeDataObj = storeResData[storeMetricKey] || {}
  const resultObject = {}
  const mappedDateKeys = Object.keys(storeDataObj).reduce((acc, tblDateKey) => {
    const item = storeDataObj[tblDateKey]
    const isValidKey = tblDateKey.endsWith("##FY")
    if (isValidKey) {
      acc[tblDateKey] = item
    }
    return acc
  }, {})
  resultObject[newMetricKey] = {
    ...mappedDateKeys,
  }
  return resultObject
}

const getEpsNormalizedFromValuationDataset = (
  financialDatesArray,
  epsNormalizedArray = []
) => {
  return financialDatesArray.reduce((acc, dateObj) => {
    const epoch = dayjs.utc(dateObj.fiperiodenddate)
    const filteredArray = epsNormalizedArray.filter((item) => {
      return item.dV <= epoch.valueOf()
    })
    const lastItem = filteredArray[filteredArray.length - 1]
    const key = `${dateObj.calendaryear}##FY`
    if (!hasProp(acc, key)) {
      acc[key] = lastItem
    }
    return acc
  }, {})
}

const getPriceCloseFromEpochDate = (datesArr, priceCloseArray) => {
  const priceCloseArrayWithoutWeekends = priceCloseArray.filter((item) => {
    const utcDate = dayjs.utc(item.dV)
    return utcDate.day() !== 0 && utcDate.day() !== 6
  })
  return datesArr.reduce((acc, dateObj) => {
    const epoch = dayjs.utc(dateObj.epoch)
    const filteredPriceCloseArray = priceCloseArrayWithoutWeekends.filter(
      (item) => {
        return item.dV <= epoch.valueOf()
      }
    )
    const lastItem = filteredPriceCloseArray[filteredPriceCloseArray.length - 1]
    const key = `${dateObj.calendaryear}##FY`
    acc[key] = lastItem
    return acc
  }, {})
}

const fetchValuationDataFromVuex = (
  storeResData,
  storeMetricKey,
  metricKey,
  priceCloseArray,
  financialDatesArr,
  epsNormalizedArr
) => {
  const storeDataObj = storeResData[storeMetricKey] || {}
  const storeKeys = Object.keys(storeDataObj)
  const resultObject = {}
  const isStockPrice = metricKey === "stockPrice"

  // TODO: map the valuation keys to match estimates
  // dataset (like 2022##FY) using the last estimate
  // from each year, not only the latest actual
  const lastValueKey = getLastKeyInObject(storeDataObj)
  const mappedDateKeys = storeKeys.reduce((acc, tblDateKey) => {
    const [dateStr] = tblDateKey.split("T")
    const [year] = dateStr.split("-").map(Number)
    const sameYearKeys = storeKeys.filter((key) =>
      key.startsWith(year.toString())
    )
    const finalObjFromYear =
      storeDataObj?.[sameYearKeys[sameYearKeys.length - 1]]
    acc[`${year}##FY`] = finalObjFromYear
    return acc
  }, {})

  let result = {
    ...mappedDateKeys,
  }

  if (metricKey === "valuationEpsNormalized") {
    const epsNormalized = getEpsNormalizedFromValuationDataset(
      financialDatesArr,
      epsNormalizedArr
    )
    result = {
      ...mappedDateKeys,
      ...epsNormalized,
    }
  }

  if (isStockPrice) {
    const priceCloseItem = getPriceCloseFromEpochDate(
      financialDatesArr,
      priceCloseArray
    )
    result = {
      ...mappedDateKeys,
      ...priceCloseItem,
      latest: storeDataObj[lastValueKey],
    }
  }
  resultObject[metricKey] = result
  return resultObject
}

const fillWithLatestAvailableValue = (
  vendorDataObj,
  estimatesYearArr,
  metricKey,
  initialStoreObjectFallback
) => {
  let lastAvailableValue
  const item = vendorDataObj[metricKey]
  const obj = {}
  const keysWithoutLatestProp = Object.keys(item)
    .filter((key) => key !== "latest")
    .sort()

  keysWithoutLatestProp.forEach((key) => {
    const object = item?.[key]
    if (hasProp(item, key) && object) {
      lastAvailableValue = object
    }
  })

  if (metricKey === "stockPrice") {
    estimatesYearArr.forEach((key) => {
      obj[key] = item?.[key] || item.latest
    })
  } else {
    estimatesYearArr.forEach((key) => {
      if (hasProp(item, key)) {
        obj[key] = item?.[key]
      } else if (hasProp(initialStoreObjectFallback, key)) {
        obj[key] = initialStoreObjectFallback?.[key]
      } else {
        const lastAvailableValueTimestampKey = findFirstAvailableKeyInObject({
          item: lastAvailableValue,
          keys: ["dV", "timeVal"],
          defaultEmpty: null,
        })
        if (lastAvailableValueTimestampKey) {
          const lastAvailableValueTimestamp =
            lastAvailableValue[lastAvailableValueTimestampKey]
          const [year] = key.split("##").map(Number)
          const lastAvailableYear = dayjs
            .utc(lastAvailableValueTimestamp)
            .year()
          const yearOffset = year - lastAvailableYear
          const finalYearValue = dayjs
            .utc(lastAvailableValueTimestamp)
            .add(yearOffset, "year")
            .valueOf()

          obj[key] = {
            ...lastAvailableValue,
            [lastAvailableValueTimestampKey]: finalYearValue,
          }
        } else {
          obj[key] = lastAvailableValue || {}
        }
      }
    })
  }

  return obj
}

const createEstimatesDataObjFromVendorDataArr = (
  estimatesDataArr,
  estimatesStoreResData,
  mostRecentActualDateKeyStr,
  dev,
  amountOfYearsForward,
  forceGaapMetrics
) => {
  if (!mostRecentActualDateKeyStr) {
    return {}
  }
  const [mostRecentActualYear] = mostRecentActualDateKeyStr.split("##")
  const estimatesYearArr = generateYearsKeys(
    Number(mostRecentActualYear),
    amountOfYearsForward
  )
  const mappedLabels = {}
  const data = estimatesDataArr.reduce(
    (acc, { storeMetricKey, metricKey, label }) => {
      const { data: vendorDataObj, label: metricLabel } =
        fetchEstimatesDataFromVuex(
          estimatesStoreResData,
          storeMetricKey,
          metricKey,
          mostRecentActualDateKeyStr,
          dev,
          label,
          forceGaapMetrics
        )
      mappedLabels[metricKey] = metricLabel

      const filledVendorDataObj = fillWithLatestAvailableValue(
        vendorDataObj,
        estimatesYearArr,
        metricKey
      )

      const finalObj = {
        ...vendorDataObj,
        [metricKey]: {
          ...vendorDataObj[metricKey],
          ...filledVendorDataObj,
        },
      }
      return {
        ...acc,
        ...finalObj,
      }
    },
    {}
  )

  return { data, labels: mappedLabels }
}

const getInitialStoreObjectFallback = (
  storeForecastFallback,
  initialStoreObject
) => {
  if (!storeForecastFallback) {
    return null
  }
  return initialStoreObject?.[storeForecastFallback]
}

const createValuationDataObjFromVendorDataArr = (
  valuationMetricsArr,
  valuationStoreResData,
  mostRecentActualDateKeyStr,
  amountOfYearsForward,
  priceCloseArray,
  datesArr,
  epsNormalizedArray,
  initialStoreObject
) => {
  if (!mostRecentActualDateKeyStr) {
    return {}
  }
  const [mostRecentActualYear] = mostRecentActualDateKeyStr.split("##")
  const yearsArr = generateYearsKeys(
    Number(mostRecentActualYear),
    amountOfYearsForward
  )
  return valuationMetricsArr.reduce(
    (acc, { storeMetricKey, metricKey, storeForecastFallback }) => {
      const vendorDataObj = fetchValuationDataFromVuex(
        valuationStoreResData,
        storeMetricKey,
        metricKey,
        priceCloseArray,
        datesArr,
        epsNormalizedArray
      )
      const initialStoreObjectFallback = getInitialStoreObjectFallback(
        storeForecastFallback,
        initialStoreObject
      )
      const filledVendorDataObj = fillWithLatestAvailableValue(
        vendorDataObj,
        yearsArr,
        metricKey,
        initialStoreObjectFallback
      )
      // making sure we use the latest stock price as the first forecast
      if (metricKey === "stockPrice") {
        const firstForecastKey = Object.keys(filledVendorDataObj)[0]
        filledVendorDataObj[firstForecastKey] = vendorDataObj[metricKey]?.latest
      }

      const finalObj = {
        ...vendorDataObj,
        [metricKey]: {
          ...vendorDataObj[metricKey],
          ...filledVendorDataObj,
        },
      }
      return {
        ...acc,
        ...finalObj,
      }
    },
    {}
  )
}

const createFinancialDataObjFromVendorDataArr = (
  estimatesMetricArr,
  financialStoreResData,
  mostRecentActualDateKeyStr,
  amountOfYearsForward
) => {
  if (!mostRecentActualDateKeyStr) {
    return {}
  }
  const [mostRecentActualYear] = mostRecentActualDateKeyStr.split("##")
  const estimatesYearArr = generateYearsKeys(
    Number(mostRecentActualYear),
    amountOfYearsForward
  ).filter((key) => key !== "latest")
  const mappedLabels = {}
  const data = estimatesMetricArr.reduce(
    (acc, { financialFallbackKey, metricKey, label, fallbackLabel }) => {
      const vendorDataObj = fetchFinancialsDataFromVuex(
        financialStoreResData,
        financialFallbackKey,
        metricKey
      )

      const filledVendorDataObj = fillWithLatestAvailableValue(
        vendorDataObj,
        estimatesYearArr,
        metricKey
      )
      mappedLabels[metricKey] = fallbackLabel || label

      const finalObj = {
        ...vendorDataObj,
        [metricKey]: {
          ...vendorDataObj[metricKey],
          ...filledVendorDataObj,
        },
      }
      return {
        ...acc,
        ...finalObj,
      }
    },
    {}
  )

  return {
    data,
    labels: mappedLabels,
  }
}

const getCellValue = (key, dateKeyStr, sourcesArr = []) => {
  for (let idx = 0; idx < sourcesArr.length; idx++) {
    const source = sourcesArr[idx]
    if (hasProp(source?.[key]?.[dateKeyStr], "v")) {
      return source?.[key]?.[dateKeyStr]?.v
    }
  }
  return null
}

const getCellObject = (key, dateKeyStr, sourcesArr = []) => {
  for (let idx = 0; idx < sourcesArr.length; idx++) {
    const source = sourcesArr[idx]
    if (hasProp(source?.[key]?.[dateKeyStr], "v")) {
      return {
        ...source?.[key]?.[dateKeyStr],
        value: source?.[key]?.[dateKeyStr]?.v,
        index: idx,
      }
    }
  }
  return null
}

const getFullFilledDates = (datesArr, amountOfYearsForward, dateFormatter) => {
  const amountOfEstimates = datesArr.filter((i) => i.isEstimate)
  const amountOfIterations = amountOfYearsForward - amountOfEstimates.length
  const lastAvailableObject = datesArr[datesArr.length - 1]
  const lastAvailableCalendarYear = lastAvailableObject?.calendarYear
  const lastAvailableFiscalyear = lastAvailableObject?.fiscalYear
  const isFiscalYearTheSameAsCalendarYear =
    lastAvailableCalendarYear === lastAvailableFiscalyear
  const updatedArr = []

  const updatedDates = datesArr.map((date) => {
    const timestamp = dayjs.utc(date.epoch).valueOf()
    return {
      ...date,
      fiscalDate: dateFormatter.format(date.epoch),
      epoch: timestamp,
    }
  })

  for (let i = 0; i < amountOfIterations; i++) {
    const year = lastAvailableCalendarYear + i + 1
    const dateKeyStr = `${year}##FY`
    const sum = isFiscalYearTheSameAsCalendarYear ? 0 : 1
    const fiscalYear = lastAvailableFiscalyear + i + sum
    const epochDate = dayjs(lastAvailableObject?.epoch).add(i + 1, "year")
    updatedArr.push({
      dateKey: dateKeyStr,
      fiscalDate: dateFormatter.format(epochDate),
      calendarYear: year,
      fiscalYear,
      label: year,
      isEstimate: true,
      epoch: epochDate.valueOf(),
    })
  }

  return [...updatedDates, ...updatedArr]
}

const cellCalculations = {
  valuationEpsNormalized: ({ dateKeyStr, vendorObj, userObj, accObj }) => {
    const netIncome = getCellValue("netIncome", dateKeyStr, [
      userObj,
      accObj,
      vendorObj,
    ])
    const dso = getCellValue("dso", dateKeyStr, [accObj, vendorObj])
    let value = netIncome / dso

    if (dso === null || netIncome === null) {
      value = null
    }

    return {
      v: value || 0,
      dependencies: { netIncome, dso },
    }
  },
  revenue: ({ dateKeyStr, vendorObj, userObj, accObj }) => {
    const lastYear = getYearKey(dateKeyStr, -1)

    const lastYearRevenueVal = getCellValue("revenue", lastYear, [
      accObj,
      vendorObj,
    ])

    let currentYearYoYpct = userObj?.revenueChangeYoY?.[dateKeyStr]
    // for this one the approximation wouldn't work,
    // if the input value is zero should really be zero
    if (currentYearYoYpct === 0.0001) {
      currentYearYoYpct = 0
    }
    return {
      v: lastYearRevenueVal * (1 + currentYearYoYpct),
      dependencies: {
        lastYearRevenueVal,
        currentYearYoYpct,
      },
    }
  },
  ebit: ({ dateKeyStr, vendorObj, userObj, accObj }) => {
    const pctMarginUser = userObj?.ebitMargin?.[dateKeyStr]
    const pctMargin =
      pctMarginUser || getCellValue("ebitMargin", dateKeyStr, [vendorObj])
    const currentRevenue = getCellValue("revenue", dateKeyStr, [
      accObj,
      vendorObj,
    ])
    const pctMarginObj = getCellObject("ebitMargin", dateKeyStr, [vendorObj])
    const currentRevenueObj = getCellObject("revenue", dateKeyStr, [
      accObj,
      vendorObj,
    ])
    const unauth = pctMarginObj?.unauth || currentRevenueObj?.unauth

    return {
      v: pctMargin * currentRevenue,
      unauth,
      dependencies: {
        pctMargin,
        currentRevenue,
      },
    }
  },
  ebt: ({ dateKeyStr, vendorObj, userObj, accObj }) => {
    const interestUserVal = userObj?.interestOther?.[dateKeyStr]
    const interest =
      interestUserVal ||
      getCellValue("interestOther", dateKeyStr, [accObj, vendorObj])
    const ebit = getCellValue("ebit", dateKeyStr, [accObj, vendorObj])
    return {
      v: interest + ebit,
      dependencies: {
        interest,
        ebit,
      },
    }
  },
  netIncome: ({ dateKeyStr, vendorObj, accObj }) => {
    const taxes =
      getCellValue("taxesOther", dateKeyStr, [accObj, vendorObj]) || 0
    const ebt = getCellValue("ebt", dateKeyStr, [accObj, vendorObj]) || 0
    return {
      v: taxes + ebt,
      dependencies: { taxes, ebt },
    }
  },
  eps: ({ dateKeyStr, vendorObj, accObj }) => {
    const netIncome = getCellValue("netIncome", dateKeyStr, [accObj, vendorObj])
    const dso = getCellValue("dso", dateKeyStr, [accObj, vendorObj])
    let value = netIncome / dso

    if (dso === null || netIncome === null) {
      value = null
    }

    return {
      v: value,
      dependencies: { netIncome, dso },
    }
  },
  dividendsPerShare: ({ dateKeyStr, vendorObj, userObj, accObj }) => {
    const payoutRatioObj = getCellObject(
      "dividendsPerSharePayoutRatio",
      [dateKeyStr],
      [accObj, vendorObj]
    )
    const currentPayoutRatio =
      userObj?.dividendsPerSharePayoutRatio?.[dateKeyStr] ||
      getCellValue("dividendsPerSharePayoutRatio", dateKeyStr, [
        accObj,
        vendorObj,
      ])
    const epsObj = getCellObject("eps", dateKeyStr, [accObj, vendorObj])
    const currentEPS = getCellValue("eps", dateKeyStr, [accObj, vendorObj])
    const unauth = [payoutRatioObj, epsObj].some((obj) => obj?.unauth === true)
    const v = currentPayoutRatio * currentEPS
    return {
      v,
      dependencies: {
        currentPayoutRatio,
        currentEPS,
      },
      unauth,
    }
  },
  ebitChangeYoY: ({ dateKeyStr, vendorObj, accObj }) => {
    const lastYearKey = getYearKey(dateKeyStr, -1)
    const currentEbit = getCellValue("ebit", dateKeyStr, [accObj, vendorObj])
    const lastYearEbit = getCellValue("ebit", lastYearKey, [accObj, vendorObj])
    const value = currentEbit / lastYearEbit - 1

    return {
      v: value,
      dependencies: { currentEbit, lastYearEbit },
    }
  },
  ebitIncrementalMargin: ({ dateKeyStr, vendorObj, accObj }) => {
    const lastYearKey = getYearKey(dateKeyStr, -1)
    const currentEbit =
      getCellValue("ebit", dateKeyStr, [accObj, vendorObj]) || {}
    const lastYearEbit =
      getCellValue("ebit", lastYearKey, [accObj, vendorObj]) || {}
    const currentRevenue =
      getCellValue("revenue", dateKeyStr, [accObj, vendorObj]) || {}
    const lastYearRevenue = getCellValue("revenue", lastYearKey, [
      accObj,
      vendorObj,
    ])
    const diffBetweenCurrentAndLastYearEbit = currentEbit - lastYearEbit
    const diffBetweenCurrentAndLastYearRevenue =
      currentRevenue - lastYearRevenue
    return {
      v:
        diffBetweenCurrentAndLastYearEbit /
        diffBetweenCurrentAndLastYearRevenue,
      dependencies: {
        currentEbit,
        lastYearEbit,
        currentRevenue,
        lastYearRevenue,
        dateKeyStr,
        lastYearKey,
      },
    }
  },
  netIncomeChangeYoY: ({ dateKeyStr, vendorObj, accObj }) => {
    const lastYearKey = getYearKey(dateKeyStr, -1)
    const currentNetIncome = getCellValue("netIncome", dateKeyStr, [
      accObj,
      vendorObj,
    ])
    const lastYearNetIncome = getCellValue("netIncome", lastYearKey, [
      accObj,
      vendorObj,
    ])
    return {
      v: currentNetIncome / lastYearNetIncome - 1,
      dependencies: { currentNetIncome, lastYearNetIncome },
    }
  },
  netIncomeMargin: ({ dateKeyStr, vendorObj, accObj }) => {
    const currentNetIncome = getCellValue("netIncome", dateKeyStr, [
      accObj,
      vendorObj,
    ])
    const currentRevenue = getCellValue("revenue", dateKeyStr, [
      accObj,
      vendorObj,
    ])
    return {
      v: currentNetIncome / currentRevenue,
      dependencies: { currentNetIncome, currentRevenue },
    }
  },
  epsChangeYoY: ({ dateKeyStr, vendorObj, accObj }) => {
    const lastYearKey = getYearKey(dateKeyStr, -1)
    const currentEPS = getCellValue("eps", dateKeyStr, [accObj, vendorObj])
    const lastYearEPS = getCellValue("eps", lastYearKey, [accObj, vendorObj])

    return {
      v: currentEPS / lastYearEPS - 1 || 0,
      dependencies: {
        currentEPS,
        lastYearEPS,
      },
    }
  },
  taxesOther: ({ dateKeyStr, vendorObj, userObj, accObj }) => {
    const ebtPctUserObj = userObj?.taxesPercentOfEBT?.[dateKeyStr]
    const ebtPct =
      ebtPctUserObj ||
      getCellValue("taxesPercentOfEBT", dateKeyStr, [
        userObj,
        accObj,
        vendorObj,
      ])
    const ebt = getCellValue("ebt", dateKeyStr, [accObj, vendorObj])
    return {
      v: ebtPct * ebt,
      dependencies: { ebtPct, ebt },
    }
  },
  dso: ({ dateKeyStr, vendorObj, userObj, accObj }) => {
    const currentChangeYoY =
      userObj?.dsoChangeYoY?.[dateKeyStr] ||
      getCellValue("dsoChangeYoY", dateKeyStr, [accObj, vendorObj])
    const lastYearKey = getYearKey(dateKeyStr, -1)
    const lastYearDSO =
      userObj?.dso?.[lastYearKey] ||
      getCellValue("dso", lastYearKey, [accObj, vendorObj])
    return {
      v: lastYearDSO * (1 + currentChangeYoY),
      dependencies: { lastYearDSO, currentChangeYoY },
    }
  },
  dividendsPerShareChangeYoY: ({ dateKeyStr, vendorObj, accObj }) => {
    const dpsKey = "dividendsPerShare"
    const sortedKeys = Object.keys(vendorObj[dpsKey]).sort()
    const currentYearIdx = sortedKeys.indexOf(dateKeyStr)
    const lastYearKey = sortedKeys[currentYearIdx - 1]
    const currentDPSObject = getCellObject(dpsKey, dateKeyStr, [
      accObj,
      vendorObj,
    ])
    const lastYearDPSObj = getCellObject(dpsKey, lastYearKey, [
      accObj,
      vendorObj,
    ])
    let currentDPS = getCellValue(dpsKey, dateKeyStr, [accObj, vendorObj])
    if (currentDPS < 0.01) {
      currentDPS = 0
    }
    const lastYearDPS = getCellValue(dpsKey, lastYearKey, [accObj, vendorObj])
    let value = currentDPS / lastYearDPS - 1
    if (lastYearDPS < 0.01) {
      value = 0
    }
    const unauth = [currentDPSObject, lastYearDPSObj].some(
      (item) => item?.unauth === true
    )

    if (!isFinite(currentDPS) || !lastYearKey) {
      value = null
    }
    return {
      v: value,
      dependencies: { currentDPS, lastYearDPS },
      unauth,
    }
  },
  impliedStockPrice: ({ dateKeyStr, vendorObj, userObj, accObj }) => {
    const ntmDateKey = getYearKey(dateKeyStr, 1)
    const peMultiple =
      userObj?.priceOverEarningsMultiple?.[dateKeyStr] ||
      getCellValue("priceOverEarningsMultiple", dateKeyStr, [accObj, vendorObj])
    const eps = getCellValue("eps", ntmDateKey, [accObj, vendorObj])
    const v = peMultiple * eps
    return {
      v,
      dependencies: { peMultiple, eps },
    }
  },
  cumulativeDividendsPerShare: ({
    dateKeyStr,
    vendorObj,
    accObj,
    lastActualDateKeyStr,
  }) => {
    const [year] = dateKeyStr.split("##")
    const [lastActualYear] = lastActualDateKeyStr.split("##")
    const date = `12/31/${year}`
    const dpsObj = getCellObject("dividendsPerShare", dateKeyStr, [
      accObj,
      vendorObj,
    ])
    const dps = getCellValue("dividendsPerShare", dateKeyStr, [
      accObj,
      vendorObj,
    ])
    if (!dps) {
      return null
    }
    const isOneYearAfterLastActual = Number(year) - Number(lastActualYear) === 1
    if (isOneYearAfterLastActual) {
      const cumulative = calculateCumulativeDps(dps, date, new Date())
      return {
        v: cumulative,
        unauth: dpsObj?.unauth,
        dependencies: { dps, date },
      }
    } else {
      const currentKeyIdx = Object.keys(
        accObj.cumulativeDividendsPerShare
      ).indexOf(dateKeyStr)
      const lastYearKey = Object.keys(accObj.cumulativeDividendsPerShare)[
        currentKeyIdx - 1
      ]
      const lastYearCumulativeObj = getCellObject(
        "cumulativeDividendsPerShare",
        lastYearKey,
        [accObj, vendorObj]
      )
      const lastYearCumulative = getCellValue(
        "cumulativeDividendsPerShare",
        lastYearKey,
        [accObj, vendorObj]
      )
      const v = lastYearCumulative + dps
      const unauth = lastYearCumulativeObj?.unauth || dpsObj?.unauth

      return {
        v,
        unauth,
        dependencies: { dps, date },
      }
    }
  },
  totalStockPriceIncludingDividends: ({ dateKeyStr, vendorObj, accObj }) => {
    const cumulativeDps = getCellValue(
      "cumulativeDividendsPerShare",
      dateKeyStr,
      [accObj, vendorObj]
    )
    const impliedStockPrice = getCellValue("impliedStockPrice", dateKeyStr, [
      accObj,
      vendorObj,
    ])
    return {
      v: cumulativeDps + impliedStockPrice,
      dependencies: { cumulativeDps, impliedStockPrice },
    }
  },
  upsideOrDownsideToCurrentStockPrice: ({ dateKeyStr, vendorObj, accObj }) => {
    const stockPriceIncludingDividends = getCellValue(
      "totalStockPriceIncludingDividends",
      dateKeyStr,
      [accObj, vendorObj]
    )
    const currentStockPrice = getCellValue("stockPrice", dateKeyStr, [
      accObj,
      vendorObj,
    ])
    return {
      v: stockPriceIncludingDividends / currentStockPrice - 1,
      dependencies: { stockPriceIncludingDividends, currentStockPrice },
    }
  },
  annualizedReturn: ({ dateKeyStr, vendorObj, accObj, dates }) => {
    const [year] = dateKeyStr.split("##").map(Number)
    const date = `12/31/${year}`
    const actualDate = dates.find((i) => i.calendarYear === year)?.epoch
    const latestStockPrice = vendorObj.stockPrice.latest
    const realizedAtDate = dayjs.utc(actualDate).format("DD/MM/YY")

    const scaledUpsideOrDownsideVal = getCellObject(
      "upsideOrDownsideToCurrentStockPrice",
      dateKeyStr,
      [accObj, vendorObj]
    )
    const irrProps = {
      scaledUpsideOrDownsideVal: scaledUpsideOrDownsideVal?.v,
      realizedAtDate,
      initialDate: latestStockPrice?.dV,
    }
    const finalValue = calculateIrr(
      irrProps.scaledUpsideOrDownsideVal,
      irrProps.realizedAtDate,
      irrProps.initialDate
    )

    return {
      v: finalValue,
      dependencies: { ...irrProps },
      dV: dayjs.utc(date, "MM/DD/YYYY").valueOf(),
    }
  },
}

export {
  cellCalculations,
  createEstimatesDataObjFromVendorDataArr,
  createFinancialDataObjFromVendorDataArr,
  createValuationDataObjFromVendorDataArr,
  createValuationOutputFromHubData,
  generateAssumptionsOutput,
  generateHistoricalTable,
  getLastStockPriceObj,
  getFullfilledDatesFromState,
  getFormatters,
  getCellValue,
  getCellObject,
  getFullFilledDates,
  getUserInputDataObject,
  updateCalculatedEstimateDataObj,
  updateValuationOutput,
}
