export * from "./cache"

export const getYearKey = (dateKeyStr, yearOffset) => {
  if (!dateKeyStr) return null
  const [year] = dateKeyStr.split("##")
  return `${Number(year) + yearOffset}##FY`
}

export const getJWT = async ($AmplifyObj) => {
  const user = await $AmplifyObj.Auth.currentAuthenticatedUser()
  return user.signInUserSession.idToken.jwtToken
}
