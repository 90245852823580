import { fetchByCidTid } from "./watchlist"
import { getJWT } from "~/utils/tools"

const API_URL = process.env.API_URL
const CDK_URL = process.env.MACUNA_LAMBDA_URL
const isDev = process.env.SETTINGS === "development"
const endpointBaseDev = "https://rishu-api.tikr.com"
const endpointBase = isDev ? endpointBaseDev : API_URL
const GET_VALUATION_MODEL_LIST = `${endpointBase}/lv`
const CREATE_VALUATION_MODEL = `${endpointBase}/cv`
const UPDATE_VALUATION_MODEL = `${endpointBase}/uv`
const DELETE_VALUATION_MODEL = `${endpointBase}/dv`
const GET_SINGLE_MODEL = `${endpointBase}/gv`
const GET_PUBLIC_MODEL = `${endpointBase}/gpv`
const CHANGE_ACTIVE_MODEL = `${endpointBase}/sav`
const SHARE_MODEL = `${endpointBase}/sv`
const TOGGLE_PUBLIC_MODEL = `${endpointBase}/tsv`

const fetchModelsFromBackend = async ({
  cidList,
  $AmplifyObj,
  $axiosObj,
  flagEnabled,
}) => {
  if (!flagEnabled) return { error: "Feature is disabled" }
  try {
    const jwt = await getJWT($AmplifyObj)
    let body = {
      auth: jwt,
    }
    if (cidList?.length > 0) {
      body = {
        ...body,
        cids: cidList,
      }
    }
    const { data } = await $axiosObj.post(GET_VALUATION_MODEL_LIST, body)
    return { data, error: false }
  } catch (error) {
    return { error: error.message }
  }
}

const fetchAllModels = async ({
  $AmplifyObj,
  $axiosObj,
  flagEnabled,
  mapLatestData,
}) => {
  if (!flagEnabled) return { error: "Feature is disabled" }
  try {
    const jwt = await getJWT($AmplifyObj)
    const body = {
      auth: jwt,
    }
    const { data } = await $axiosObj.post(GET_VALUATION_MODEL_LIST, body)
    if (mapLatestData) {
      const tickers = mapHubCompanies(data)
      if (tickers.length > 0) {
        const { data: stockData, error: cidTidError } = await fetchByCidTid({
          $AmplifyObj,
          $axios: $axiosObj,
          tickers,
        })

        const mappedDataObj = Object.entries(data).reduce(
          (acc, [key, value]) => {
            acc[key] = value.map((valObj) => {
              const tid = Number(valObj?.tid)
              const stock = stockData.price.find((i) => i.mctid === tid) || {}
              return {
                ...valObj,
                stockData: stock,
              }
            })
            return acc
          },
          {}
        )
        if (cidTidError) {
          return { error: cidTidError }
        }
        return { data: mappedDataObj, error: false }
      }
    }
    return { data, error: false }
  } catch (error) {
    return { error: error.message }
  }
}

const saveNewModelToBackend = async ({
  $AmplifyObj,
  $axiosObj,
  cid,
  tid,
  data,
  metadata,
  flagEnabled,
}) => {
  if (!flagEnabled) return { error: "Feature is disabled" }
  try {
    const jwt = await getJWT($AmplifyObj)
    const body = {
      auth: jwt,
      cid: cid.toString(),
      tid: tid.toString(),
      data,
      metadata,
    }
    const { data: returnData } = await $axiosObj.post(
      CREATE_VALUATION_MODEL,
      body
    )
    return { data: returnData, error: false }
  } catch (error) {
    return { error: error.message }
  }
}

const deleteModel = async ({
  cid,
  tid,
  modelId,
  $AmplifyObj,
  $axiosObj,
  flagEnabled,
}) => {
  if (!flagEnabled) return { error: "Feature is disabled" }

  try {
    const jwt = await getJWT($AmplifyObj)
    const body = {
      auth: jwt,
      cid: cid.toString(),
      tid: tid.toString(),
      modelId,
    }
    const { data } = await $axiosObj.post(DELETE_VALUATION_MODEL, body)
    return { data, error: false }
  } catch (error) {
    return { error: error.message }
  }
}

const fetchLogoData = async ({ $AmplifyObj, $axiosObj, cid }) => {
  try {
    const jwt = await getJWT($AmplifyObj)
    const route = `${CDK_URL}/logo`
    const body = {
      auth: jwt,
      cid,
    }
    const { data } = await $axiosObj.post(route, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return { data, error: false }
  } catch (error) {
    return { error: error.message }
  }
}

const fetchSingleModelFromBackend = async ({
  uuid,
  $AmplifyObj,
  $axiosObj,
  flagEnabled,
}) => {
  if (!flagEnabled) return { error: "Feature is disabled" }
  try {
    const jwt = await getJWT($AmplifyObj)
    const body = {
      auth: jwt,
      modelId: uuid,
    }
    const { data } = await $axiosObj.post(GET_SINGLE_MODEL, body)
    return { data, error: false }
  } catch (error) {
    return { error: error.message }
  }
}

const editModel = async ({
  $AmplifyObj,
  $axiosObj,
  cid,
  tid,
  data,
  metadata,
  modelId,
  flagEnabled,
}) => {
  if (!flagEnabled) return { error: "Feature is disabled" }
  try {
    const jwt = await getJWT($AmplifyObj)
    const body = {
      auth: jwt,
      cid: cid.toString(),
      tid: tid.toString(),
      modelId,
      data,
      metadata,
    }
    const { data: returnData } = await $axiosObj.post(
      UPDATE_VALUATION_MODEL,
      body
    )
    return { data: returnData, error: false }
  } catch (error) {
    return { error: error.message }
  }
}

const mapHubCompanies = (dataArr) => {
  return Object.values(dataArr).reduce((acc, item) => {
    const firstItem = item[0]
    const cid = firstItem?.cid.toString()
    const tid = firstItem?.tid.toString()
    acc.push([cid, tid])
    return acc
  }, [])
}

const changeActiveModel = async ({
  $AmplifyObj,
  $axiosObj,
  cid,
  tid,
  modelId,
  lastActiveModelId,
  flagEnabled,
}) => {
  if (!flagEnabled) return { error: "Feature is disabled" }
  try {
    const jwt = await getJWT($AmplifyObj)
    const actualModelId =
      modelId && modelId !== lastActiveModelId ? modelId : ""
    const body = {
      auth: jwt,
      modelId: actualModelId,
      cid: cid.toString(),
      tid: tid.toString(),
    }
    if (lastActiveModelId) {
      body.lastActiveModelId = lastActiveModelId
    }

    const { data } = await $axiosObj.post(CHANGE_ACTIVE_MODEL, body)
    return { data, error: false }
  } catch (error) {
    return { error: error.message }
  }
}

const shareModel = async ({
  $AmplifyObj,
  $axiosObj,
  modelId,
  cid,
  tid,
  flagEnabled,
}) => {
  if (!flagEnabled) return { error: "Feature is disabled" }
  try {
    const jwt = await getJWT($AmplifyObj)
    const body = {
      auth: jwt,
      cid,
      tid,
      modelId,
    }
    const { data } = await $axiosObj.post(SHARE_MODEL, body)
    return { error: false, data }
  } catch (error) {
    return { error: error.message }
  }
}

const fetchPublicModel = async ({
  $AmplifyObj,
  $axiosObj,
  publicModelId,
  flagEnabled,
}) => {
  if (!flagEnabled) return { error: "Feature is disabled" }

  try {
    const jwt = await getJWT($AmplifyObj)
    const body = {
      auth: jwt,
      publicModelId,
    }
    const { data } = await $axiosObj.post(GET_PUBLIC_MODEL, body)
    return { error: false, data }
  } catch (error) {
    return { error: error.message }
  }
}

const togglePublicModel = async ({
  $AmplifyObj,
  $axiosObj,
  flagEnabled,
  modelId,
  cid,
  tid,
}) => {
  if (!flagEnabled) return { error: "Feature is disabled" }
  try {
    const jwt = await getJWT($AmplifyObj)
    const body = {
      auth: jwt,
      modelId,
      cid,
      tid,
    }
    const { data } = await $axiosObj.post(TOGGLE_PUBLIC_MODEL, body)
    return { error: false, data }
  } catch (error) {
    return { error: error.message }
  }
}

export {
  changeActiveModel,
  deleteModel,
  editModel,
  fetchLogoData,
  fetchModelsFromBackend,
  fetchSingleModelFromBackend,
  fetchAllModels,
  fetchPublicModel,
  saveNewModelToBackend,
  shareModel,
  togglePublicModel,
}
