import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.anchor.js";
import "core-js/modules/es.string.sub.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
import { nextTick } from "vue";
import { useStorage } from "@vueuse/core";
import { baseAnalytics } from "~/plugins/aws-exports.js";
import { estimatesTabs, financialTabs, valuationTabs, dataSourceTabs } from "~/utils/constants/tabs";
import useBusinessMode from "~/functions/useBusinessMode";
import useBaseUtils from "~/functions/useBaseUtils";
import useAnalytics from "~/functions/useAnalytics";
import { SHOW_MORNINGSTAR_FINANCIALS, VALUATION_MODEL } from "~/feature-toggle";
import useBreakpoints from "~/functions/useBreakpoints";
import { ONBOARDING_STEP_INITIAL_PAGE_ID, ONBOARDING_STEP_MY_WATCHLIST_ID, ONBOARDING_STEP_TRACK_INVESTING_GURUS_ID, ONBOARDING_STEP_GLOBAL_SCREENER_ID, ONBOARDING_DETAILED_FINANCIALS_ID, ONBOARDING_ANALYST_ESTIMATES_ID, ONBOARDING_WANT_TO_LEARN_MORE_ID } from "~/utils/onboarding/stepIds";
import useLifecycleLog from "~/functions/useLifecycleLog";
export default {
  __name: 'default',
  setup: function setup(__props) {
    var _$featureToggle$toggl;
    useLifecycleLog({
      logName: "default layout"
    });
    var isSettingsMenuOpen = ref(false);
    var isOnboardingRewatchActive = ref(false);
    var _useBreakpoints = useBreakpoints(),
      isGreaterOrEqual = _useBreakpoints.isGreaterOrEqual;
    var _useBusinessMode = useBusinessMode(),
      checkBusinessModePreference = _useBusinessMode.checkBusinessModePreference,
      hidePrice = _useBusinessMode.hidePrice;
    var $route = useRoute();
    var $router = useRouter();
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev,
      freeTier = _useBaseUtils.freeTier,
      refCode = _useBaseUtils.refCode,
      idtoken = _useBaseUtils.idtoken,
      restrictResponse = _useBaseUtils.restrictResponse,
      freezeFirstColumn = _useBaseUtils.freezeFirstColumn,
      syncStoreWithLocalStorageBoolean = _useBaseUtils.syncStoreWithLocalStorageBoolean,
      I18nFn = _useBaseUtils.I18nFn;
    var history = ref([]);
    var defaultCid = "29096";
    var defaultTid = "11311662";
    var _useAnalytics = useAnalytics(),
      gtmDataLayerVariables = _useAnalytics.gtmDataLayerVariables,
      events = _useAnalytics.events,
      sendEvent = _useAnalytics.sendEvent; // old data() object
    var lastFetch = new Date("2022-08-29T02:21:48.008Z");
    var clipped = ref(false);
    var drawer = ref(null);
    var miniVariant = ref(false);
    var mounted = ref(false);
    var _useNuxtApp = useNuxtApp(),
      $Amplify = _useNuxtApp.$Amplify,
      $featureToggle = _useNuxtApp.$featureToggle,
      $gtm = _useNuxtApp.$gtm,
      $store = _useNuxtApp.$store,
      $vuetify = _useNuxtApp.$vuetify;
    var enableDataSourceQueryParams = (_$featureToggle$toggl = $featureToggle.toggles) === null || _$featureToggle$toggl === void 0 ? void 0 : _$featureToggle$toggl["datasource-query-params"];
    var _dataSourceTabs = dataSourceTabs(SHOW_MORNINGSTAR_FINANCIALS),
      _dataSourceTabs2 = _slicedToArray(_dataSourceTabs, 1),
      dataSourceFirstSlug = _dataSourceTabs2[0];
    var estimatesFirstTabSlug = estimatesTabs[0].slug;
    var financialFirstTabSlug = financialTabs[0].slug;
    var valuationFirstTabSlug = valuationTabs[0].slug;
    checkBusinessModePreference();
    syncStoreWithLocalStorageBoolean({
      vuexKey: "reverseDates",
      localStorageKey: "reverseDates"
    });
    var currentActiveModelLink = computed(function () {
      var _$store$state$valuati;
      if (!VALUATION_MODEL) {
        return "";
      }
      var cid = $store.state.ciq.ticker.companyid || defaultCid;
      var tid = $store.state.ciq.ticker.tradingitemid || defaultTid;
      var models = (_$store$state$valuati = $store.state.valuationModel.allModels) === null || _$store$state$valuati === void 0 ? void 0 : _$store$state$valuati[cid.toString()];
      var activeModel = models === null || models === void 0 ? void 0 : models.find(function (model) {
        return model.isActive;
      });
      var path = "/stock/model?cid=".concat(cid, "&tid=").concat(tid, "&ref=").concat(refCode.value);
      if (activeModel) {
        return "".concat(path, "&modelId=").concat(activeModel.modelId);
      }
      return path;
    });
    var cid = computed(function () {
      return $store.state.ciq.ticker.companyid || defaultCid;
    });
    var tid = computed(function () {
      return $store.state.ciq.ticker.tradingitemid || defaultTid;
    });
    var appLanguages = computed(function () {
      return $store.state.config.appLanguages;
    });
    var activeScreener = computed(function () {
      return $store.state.screener.active;
    });
    var activeNewsfeed = computed(function () {
      return $store.state.trkd.active;
    });
    var refinitiv = computed(function () {
      return $store.state.config.refinitiv;
    });
    var ciqSearch = computed(function () {
      return $store.state.config.ciqSearch;
    });
    var user = computed(function () {
      return Boolean($store.state.user);
    });
    var fetchingAgreeToTerms = computed(function () {
      return $store.state.fetchingAgreeToTerms;
    });
    var selectedStock = computed(function () {
      return $store.state.ciq.ticker || {};
    });
    var showPayments = computed(function () {
      return $store.state.config.showPayments;
    });
    var largerThanXsBreakpoint = computed(function () {
      return $vuetify.breakpoint.name !== "xs";
    });
    var stockTruthy = computed(function () {
      return Boolean($store.state.ciq.ticker.tickersymbol);
    });
    var estimatesUrl = computed(function () {
      return "/stock/estimates?cid=".concat(cid.value, "&tid=").concat(tid.value, "&tab=").concat(estimatesFirstTabSlug, "&ref=").concat(refCode.value);
    });
    var activeWatchlist = computed(function () {
      var activeId = Object.hasOwn($store.state.watchlist.activeWatchlist, "id") ? $store.state.watchlist.activeWatchlist.id : null;
      if (activeId) {
        var _activeWatchlist = Object.hasOwn($store.state.watchlist.watchlists, activeId) ? $store.state.watchlist.watchlists[activeId] : null;
        if (_activeWatchlist) {
          return _activeWatchlist;
        }
      }
      return false;
    });
    var primaryBrowserLang = computed(function () {
      var navigatorLang = navigator.language;
      var browserLang = navigatorLang.split("-")[0].toUpperCase();
      return browserLang;
    });
    var defaultAppLang = computed({
      get: function get() {
        var storeAppLang = $store.state.config.defaultAppLang;
        var primaryLang = primaryBrowserLang.value.toLowerCase();
        var backupLang = appLanguages.value.includes(primaryLang) ? primaryLang : "en";
        return storeAppLang || backupLang;
      },
      set: function set(value) {
        $store.commit("config/setLocalStorage", {
          vuexKey: "defaultAppLang",
          localStorageKey: "defaultAppLang",
          value: value
        });
      }
    });
    var watchlistPath = computed(function () {
      var activeId = activeWatchlist.value;
      return activeId ? "/watchlist?wid=".concat(activeId.id, "&ref=").concat(refCode.value) : "/watchlist?ref=".concat(refCode.value);
    });
    var screenerPath = computed(function () {
      var activeId = activeScreener.value;
      var order = $store.state.screener.order || [];
      var idx = 1;
      try {
        idx = order && order.findIndex(function (e) {
          return e === activeId;
        }) >= 0 ? order.findIndex(function (e) {
          return e === activeId;
        }) + 1 : 1;
      } catch (e) {//
      }
      return activeId ? "/screener?sid=".concat(idx, "&ref=").concat(refCode.value) : "/screener?ref=".concat(refCode.value);
    });
    var newsfeedPath = computed(function () {
      var activeId = activeNewsfeed.value;
      var order = $store.state.trkd.order || [];
      var idx = 1;
      try {
        idx = order && order.findIndex(function (e) {
          return e === activeId;
        }) >= 0 ? order.findIndex(function (e) {
          return e === activeId;
        }) + 1 : 1;
      } catch (e) {//
      }
      return activeId ? "/markets?fid=".concat(idx, "&ref=").concat(refCode.value) : "/markets?ref=".concat(refCode.value);
    });
    var agreedTOS = computed(function () {
      return idtoken.value.payload["custom:tos"] ? idtoken.value.payload["custom:tos"].split(" ") : [];
    });
    var agreedPP = computed(function () {
      return idtoken.value.payload["custom:pp"] ? idtoken.value.payload["custom:pp"].split(" ") : [];
    });
    var paid = computed(function () {
      // perform logic to determine if the user is a paying user or not
      var groupArr = idtoken.value.payload["cognito:groups"] || [idtoken.value.payload["custom:group"]];
      var paidSet = new Set($store.state.paidGroups);
      var paidUser = false;
      groupArr.forEach(function (g) {
        if (paidSet.has(g)) {
          paidUser = true;
        }
      });
      return paidUser;
    });
    var showAgreeToTerms = computed(function () {
      var tosVer = $store.state.tosVer;
      var ppVer = $store.state.ppVer;
      var agreedPPVersion = agreedPP.value[0];
      var agreedTOSVersion = agreedTOS.value[0];
      var userBool = user.value; // don't show terms unless there is a user (prevents popup on logout?)
      return !(userBool && tosVer === agreedTOSVersion && ppVer === agreedPPVersion);
    });
    var createRefCode = computed(function () {
      var group = idtoken.value.payload["custom:group"];
      var groupExp = idtoken.value.payload["custom:groupExp"];
      var refCode = idtoken.value.payload["custom:refCode"];
      return !(group && groupExp && refCode);
    });
    var darkMode = computed({
      get: function get() {
        return $store.state.config.darkMode;
      },
      set: function set() {
        dark.value = !dark.value;
        $store.commit("config/setDarkMode", {
          vuetify: $vuetify
        });
      }
    });
    var toCid = computed(function () {
      return selectedStock.value.companyid ? selectedStock.value.companyid : defaultCid;
    });
    var toTid = computed(function () {
      return selectedStock.value.tradingitemid ? selectedStock.value.tradingitemid : defaultTid;
    });
    var latestVersion = computed(function () {
      var latestVersion = $store.state.latestAppVer;
      var currentVersion = $store.state.appVer;
      if (latestVersion) {
        return latestVersion !== currentVersion;
      } else {
        return false;
      }
    });
    var loginPath = computed(function () {
      var decodedPath = decodeURIComponent("/");
      return "/login?ref=organic&redirect=".concat(decodedPath);
    }); // Watchers
    watch($route, function (newRouteVal) {
      history.value.push(newRouteVal);
      if ($route.query.onboardingFinished) {
        isSettingsMenuOpen.value = true;
      }
    }); // BEGINS LOGIC FROM beforeCreate lifecycle hook
    // this is first hook called
    // FIXME: also fetch the trkd token?
    $store.dispatch("watchlist/getWatchlists");
    $store.dispatch("screener/getAllScreens", {});
    $store.dispatch("trkd/getAllFeeds", {});
    var dark = useStorage("tikrDarkMode", false);
    try {
      var allPeriodsDefault = parseInt(localStorage.getItem("allPeriodsDefault"));
      if (!isNaN(allPeriodsDefault)) {
        $store.commit("config/setLocalStorage", {
          vuexKey: "allPeriodsDefault",
          localStorageKey: "allPeriodsDefault",
          value: parseInt(allPeriodsDefault)
        });
      }
      var navigatorLang = navigator.language;
      var browserLang = navigatorLang.split("-")[0].toUpperCase();
      var defaultNewsLang = localStorage.getItem("defaultNewsLang");
      var _defaultAppLang = localStorage.getItem("defaultAppLang");
      var availableNewsLanguageCodes = ["EN", "ES", "DE", "FR", "IT", "KO", "PT", "PL", "RU", "TR", "ZH"];
      var availableAppLanguageCodes = ["en", "es", "ja", "zh", "ko"];
      if (typeof defaultNewsLang === "string" && defaultNewsLang !== "EN") {
        $store.commit("config/setLocalStorage", {
          vuexKey: "defaultNewsLang",
          localStorageKey: "defaultNewsLang",
          value: availableNewsLanguageCodes.includes(defaultNewsLang) ? defaultNewsLang : "EN"
        });
      } else {
        // there is no
        $store.commit("config/setLocalStorage", {
          vuexKey: "defaultNewsLang",
          localStorageKey: "defaultNewsLang",
          value: availableNewsLanguageCodes.includes(browserLang) ? browserLang : "EN"
        });
      }
      var includeEnglishNews = localStorage.getItem("includeEnglishNews");
      if (includeEnglishNews === "false") {
        $store.commit("config/setLocalStorage", {
          vuexKey: "includeEnglishNews",
          localStorageKey: "includeEnglishNews",
          value: false
        });
      }
      if (typeof _defaultAppLang === "string" && availableAppLanguageCodes.includes(_defaultAppLang.toLowerCase())) {
        $Amplify.I18n.setLanguage(_defaultAppLang.toLowerCase());
        $store.commit("config/setLocalStorage", {
          vuexKey: "defaultAppLang",
          localStorageKey: "defaultAppLang",
          value: _defaultAppLang.toLowerCase()
        });
      }
    } catch (error) {
      console.error("error setting from localStorage: ", error);
    } // checkLocalStorage to see if they want dark
    watch([function () {
      return $vuetify.theme.dark;
    }, dark], function (newValues) {
      var _newValues = _slicedToArray(newValues, 2),
        vuetifyDark = _newValues[0],
        storageDark = _newValues[1];
      var darkDesire = storageDark === true;
      if (darkDesire !== vuetifyDark) {
        $store.commit("config/setDarkMode", {
          vuetify: $vuetify,
          dark: darkDesire
        });
        if (darkDesire) {
          $vuetify.theme.themes.dark.anchor = {
            base: "#CFD2D6"
          };
        }
      }
    }, {
      immediate: true
    });
    $store.dispatch("getAppInfo"); // this initiates a bypassCache call...
    var fetchDate = new Date();
    lastFetch.value = fetchDate; // ENDS LOGIC FROM beforeCreate  lifecycle hook
    // BEGINS LOGIC FROM created lifecycle hook
    var lang = navigator.language || "en-US";
    var analyticsConfig = _objectSpread({}, baseAnalytics);
    analyticsConfig.AWSPinpoint.endpointId = idtoken.value.payload.sub;
    analyticsConfig.AWSPinpoint.endpoint = {
      userId: idtoken.value.payload.sub
    };
    analyticsConfig.AWSPinpoint.endpoint.channelType = "EMAIL";
    analyticsConfig.AWSPinpoint.endpoint.demographic = {
      locale: lang
    }; // TODO: any other information I want to associate with the endpoint
    // when initalizing pinpoint?
    $Amplify.Core.configure(analyticsConfig);
    window.addEventListener("focus", function () {
      // maybe get the new user too?
      // console.log("window.focus called from default: ", e)
      // should this be awaited?
      $store.dispatch("getAppInfo");
    }); // ENDS LOGIC FROM created lifecycle hook
    onMounted(function () {
      mounted.value = true;
      try {
        var gtmDataLayerVariablesObj = gtmDataLayerVariables;
        if (Object.keys(gtmDataLayerVariablesObj).length > 0) {
          window.dataLayer.push(gtmDataLayerVariablesObj);
        } // or is there some way to terminate existing gtm instance and restart container?
        if (!window.tikrgtmInit) {
          $gtm.init("GTM-N6PV66S");
          window.tikrgtmInit = true; // find some way to check if $gtm is initalized?
          // in the event you initalize with presignup_utm_params?
          // or is there an error if $gtm.init is called twice?
        }
        sendEvent(events.userLogin, {
          event: "userLogin"
        });
      } catch (error) {
        if (dev.value) {
          console.log("Error pushing dataLayer to window.dataLayer");
        }
      } // check for privacy policy and tos here
      // create refCode here too.. just get the code working worry about order later
      var createRefCodeVal = createRefCode.value;
      if (createRefCodeVal) {
        // dispatch the action to create the refCode
        $store.dispatch("createRefCode");
      }
      if ($vuetify.theme.dark) {
        $vuetify.theme.applyVueMeta23();
      }
    }); // Methods
    var handleOnboardingRewatch = function handleOnboardingRewatch() {
      isOnboardingRewatchActive.value = false;
    };
    var fetchOnboardingRewatch = function fetchOnboardingRewatch() {
      isOnboardingRewatchActive.value = true;
    };
    var appLangChange = function appLangChange(e) {
      var lang = e.toLowerCase();
      if (appLanguages.value.includes(lang)) {
        defaultAppLang.value = lang;
        $Amplify.I18n.setLanguage(e.toLowerCase());
        nextTick(function () {
          location.reload(true);
        });
      }
    };
    var agreeToTerms = function agreeToTerms() {
      $store.dispatch("agreeToTerms");
    };
    var updatePage = function updatePage() {
      location.reload(true);
    };
    var upgrade = function upgrade() {
      sendEvent(events.upgradeSelected, {
        event: "upgradeSelected"
      });
      $router.push("/account/subs?ref=".concat(refCode.value));
    };
    var fundaClick = function fundaClick(title) {
      // If no active ticker
      var useFunda = false;
      if (!stockTruthy.value && useFunda) {
        confirm("".concat(I18nFn("Use top search bar to select a ticker to view"), " ").concat(title, "\n").concat(I18nFn("(e.g. NFLX)")));
        $router.back();
      }
    };
    var signOut = function signOut() {
      $store.commit("ciq/resetCIQ");
      $store.commit("fmp/resetFMP");
      $store.commit("trkd/resetTRKD");
      $store.commit("watchlist/resetState");
      $Amplify.Auth.signOut({
        global: true
      }).then(function () {
        $router.push("/login");
        console.log("signout success");
        $store.commit("updateCurrentUser", {
          user: null,
          from: "default signout"
        });
        $store.commit("updateAuthDisplayMap", {
          data: "signedOut"
        });
        $store.commit("signOut");
      })["catch"](function (e) {
        console.error("SignOut Error!", e);
        console.error("SignOut Error stringified", JSON.stringify(e, null, 2));
      });
    }; // use the login path in case
    // we dont have a user object
    var getHref = function getHref(path) {
      return user.value ? path : loginPath.value;
    };
    return {
      __sfc: true,
      isSettingsMenuOpen: isSettingsMenuOpen,
      isOnboardingRewatchActive: isOnboardingRewatchActive,
      isGreaterOrEqual: isGreaterOrEqual,
      checkBusinessModePreference: checkBusinessModePreference,
      hidePrice: hidePrice,
      $route: $route,
      $router: $router,
      dev: dev,
      freeTier: freeTier,
      refCode: refCode,
      idtoken: idtoken,
      restrictResponse: restrictResponse,
      freezeFirstColumn: freezeFirstColumn,
      syncStoreWithLocalStorageBoolean: syncStoreWithLocalStorageBoolean,
      I18nFn: I18nFn,
      history: history,
      defaultCid: defaultCid,
      defaultTid: defaultTid,
      gtmDataLayerVariables: gtmDataLayerVariables,
      events: events,
      sendEvent: sendEvent,
      lastFetch: lastFetch,
      clipped: clipped,
      drawer: drawer,
      miniVariant: miniVariant,
      mounted: mounted,
      $Amplify: $Amplify,
      $featureToggle: $featureToggle,
      $gtm: $gtm,
      $store: $store,
      $vuetify: $vuetify,
      enableDataSourceQueryParams: enableDataSourceQueryParams,
      dataSourceFirstSlug: dataSourceFirstSlug,
      estimatesFirstTabSlug: estimatesFirstTabSlug,
      financialFirstTabSlug: financialFirstTabSlug,
      valuationFirstTabSlug: valuationFirstTabSlug,
      currentActiveModelLink: currentActiveModelLink,
      cid: cid,
      tid: tid,
      appLanguages: appLanguages,
      activeScreener: activeScreener,
      activeNewsfeed: activeNewsfeed,
      refinitiv: refinitiv,
      ciqSearch: ciqSearch,
      user: user,
      fetchingAgreeToTerms: fetchingAgreeToTerms,
      selectedStock: selectedStock,
      showPayments: showPayments,
      largerThanXsBreakpoint: largerThanXsBreakpoint,
      stockTruthy: stockTruthy,
      estimatesUrl: estimatesUrl,
      activeWatchlist: activeWatchlist,
      primaryBrowserLang: primaryBrowserLang,
      defaultAppLang: defaultAppLang,
      watchlistPath: watchlistPath,
      screenerPath: screenerPath,
      newsfeedPath: newsfeedPath,
      agreedTOS: agreedTOS,
      agreedPP: agreedPP,
      paid: paid,
      showAgreeToTerms: showAgreeToTerms,
      createRefCode: createRefCode,
      darkMode: darkMode,
      toCid: toCid,
      toTid: toTid,
      latestVersion: latestVersion,
      loginPath: loginPath,
      dark: dark,
      fetchDate: fetchDate,
      lang: lang,
      analyticsConfig: analyticsConfig,
      handleOnboardingRewatch: handleOnboardingRewatch,
      fetchOnboardingRewatch: fetchOnboardingRewatch,
      appLangChange: appLangChange,
      agreeToTerms: agreeToTerms,
      updatePage: updatePage,
      upgrade: upgrade,
      fundaClick: fundaClick,
      signOut: signOut,
      getHref: getHref,
      ONBOARDING_STEP_INITIAL_PAGE_ID: ONBOARDING_STEP_INITIAL_PAGE_ID,
      ONBOARDING_STEP_MY_WATCHLIST_ID: ONBOARDING_STEP_MY_WATCHLIST_ID,
      ONBOARDING_STEP_TRACK_INVESTING_GURUS_ID: ONBOARDING_STEP_TRACK_INVESTING_GURUS_ID,
      ONBOARDING_STEP_GLOBAL_SCREENER_ID: ONBOARDING_STEP_GLOBAL_SCREENER_ID,
      ONBOARDING_DETAILED_FINANCIALS_ID: ONBOARDING_DETAILED_FINANCIALS_ID,
      ONBOARDING_ANALYST_ESTIMATES_ID: ONBOARDING_ANALYST_ESTIMATES_ID,
      ONBOARDING_WANT_TO_LEARN_MORE_ID: ONBOARDING_WANT_TO_LEARN_MORE_ID
    };
  }
};