import { set } from "vue"
import {
  forecastRowsTemplate,
  historicalYearsToMatch,
} from "~/utils/valuationTransform/peValuationTemplates"
import { tierRestrictions } from "~/utils/valuationTransform/models"
import dayjs from "~/utils/tools/dayjs"
import ciqCurrencyMap from "~/utils/templates/ciqcurrency.json"
import {
  createEstimatesDataObjFromVendorDataArr,
  createFinancialDataObjFromVendorDataArr,
  createValuationDataObjFromVendorDataArr,
  getCellObject,
  getCellValue,
  getFullFilledDates,
  getUserInputDataObject,
  updateCalculatedEstimateDataObj,
} from "~/utils/valuationTransform/index"
import {
  cyclicalDescriptionIdMatch,
  highlySpecializedDescriptionMatch,
} from "~/utils/constants/industryCategories"
import { calculateCAGR } from "~/modules/ratios"
import {
  findFirstAvailableItemInObject,
  findFirstAvailableKeyInObject,
} from "~/utils/tools/object"
import { getYearKey } from "~/utils/tools"
import { hasProp } from "~/utils/screener/screenerConditionUtils"

const getMixedDescription = (isoCodeList) => {
  const isoCodeListStr = isoCodeList.join(" and ")
  return `Valuation model contains currencies in ${isoCodeListStr}`
}

const mapStoreCurrencies = (
  storeCurrencies = [],
  hasMixedCurrencies,
  isoCodeList
) => {
  return storeCurrencies.map((currency, idx) => {
    const isStringCurrency = typeof currency === "string"
    const isMixedCurrenciesItem = hasMixedCurrencies && idx === 0
    if (isStringCurrency) {
      const iso = Object.values(ciqCurrencyMap).find((item) => {
        return item.name === currency
      })?.iso
      const name = isMixedCurrenciesItem ? "Mixed" : currency
      const desc = isMixedCurrenciesItem ? getMixedDescription(isoCodeList) : ""
      return {
        code: iso,
        name,
        description: desc,
      }
    }
    const name = isMixedCurrenciesItem ? "Mixed" : currency?.name
    const desc = isMixedCurrenciesItem ? getMixedDescription(isoCodeList) : ""
    return {
      code: currency?.code,
      name,
      description: desc,
    }
  })
}

const checkDateValidity = (dateKeyStr) => {
  const [year] = dateKeyStr.split("##")
  const currentYear = new Date().getFullYear()
  return currentYear - Number(year) < 2
}

const checkStoreValidity = (dataObj = {}) => {
  if (!dataObj) return false
  const keys = Object.keys(dataObj).filter((key) => key !== "dividendsPerShare")
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index]
    const element = dataObj[key]
    const elementKeys = Object.keys(element)

    for (let i = 0; i < elementKeys.length; i++) {
      const elementKey = elementKeys[i]
      const elementElement = element[elementKey]

      if (!elementElement || Object.keys(elementElement).length === 0) {
        return false
      }
    }
  }
  return true
}

const checkValidKeys = (keys, resDataObj) => {
  return keys.every((keyObj) => {
    return hasProp(resDataObj, keyObj.key)
  })
}

const findKeys = (keyToFind, metricArray) => {
  return metricArray.reduce((acc, item) => {
    const keys = item?.storeMetricKey
    if (Array.isArray(keys)) {
      const keyNeeded = keys.find((key) => key?.[keyToFind])
      acc.push(keyNeeded)
    }
    return acc
  }, [])
}

const checkStoreDataForGaapUse = (metricArray, resDataObj) => {
  const metricsNormalized = findKeys("isNormalized", metricArray)
  const metricsWithGaap = findKeys("isGaap", metricArray)
  const isEveryNormalizedKeyAvailable = checkValidKeys(
    metricsNormalized,
    resDataObj
  )
  const isEveryGaapKeyAvailable = checkValidKeys(metricsWithGaap, resDataObj)
  return { isEveryNormalizedKeyAvailable, isEveryGaapKeyAvailable }
}

const getInitEstimatesDataObj = (
  estimatesObj,
  estimatesMetricsArray,
  isDev,
  amountOfYearsFwd
) => {
  const resData = estimatesObj?.a?.resData || {}
  const mostRecentDateKey = estimatesObj?.a?.mostRecentDateKey
  const gaapUse = checkStoreDataForGaapUse(estimatesMetricsArray, resData)
  const forceGaapMetrics =
    gaapUse.isEveryGaapKeyAvailable && !gaapUse.isEveryNormalizedKeyAvailable
  const { data, labels } = createEstimatesDataObjFromVendorDataArr(
    estimatesMetricsArray,
    resData,
    mostRecentDateKey,
    isDev,
    amountOfYearsFwd,
    forceGaapMetrics
  )

  return { data, labels }
}

const getInitFinancialsDataObj = (
  financialResDataObj,
  mostRecentActualDateKey,
  amountOfYearsForward,
  estimatesMetricsArray
) => {
  const financialStoreResData = financialResDataObj || {}
  return createFinancialDataObjFromVendorDataArr(
    estimatesMetricsArray,
    financialStoreResData,
    mostRecentActualDateKey,
    amountOfYearsForward
  )
}

const getMostRecentActualDateKeyStr = ($ciqStore, isEstimateStoreValid) => {
  const fallback = "2024##FY"
  if (!isEstimateStoreValid) {
    return $ciqStore.financials?.a?.mostRecentDateKey || fallback
  }
  return $ciqStore.estimates?.a?.mostRecentDateKey || fallback
}

const warningMessagesByType = {
  cyclical: (tickerName, industry) =>
    `${tickerName} operates in a cyclical industry (${industry}).`,
  highlySpecialized: (tickerName, industry) =>
    `${tickerName} operates in a highly specialized industry (${industry}), making this valuation framework less relevant.`,
  unprofitable: (tickerName) =>
    `${tickerName} is currently an unprofitable company, making this valuation framework less relevant.`,
}

const lowercaseStr = (item) => item.toLowerCase()
const lowercaseCyclicalMatch = cyclicalDescriptionIdMatch.map(lowercaseStr)
const lowercaseSpecializedMatch =
  highlySpecializedDescriptionMatch.map(lowercaseStr)

const getWarningMessageByCompanyDescription = (
  descriptionToMatch = "",
  tickerName = "",
  hasInvalidEps = false
) => {
  const lowercaseVersion = descriptionToMatch.toLowerCase()
  if (lowercaseCyclicalMatch.includes(lowercaseVersion)) {
    return warningMessagesByType.cyclical(tickerName, descriptionToMatch)
  }
  if (lowercaseSpecializedMatch.includes(lowercaseVersion)) {
    return warningMessagesByType.highlySpecialized(
      tickerName,
      descriptionToMatch
    )
  }
  if (hasInvalidEps) {
    return warningMessagesByType.unprofitable(tickerName)
  }
  return ""
}

const getStockValues = (stockPriceArr, stockFallbackObj) => {
  if (stockPriceArr?.length) {
    const latestStockPriceObj = stockPriceArr[stockPriceArr.length - 1] || {}
    const previousStockPriceObj = stockPriceArr[stockPriceArr.length - 2] || {}

    return {
      latest: latestStockPriceObj,
      previous: previousStockPriceObj,
      diff: {
        percentage:
          (latestStockPriceObj?.v - previousStockPriceObj?.v) /
          previousStockPriceObj?.v,
        value: latestStockPriceObj?.v - previousStockPriceObj?.v,
      },
    }
  }

  return stockFallbackObj
}

const getStoreValidity = (dataObj = {}, mostRecentDateKeyStr) => {
  const isStoreValid = checkStoreValidity(dataObj)
  const keys = Object.keys(dataObj).filter((key) => key !== "dividendsPerShare")
  const result = keys.reduce((acc, item) => {
    const hasValidObject = hasProp(dataObj[item], mostRecentDateKeyStr)
    acc.push(hasValidObject)
    return acc
  }, [])
  const hasValidLastActual = result.every((item) => item === true)
  const isRecentDateKeyStrValid = checkDateValidity(mostRecentDateKeyStr)
  return {
    isValidStore: isStoreValid && isRecentDateKeyStrValid && hasValidLastActual,
    isValidLastActual: hasValidLastActual,
  }
}

const getStoreObj = (storeObj, metricKey, forceDateChange) => {
  const initialStoreObj = storeObj?.[metricKey] || {}
  if (forceDateChange) {
    return Object.keys(initialStoreObj).reduce((acc, key) => {
      const item = initialStoreObj[key]
      if (key === "latest") {
        acc[key] = item
        return acc
      } else {
        const keyYear = Number(key.split("##")[0])
        const dateValueKey = findFirstAvailableKeyInObject({
          item,
          keys: ["dV", "timeVal"],
          defaultEmpty: null,
        })
        const dateValue = item?.[dateValueKey]
        const fullYear = new Date(dateValue).getFullYear()
        if (keyYear !== fullYear) {
          const result = dayjs(dateValue)
            .add(keyYear - fullYear, "year")
            .valueOf()
          acc[key] = {
            ...item,
            [dateValueKey]: result,
          }
        } else {
          acc[key] = {
            ...item,
          }
        }
        return acc
      }
    }, {})
  }
  return initialStoreObj
}

const getAllDates = (
  $ciqStore,
  isFinancialDataSet,
  dateFormatter,
  utcParser
) => {
  let dates = $ciqStore.estimates.a?.dates || []
  const financialDatesArr =
    $ciqStore.financials.a?.dates.filter((i) => i.periodtypeid === 1) || []
  if (isFinancialDataSet) {
    dates = financialDatesArr.reduce((acc, item) => {
      if (acc.find((i) => i.calendaryear === item.calendaryear)) return acc
      acc.push(item)
      return acc
    }, [])
  }
  return dates.map((dateObj) => {
    const calendarYear = dateObj?.calendaryear
    const fiscalYear = dayjs.utc(dateObj?.epoch).year()
    const label = dateObj?.text
    const dateKey = `${calendarYear}##FY`
    const isEstimate = dateObj?.isEstimate
    const obj = {
      isEstimate,
      epoch: dateObj?.epoch,
      calendarYear,
      fiscalYear,
      dateKey,
      text: dateFormatter.format(dateObj.epoch),
      label,
      advancedate: dateObj?.advancedate || dateObj?.fifilingdate,
    }
    if (!isEstimate) {
      const datesFromSameYear = financialDatesArr.filter(
        (i) => i.calendaryear === calendarYear
      )
      const lastDate = datesFromSameYear[datesFromSameYear.length - 1]
      const fiperiodenddate = lastDate?.fiperiodenddate
      const formattedFiperiodenddate = dateFormatter.format(
        utcParser(fiperiodenddate)
      )
      return {
        ...obj,
        fiperiodenddate,
        formattedFiperiodenddate,
      }
    }
    return obj
  })
}

const getAllDatesByType = ($ciqStore, dataSet, dateFormatter, utcParser) => {
  const isFinancialDataSet = dataSet === "financials"
  return getAllDates($ciqStore, isFinancialDataSet, dateFormatter, utcParser)
}

const toPercentage = (num) => {
  const scaled = num * 100
  if (Number.isInteger(scaled)) {
    return scaled.toFixed()
  } else {
    return scaled.toFixed(2)
  }
}

const numFormatterFn = (
  value,
  type,
  percentFormatter,
  numberFormatter,
  currencyFormatter
) => {
  if (type === "pct") {
    return percentFormatter?.format(value)
  } else if (type === "currency") {
    return currencyFormatter.format(value)
  }
  return numberFormatter?.format(value)
}

const getIrr = (
  storeObj,
  recentActualDateKeyStr,
  relativeDateKey,
  isHistorical,
  labelFn,
  formattersObj,
  dateArr
) => {
  const dateKey = getYearKey(recentActualDateKeyStr, relativeDateKey)

  if (isHistorical) {
    const initialDateKeyHeader = dateArr.find(
      (item) => item.dateKey === dateKey
    )
    const finalDateKeyHeader = dateArr.find(
      (item) => item.dateKey === recentActualDateKeyStr
    )
    const stockPriceAtCertainYear = getCellValue("stockPrice", dateKey, [
      storeObj,
    ])
    const stockPriceAtCertainYearObj = getCellObject("stockPrice", dateKey, [
      storeObj,
    ])
    const mostRecentActualStockPriceObj = getCellObject(
      "stockPrice",
      recentActualDateKeyStr,
      [storeObj]
    )
    const finalDate = finalDateKeyHeader?.epoch
    const initialDate = initialDateKeyHeader?.epoch
    const { cagr } = calculateCAGR({
      initialValue: stockPriceAtCertainYear,
      lastValue: mostRecentActualStockPriceObj?.v,
      firstDate: initialDate,
      lastDate: finalDate,
    })
    const fromDate = formattersObj.date.format(initialDate)
    const toDate = formattersObj.date.format(finalDate)
    const unauth = [
      stockPriceAtCertainYearObj,
      mostRecentActualStockPriceObj,
    ].some((item) => item?.unauth === true)
    const label = labelFn({
      value: cagr,
      fromDate,
      toDate,
      numFormatter: (val, type) =>
        numFormatterFn(
          val,
          type,
          formattersObj.percent,
          formattersObj.number,
          formattersObj.currency
        ),
    })
    return {
      v: cagr,
      label,
      unauth,
    }
  }
  const fromObj = storeObj?.annualizedReturn?.[recentActualDateKeyStr]
  const fromDate = formattersObj.date?.format(fromObj?.timeVal)

  const storeObjItem = storeObj?.annualizedReturn?.[dateKey]
  const toDate = formattersObj.date?.format(
    dayjs.utc(storeObjItem.dV || storeObjItem.timeVal).valueOf()
  )
  const unauth = fromObj?.unauth || storeObjItem?.unauth
  const v = storeObjItem?.v
  const from = fromObj?.v
  let label = ""
  if (labelFn) {
    label = labelFn({
      value: v,
      fromDate,
      toDate,
      from,
      to: v,
      numFormatter: (val, type) =>
        numFormatterFn(
          val,
          type,
          formattersObj.percent,
          formattersObj.number,
          formattersObj.currency
        ),
    })
  }

  return {
    v: storeObjItem?.v || null,
    label,
    unauth,
  }
}

const getDivision = (
  nominator,
  denominator,
  dateKey,
  labelFn,
  formattersObj,
  mostRecentActualDateKeyStr,
  currentIndex
) => {
  const nominatorObj = nominator?.[dateKey]
  const denominatorObj = denominator?.[dateKey]
  const nominatorValue = nominatorObj?.v
  const denominatorValue = denominatorObj?.v
  const isValid = isFinite(nominatorValue) && isFinite(denominatorValue)
  const value = nominatorValue / denominatorValue
  const fromDate = formattersObj.date?.format(nominatorObj?.timeVal)
  const cyNominatorVal = nominator?.[mostRecentActualDateKeyStr]?.v
  const cyDenominatorVal = denominator?.[mostRecentActualDateKeyStr]?.v
  const cyValue = cyNominatorVal / cyDenominatorVal
  const cyDate = formattersObj.date?.format(
    nominator?.[mostRecentActualDateKeyStr]?.timeVal
  )
  const label = labelFn({
    from: value,
    fromDate,
    index: currentIndex,
    currentYearValue: cyValue,
    currentYearDate: cyDate,
    numFormatter: (val, type) =>
      numFormatterFn(
        val,
        type,
        formattersObj.percent,
        formattersObj.number,
        formattersObj.currency
      ),
  })
  const unauth = [nominatorObj, denominatorObj].some(
    (item) => item?.unauth === true
  )
  if (isValid) {
    return {
      v: value,
      label,
      unauth,
    }
  }
  return 0
}

const getNormalizedNumber = (num) => {
  if (Number.isInteger(num)) {
    return num
  } else {
    return num?.toFixed(2) || num
  }
}

const getYoYDivValue = (
  storeObj,
  metricKey,
  denominatorKey,
  recentActualDateKeyStr,
  key,
  labelFn,
  formattersObj,
  dateArr
) => {
  const currentYearObj = storeObj?.[metricKey]?.[recentActualDateKeyStr]
  const yearAgoObj = storeObj?.[metricKey]?.[key]
  const currentYearNominator =
    storeObj?.[metricKey]?.[recentActualDateKeyStr]?.v
  const currentYearDenominatorObj =
    storeObj?.[denominatorKey]?.[recentActualDateKeyStr]
  const currentYearDenominator = currentYearDenominatorObj?.v
  const currentYearDiv = currentYearNominator / currentYearDenominator

  const yearAgoNominatorObj = storeObj?.[metricKey]?.[key]
  const yearAgoNominator = yearAgoNominatorObj?.v
  const yearAgoDenominatorObj = storeObj?.[denominatorKey]?.[key]
  const yearAgoDenominator = yearAgoDenominatorObj?.v
  const yearAgoDiv = yearAgoNominator / yearAgoDenominator

  const cellAValue = yearAgoDiv
  const cellBValue = currentYearDiv
  const dateKeys = ["dV", "timeVal"]
  const yearAgoDate = findFirstAvailableItemInObject({
    item: yearAgoObj,
    keys: dateKeys,
    defaultEmpty: null,
  })
  const normalizedFrom = getNormalizedNumber(yearAgoDiv)
  const normalizedTo = getNormalizedNumber(currentYearDiv)
  const currentYearDate = dateArr.find(
    (item) => item.dateKey === recentActualDateKeyStr
  )?.epoch
  const cagr = calculateCAGR({
    initialValue: cellAValue,
    lastValue: cellBValue,
    firstDate: yearAgoDate,
    lastDate: currentYearDate,
  })
  const label = labelFn({
    from: normalizedFrom,
    fromDate: formattersObj.date?.format(yearAgoDate),
    to: normalizedTo,
    toDate: formattersObj.date?.format(currentYearDate),
    numFormatter: (val, type) =>
      numFormatterFn(
        val,
        type,
        formattersObj.percent,
        formattersObj.number,
        formattersObj.currency
      ),
  })
  const unauth = [
    currentYearObj,
    currentYearDenominatorObj,
    yearAgoObj,
    yearAgoDenominatorObj,
  ].some((item) => item?.unauth === true)

  return {
    v: cagr.cagr,
    label,
    unauth,
  }
}

const getLatestForwardValue = (
  storeObject,
  metricKey,
  recentActualDateKeyStr,
  offset,
  labelFn,
  formattersObj,
  dateArr
) => {
  const key = getYearKey(recentActualDateKeyStr, offset)
  const metricObj = storeObject?.[metricKey]?.[key]
  const v = metricObj?.v
  const unauth = metricObj?.unauth
  let label = ""
  const fromDate = dateArr.find(
    (item) => item.dateKey === recentActualDateKeyStr
  )?.epoch
  const toDate = dateArr.find((item) => item.dateKey === key)?.epoch

  if (labelFn) {
    label = labelFn({
      fromDateEpoch: fromDate,
      fromDate: formattersObj.date?.format(fromDate),
      value: v,
      toDateEpoch: toDate,
      toDate: formattersObj.date?.format(toDate),
      numFormatter: (val, type) =>
        numFormatterFn(
          val,
          type,
          formattersObj.percent,
          formattersObj.number,
          formattersObj.currency
        ),
    })
  }
  return {
    v,
    label,
    unauth,
  }
}

const getYoYValue = (
  obj,
  recentActualDateKeyStr,
  relativeDateKey,
  labelFn,
  formattersObj
) => {
  const currentObj = obj?.[recentActualDateKeyStr] || {}
  const previousYearKey = getYearKey(recentActualDateKeyStr, relativeDateKey)
  const previousYearObj = obj?.[previousYearKey] || {}
  const cellAValue = previousYearObj?.v
  const cellBValue = currentObj?.v
  const value = cellBValue / cellAValue - 1

  const fromDate = formattersObj.date?.format(previousYearObj?.timeVal)
  const toDate = formattersObj.date?.format(currentObj?.timeVal)
  const label = labelFn({
    value: toPercentage(value),
    from: cellAValue,
    fromDate,
    to: cellBValue,
    toDate,
    numFormatter: (val, type) =>
      numFormatterFn(
        val,
        type,
        formattersObj.percent,
        formattersObj.number,
        formattersObj.currency
      ),
  })
  if (isFinite(value)) {
    return {
      v: value,
      label,
    }
  }
  return {
    v: null,
    label,
  }
}

const getDateFromDateArr = (dateArr, dateKey) => {
  return dateArr.find((item) => item.dateKey === dateKey)?.epoch
}

const getAnnualizedReturn = (
  storeObject,
  recentActualDateKeyStr,
  relativeDateKey,
  labelFn,
  formattersObj,
  dateArr
) => {
  const dateKey = getYearKey(recentActualDateKeyStr, relativeDateKey)
  const fromDateObj = dateArr.find((item) => item.dateKey === dateKey)
  const toDateObj = dateArr.find(
    (item) => item.dateKey === recentActualDateKeyStr
  )
  const fromDate = formattersObj.date.format(fromDateObj?.epoch)
  const toDate = formattersObj.date.format(toDateObj?.epoch)
  const currentStockPriceObj = storeObject.stockPrice?.[recentActualDateKeyStr]
  const currentStockPriceVal = currentStockPriceObj?.v
  const dateKeyStockPriceObj = storeObject.stockPrice?.[dateKey]
  const dateKeyStockPriceVal = dateKeyStockPriceObj?.v
  const result = currentStockPriceVal / dateKeyStockPriceVal - 1
  const unauth = dateKeyStockPriceObj?.unauth || currentStockPriceObj?.unauth
  const label = labelFn({
    value: result,
    fromDate,
    toDate,
    numFormatter: (val, type) =>
      numFormatterFn(
        val,
        type,
        formattersObj.percent,
        formattersObj.number,
        formattersObj.currency
      ),
  })
  return {
    v: result,
    unauth,
    label,
  }
}

const getCAGRValue = (
  obj,
  recentActualDateKeyStr,
  cagrStartOffset,
  cagrEndOffset,
  labelFn,
  formattersObj,
  differentDateObj,
  dateArr
) => {
  const timeKeys = ["dV", "timeVal"]
  const startYearKey = getYearKey(recentActualDateKeyStr, cagrStartOffset)
  const startYearObj = obj?.[startYearKey] || {}
  const startYearValue = startYearObj?.v
  let startYearDate = findFirstAvailableItemInObject({
    item: startYearObj,
    keys: timeKeys,
    defaultEmpty: null,
  })
  if (startYearKey === recentActualDateKeyStr) {
    startYearDate = getDateFromDateArr(dateArr, recentActualDateKeyStr)
  }
  const endYearKey = getYearKey(recentActualDateKeyStr, cagrEndOffset)
  const endYearObj = obj?.[endYearKey] || {}
  const endYearValue = endYearObj?.v
  // this happens because the dates of eps
  // and the dates of valuationEpsNormalized are different
  const endDateToLookup = differentDateObj
    ? differentDateObj?.[endYearKey]
    : endYearObj
  const endYearDate = findFirstAvailableItemInObject({
    item: endDateToLookup,
    keys: timeKeys,
    defaultEmpty: null,
  })
  const isValid =
    isFinite(startYearValue) &&
    isFinite(endYearValue) &&
    isFinite(startYearDate) &&
    isFinite(endYearDate)
  const unauth = [startYearObj, endYearObj].some(
    (item) => item?.unauth === true
  )

  if (isValid) {
    const cagr = calculateCAGR({
      initialValue: startYearValue,
      lastValue: endYearValue,
      firstDate: startYearDate,
      lastDate: endYearDate,
    })

    const formattedStartYearDate = formattersObj.date?.format(startYearDate)
    const formattedEndYearDate = formattersObj.date?.format(endYearDate)

    const label = labelFn({
      from: startYearValue,
      fromDate: formattedStartYearDate,
      to: endYearValue,
      toDate: formattedEndYearDate,
      numFormatter: (val, type) =>
        numFormatterFn(
          val,
          type,
          formattersObj.percent,
          formattersObj.number,
          formattersObj.currency
        ),
    })

    return {
      v: cagr.cagr,
      label,
      unauth,
    }
  }

  return {
    v: null,
    label: "",
  }
}

const getValueByFormula = ({
  storeObj,
  metricKey,
  formula,
  recentActualDateKeyStr,
  // because we are duplicating old objects to use as a base for the forecast ones
  // the original dates are there in the forecasted objects also, so we need a way
  // to change the date to the future
  forceDateChange = false,
  labelFn = null,
  dateFormatter = null,
  numberFormatter = null,
  percentFormatter = null,
  currencyFormatter = null,
  useDifferentDate = null,
  index = null,
  dateArr = [],
}) => {
  const currentObjNew = getStoreObj(storeObj, metricKey, forceDateChange)
  const formulaString = formula?.type || formula
  const isDiv = formulaString.startsWith("div")
  const isYoYDiv = formulaString.startsWith("YoYdiv")
  const isAnnualizedReturn = formulaString === "annualizedReturn"
  const relativeDateKey = formula?.relativeDateKey || 0
  const denominatorKey = isDiv || isYoYDiv ? formulaString.split(" ")[1] : null
  const denominatorObj =
    isDiv || isYoYDiv
      ? getStoreObj(storeObj, denominatorKey, forceDateChange)
      : null
  const isHistorical = !forceDateChange
  const formatters = {
    date: dateFormatter,
    number: numberFormatter,
    percent: percentFormatter,
    currency: currencyFormatter,
  }
  let differentDateObj = null
  if (useDifferentDate) {
    differentDateObj = getStoreObj(storeObj, useDifferentDate, true)
  }

  if (isAnnualizedReturn) {
    return getAnnualizedReturn(
      storeObj,
      recentActualDateKeyStr,
      relativeDateKey,
      labelFn,
      formatters,
      dateArr
    )
  }

  if (isYoYDiv) {
    const key = getYearKey(recentActualDateKeyStr, relativeDateKey)
    return getYoYDivValue(
      storeObj,
      metricKey,
      denominatorKey,
      recentActualDateKeyStr,
      key,
      labelFn,
      formatters,
      dateArr
    )
  }
  if (isDiv) {
    const key = getYearKey(recentActualDateKeyStr, relativeDateKey)
    return getDivision(
      currentObjNew,
      denominatorObj,
      key,
      labelFn,
      formatters,
      recentActualDateKeyStr,
      index
    )
  }
  switch (formulaString) {
    case "yoy":
      return getYoYValue(
        currentObjNew,
        recentActualDateKeyStr,
        relativeDateKey,
        labelFn,
        formatters
      )
    case "cagr":
      return getCAGRValue(
        currentObjNew,
        recentActualDateKeyStr,
        formula.startRelativePeriod,
        formula.endRelativePeriod,
        labelFn,
        formatters,
        differentDateObj,
        dateArr
      )

    case "irr":
      return getIrr(
        storeObj,
        recentActualDateKeyStr,
        relativeDateKey,
        isHistorical,
        labelFn,
        formatters,
        dateArr
      )
    case "latestForwardValue":
    case "latestForwardMinusOne":
      return getLatestForwardValue(
        storeObj,
        metricKey,
        recentActualDateKeyStr,
        relativeDateKey,
        labelFn,
        formatters,
        dateArr
      )
    default:
      break
  }
}

const getMetricData = (
  obj,
  item,
  recentActualDateKeyStr,
  dateFormatterFn,
  numberFormatter,
  percentFormatter,
  currencyFormatter,
  dateArr,
  currentUserTier
) => {
  const key = item.key
  const data = []
  const formulas = item.formulas
  for (let i = 0; i < formulas.length; i++) {
    const formulaStrOrObj = formulas[i]
    const relativeKey =
      formulaStrOrObj?.relativeDateKey || formulaStrOrObj.startRelativePeriod
    const newData = getValueByFormula({
      storeObj: obj,
      metricKey: key,
      formula: formulaStrOrObj,
      recentActualDateKeyStr,
      labelFn: item.labelFn,
      dateFormatter: dateFormatterFn,
      numberFormatter,
      percentFormatter,
      currencyFormatter,
      index: i,
      dateArr,
      currentUserTier,
    })
    const unauthorizedByTier = getUnauthPropByTier(
      relativeKey,
      currentUserTier,
      tierRestrictions
    )
    data.push({
      dependencies: { key, formulaStrOrObj, recentActualDateKeyStr },
      v: newData.v,
      label: newData.label,
      unauth: newData.unauth || unauthorizedByTier,
      relativeKey,
    })
  }
  return data
}

const getForecastFormula = (item, startYear, endYear) => {
  const { formula } = item
  const formulaString = formula?.type || formula
  const isCagr = formulaString === "cagr"
  const isIrr = typeof formula === "string" && formula === "irr"
  const isLatestForward = formulaString === "latestForwardValue"
  const isLatestForwardMinusOne = formulaString === "latestForwardMinusOne"
  const isDivision = typeof formula === "string" && formula.startsWith("div")
  const isPriceOverEarningsMultiple = item.key === "priceOverEarningsMultiple"
  const amountOfYearsForward = endYear - startYear
  const currentYear = new Date().getFullYear()
  const newInitialOffset = startYear - currentYear

  if (isCagr) {
    const offset = isPriceOverEarningsMultiple ? 0 : 1
    return {
      type: "cagr",
      startRelativePeriod: newInitialOffset,
      endRelativePeriod: amountOfYearsForward + offset,
    }
  }
  if (isDivision || isLatestForward || isIrr || isLatestForwardMinusOne) {
    const offset = isIrr || isLatestForwardMinusOne ? 0 : 1
    const relativeDateKey = amountOfYearsForward + offset
    return {
      type: formulaString,
      relativeDateKey,
    }
  }
  return formula
}

const getForecastMetricData = (
  assumptionCaseArr,
  assumptionsOutput,
  item,
  mostRecentActualDateKey,
  dateFormatterFn = null,
  numberFormatterFn = null,
  percentFormatterFn = null,
  currencyFormatterFn = null,
  dateArr = [],
  startYear,
  endYear
) => {
  const formula = getForecastFormula(item, startYear, endYear)
  const useDifferentDate = item.useDatesFrom

  if (assumptionCaseArr.length === 0) {
    const storeObj = assumptionsOutput[0]
    const { v, label, unauth } = getValueByFormula({
      storeObj,
      metricKey: item.key,
      formula,
      recentActualDateKeyStr: mostRecentActualDateKey,
      forceDateChange: true,
      labelFn: item.labelFn,
      dateFormatter: dateFormatterFn,
      numberFormatter: numberFormatterFn,
      percentFormatter: percentFormatterFn,
      currencyFormatter: currencyFormatterFn,
      useDifferentDate,
      index: 0,
      dateArr,
    })
    let result = {}
    if (isFinite(v)) {
      result = {
        dependencies: { key: item.key, formula, mostRecentActualDateKey },
        v,
        label,
        unauth,
      }
    }
    return [result]
  }
  return assumptionCaseArr.map((_, idx) => {
    const storeObj = assumptionsOutput[idx]
    const { v, label, unauth } = getValueByFormula({
      storeObj,
      metricKey: item.key,
      formula,
      recentActualDateKeyStr: mostRecentActualDateKey,
      forceDateChange: true,
      labelFn: item.labelFn,
      dateFormatter: dateFormatterFn,
      numberFormatter: numberFormatterFn,
      percentFormatter: percentFormatterFn,
      currencyFormatter: currencyFormatterFn,
      useDifferentDate,
      index: idx,
      dateArr,
    })
    if (isFinite(v)) {
      return {
        dependencies: { key: item.key, formula, mostRecentActualDateKey },
        v,
        label,
        unauth,
      }
    }
    return null
  })
}

const checkDPSValidity = (estimatesDpsObj, financialsDpsObj) => {
  const estimatesKeys = Object.keys(estimatesDpsObj || [])
  const financialsKeys = Object.keys(financialsDpsObj || [])

  for (let i = 0; i < estimatesKeys.length; i++) {
    const key = estimatesKeys[i]
    const element = estimatesDpsObj[key]

    if (!element || Object.keys(element).length === 0) {
      return false
    }
  }

  for (let i = 0; i < financialsKeys.length; i++) {
    const key = financialsKeys[i]
    const element = financialsDpsObj[key]
    if (!element || Object.keys(element).length === 0) {
      return false
    }
  }
  return true
}

const getPriceOverEarningsAvg = (metricObject) => {
  const dataArr = metricObject?.data || []
  const threeYearCut = dataArr.filter((item) => {
    const itemDate = dayjs.utc(item[0]).valueOf()
    // check if date is in the three year range using dayjs utc
    return itemDate >= dayjs.utc().subtract(3, "year").valueOf()
  })
  // grab last (threeYearCut.length + 1) datapoints from the end of the array
  const data = dataArr.slice(-threeYearCut.length - 1)
  const sum = data.reduce((acc, item) => acc + item[1], 0)
  const avg = sum / data.length
  return avg
}

const getPlotBandsData = (latestStockPriceObj, seriesArr) => {
  const lastItem = seriesArr[seriesArr.length - 1]
  return {
    from: latestStockPriceObj.dV,
    to: lastItem[0],
  }
}

const getUtcDateFromDateArr = (dateArr, year) => {
  return dateArr.find((item) => item.calendarYear === year)?.epoch
}

const getChartData = (
  assumptionCaseArr,
  assumptionsObjectArray,
  chartOffsetArr,
  mostRecentActualDateKey,
  datesArr,
  amountOfYearsForward
) => {
  const defaultAssumptionCaseIndex = assumptionCaseArr.findIndex(
    (item) => item.isDefault || item.name === "Mid"
  )
  const [latestActualYear] = mostRecentActualDateKey.split("##").map(Number)
  const firstForecastYearKey = getYearKey(mostRecentActualDateKey, 1)
  const firstForecastDateObj = datesArr.find(
    (item) => item.dateKey === firstForecastYearKey
  )
  const firstForecastDate = firstForecastDateObj?.epoch
  const assumptionObject = assumptionsObjectArray[defaultAssumptionCaseIndex]
  const latestStockPriceObj = assumptionObject?.stockPrice?.latest
  const currentStockPriceValue = latestStockPriceObj?.v
  const currentStockPriceDate = latestStockPriceObj?.dV
  const isAheadOfFirstForecast = currentStockPriceDate > firstForecastDate
  const futureStockPriceDatesCount = datesArr.filter(
    (item) => item?.epoch > currentStockPriceDate
  )?.length
  const yearsAheadOfStockPrice =
    amountOfYearsForward - futureStockPriceDatesCount
  const offsetInitialValue = yearsAheadOfStockPrice

  const sortByTimestamp = (a, b) => a[0] - b[0]

  const seriesArr = chartOffsetArr
    .reduce((acc, offset) => {
      const yearToLookUpTo = latestActualYear + offset
      const utcDate = getUtcDateFromDateArr(datesArr, yearToLookUpTo)
      const isAhead = offset > offsetInitialValue
      const metricKey = isAhead
        ? "totalStockPriceIncludingDividends"
        : "stockPrice"

      const keyToLookUpTo = `${yearToLookUpTo}##FY`
      const value = assumptionObject?.[metricKey]?.[keyToLookUpTo]?.v
      acc.push([utcDate, value])
      if (offset === 0) {
        acc.push([currentStockPriceDate, currentStockPriceValue])
      }
      return acc
    }, [])
    .sort(sortByTimestamp)

  const plotBandsData = getPlotBandsData(latestStockPriceObj, seriesArr)

  const lowCaseIndex = assumptionCaseArr.findIndex(
    (item) => item.name === "Low"
  )
  const highCaseIndex = assumptionCaseArr.findIndex(
    (item) => item.name === "High"
  )

  const extraData = chartOffsetArr.reduce((acc, offset) => {
    const isAhead = offset > 0
    const yearToLookUpTo = latestActualYear + offset
    const utcDate = getUtcDateFromDateArr(datesArr, yearToLookUpTo)

    if (isAhead) {
      const keyToLookUpTo = `${yearToLookUpTo}##FY`
      acc[utcDate] = {
        unauth: assumptionObject?.annualizedReturn?.[keyToLookUpTo]?.unauth,
        irrMid: assumptionObject?.annualizedReturn?.[keyToLookUpTo]?.v,
        irrLow:
          assumptionsObjectArray?.[lowCaseIndex]?.annualizedReturn?.[
            keyToLookUpTo
          ]?.v,
        irrHigh:
          assumptionsObjectArray?.[highCaseIndex]?.annualizedReturn?.[
            keyToLookUpTo
          ]?.v,
      }
    }

    return acc
  }, {})

  const rangeArr = chartOffsetArr
    .reduce((acc, offset) => {
      let yearToLookUpTo = latestActualYear + offset
      const utcDate = getUtcDateFromDateArr(datesArr, yearToLookUpTo)
      const isPast = offset < 0
      if (isPast) {
        acc.push([utcDate, null, null])
        return acc
      } else if (offset === offsetInitialValue) {
        acc.push([
          currentStockPriceDate,
          currentStockPriceValue,
          currentStockPriceValue,
        ])
        return acc
      }
      yearToLookUpTo = yearToLookUpTo + 1
      const metricKey = isPast
        ? "stockPrice"
        : "totalStockPriceIncludingDividends"
      const keyOffset = -1
      const keyToLookUpTo = `${yearToLookUpTo + keyOffset}##FY`
      const lowCaseValue =
        assumptionsObjectArray[lowCaseIndex]?.[metricKey]?.[keyToLookUpTo]?.v
      const highCaseValue =
        assumptionsObjectArray[highCaseIndex]?.[metricKey]?.[keyToLookUpTo]?.v
      acc.push([utcDate, lowCaseValue, highCaseValue])
      return acc
    }, [])
    .sort(sortByTimestamp)

  const stockPriceDataPoint = [[currentStockPriceDate, currentStockPriceValue]]
  const pointStart = seriesArr[0][0]

  return {
    seriesArr,
    rangeArr,
    stockPriceDataPoint,
    plotBandsData,
    pointStart,
    extraData,
    isAheadOfFirstForecast,
  }
}

const checkForMixedCurrencies = (initialDataObject) => {
  const mappedCurrencies = Object.values(initialDataObject).reduce(
    (currencies, element) => {
      const elementCurrencies = Object.values(element)
        .filter((dataObj) => dataObj && dataObj !== null)
        .map((dataObj) => dataObj?.iso)
      return [...currencies, ...elementCurrencies]
    },
    []
  )
  const uniqueCurrencies = new Set(mappedCurrencies)
  return {
    hasMixedCurrencies: uniqueCurrencies.size > 1,
    isoCodes: uniqueCurrencies,
  }
}

const createTimestampArray = (
  chartYearOffset,
  fullfilledDates,
  mostRecentActualDateKey
) => {
  const indexOfMostRecentActual = fullfilledDates.findIndex(
    (item) => item.dateKey === mostRecentActualDateKey
  )
  return chartYearOffset.map((offset) => {
    const indexToLookup = indexOfMostRecentActual + offset
    return fullfilledDates[indexToLookup]?.epoch
  })
}

const createChartData = ({
  offset,
  fullfilledDates,
  mostRecentActualDateKeyStr,
  assumptionCases,
  assumptionsOutput,
  formatters,
  amountOfYearsForward,
}) => {
  const datesArray = createTimestampArray(
    offset,
    fullfilledDates,
    mostRecentActualDateKeyStr
  )
  const currentStockPriceIndex = offset.findIndex((item) => item === 0) + 1
  const chartData = getChartData(
    assumptionCases,
    assumptionsOutput,
    offset,
    mostRecentActualDateKeyStr,
    fullfilledDates,
    amountOfYearsForward
  )

  const yAxisLabelFormatter = (value) => {
    return formatters?.formatCurrencyNoDecimals.format(value)
  }
  const tooltipValueFormatter = (value) => {
    return formatters?.formatCurrency.format(value)
  }
  const floatFormatter = (val) => {
    return formatters?.float.format(val)
  }
  const dateFormatter = (timestamp) => {
    return formatters?.dateFormatter.format(timestamp)
  }

  return {
    floatFormatter,
    tooltipValueFormatter,
    yAxisLabelFormatter,
    dateFormatter,
    tickPositions: datesArray,
    currentStockPriceIndex,
    ...chartData,
  }
}

const createNumberFormatter = ({ minDigits, maxDigits, lang = "default" }) => {
  return new Intl.NumberFormat(lang, {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  })
}

const createPercentFormatter = ({ minDigits, maxDigits, lang = "default" }) => {
  return new Intl.NumberFormat(lang, {
    style: "percent",
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  })
}

const createCurrencyFormatter = ({
  minDigits,
  maxDigits,
  lang = "default",
  isocode,
}) => {
  return new Intl.NumberFormat(lang, {
    style: "currency",
    currency: isocode,
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  })
}

const createHistoricalTable = ({
  templateRows,
  valuationOutput,
  mostRecentActualDateKeyStr,
  dateFormatter,
  numberFormatter,
  percentFormatter,
  currencyFormatter,
  fullfilledDates,
  currentUserTier,
}) => {
  return templateRows.reduce((acc, item) => {
    const data =
      getMetricData(
        valuationOutput,
        item,
        mostRecentActualDateKeyStr,
        dateFormatter,
        numberFormatter,
        percentFormatter,
        currencyFormatter,
        fullfilledDates,
        currentUserTier
      ) || []
    acc.push({
      label: item.label,
      key: item.key,
      format: item?.format || "oneDigitPct",
      data,
    })
    return acc
  }, [])
}

const getInitialDateStartingPoint = ({ dates = [], dateRange = [] }) => {
  const filteredDates = dates.filter((_, idx) => {
    return idx >= dateRange[0] && idx <= dateRange[1]
  })
  const firstDateObj = filteredDates[0]
  const currentYear = dayjs.utc().year()
  const startYear =
    firstDateObj?.calendarYear < currentYear
      ? currentYear
      : firstDateObj?.calendarYear
  const amountOfEstimates = filteredDates.filter(
    (item) => item.isEstimate
  ).length

  if (amountOfEstimates <= 1) {
    return {
      startYear: currentYear,
      endYear: currentYear + 5,
    }
  }

  return { startYear, endYear: startYear + (amountOfEstimates - 1) }
}

const getFormattedCurrencies = ($ciqStore, dataSet, initialDataObj) => {
  const { hasMixedCurrencies, isoCodes } =
    checkForMixedCurrencies(initialDataObj)
  if (dataSet === "financials") {
    return mapStoreCurrencies(
      $ciqStore?.financialsCurrencies || [],
      hasMixedCurrencies,
      isoCodes
    )
  }
  return mapStoreCurrencies(
    $ciqStore?.estimatesCurrencies || [],
    hasMixedCurrencies,
    isoCodes
  )
}

const createVendorDataObj = ({
  initialData,
  $store,
  mostRecentActual,
  amountOfYearsForward,
  fullfilledDates,
  currentIso,
  valuationMetricsArr,
  computedMetricsArr,
}) => {
  // valuation
  const tid = $store.state.ciq.ticker.tradingitemid
  const multiplesStore = $store.state.ciq?.addMultiples?.[tid]
  const valuationStoreResData = multiplesStore?.tblDataObj || {}
  const pricecloseArr = multiplesStore?.resData.priceclose
  const epsNormalizedArr = multiplesStore?.resData?.["eps normalized"]
  const computedLabels = computedMetricsArr.reduce((acc, item) => {
    acc[item.newCalculatedMetricKey] = item.label
    return acc
  }, {})
  const mappedLabels = valuationMetricsArr.reduce((acc, item) => {
    acc[item.metricKey] = item.label
    return acc
  }, computedLabels)
  const valuationDataObj = createValuationDataObjFromVendorDataArr(
    valuationMetricsArr,
    valuationStoreResData,
    mostRecentActual,
    amountOfYearsForward,
    pricecloseArr,
    $store.state.ciq?.financials?.a.dates,
    epsNormalizedArr,
    initialData
  )
  const calculatedDataObj = updateCalculatedEstimateDataObj(
    computedMetricsArr,
    initialData,
    valuationDataObj,
    fullfilledDates,
    currentIso,
    mostRecentActual
  )
  return {
    data: {
      ...initialData,
      ...valuationDataObj,
      ...calculatedDataObj,
    },
    labels: mappedLabels,
  }
}

const checkForMandatoryData = (
  dataObject,
  requiredMetricKeys,
  mostRecentActualDateKeyStr
) => {
  const hasRequiredKeys = checkForRequiredKeys(requiredMetricKeys, dataObject)
  const hasEnoughData = checkForRequiredDateKeys(
    requiredMetricKeys,
    dataObject,
    mostRecentActualDateKeyStr
  )
  return hasRequiredKeys && hasEnoughData
}

const createStoreDataObject = ({
  $store,
  isDev,
  currentDataset,
  parseUtcDateTime,
  utcEpochToShortDate,
  estimatesDataArr,
  valuationDataArr,
  computedMetricsArr,
  currentIso,
  requiredMetricKeys,
}) => {
  const amountOfYearsForward =
    $store.state.valuationModel.data.amountOfYearsForward
  const $ciqStore = $store.state.ciq
  const $tickerData = $ciqStore.ticker
  const tid = $tickerData?.tradingitemid
  const mostRecentDateKeyEstimates = $ciqStore.estimates?.a?.mostRecentDateKey
  const mostRecentDateKeyFinancials = $ciqStore.financials?.a?.mostRecentDateKey
  const { data: initEstimatesDataObj, labels: estimatesLabels } =
    getInitEstimatesDataObj(
      $ciqStore.estimates,
      estimatesDataArr,
      isDev,
      amountOfYearsForward
    )
  const {
    isValidStore: isValidEstimatesStore,
    isValidLastActual: isValidEstimateLastActual,
  } = getStoreValidity(initEstimatesDataObj, mostRecentDateKeyEstimates)
  const mostRecentActualDateKeyStr = getMostRecentActualDateKeyStr(
    $ciqStore,
    isValidEstimatesStore
  )
  const recommendedDataset = isValidEstimatesStore ? "estimates" : "financials"
  const dataset = currentDataset || recommendedDataset

  const isFinancialDataSet = dataset === "financials"
  const { data: initFinancialsDataObj, labels: financialLabels } =
    getInitFinancialsDataObj(
      $ciqStore?.financials?.a?.resData || {},
      mostRecentActualDateKeyStr,
      amountOfYearsForward,
      estimatesDataArr
    )
  const {
    isValidStore: isValidFinancialsStore,
    isValidLastActual: isValidFinancialLastActual,
  } = getStoreValidity(initFinancialsDataObj, mostRecentDateKeyFinancials)
  const allDates = getAllDates(
    $ciqStore,
    isFinancialDataSet,
    utcEpochToShortDate,
    parseUtcDateTime
  )
  const fullfilledDates = getFullFilledDates(
    allDates,
    amountOfYearsForward,
    utcEpochToShortDate
  )
  const stockValues = getStockValues(
    $ciqStore.addMultiples[tid]?.resData.priceclose
  )
  const priceOverEarningsThreeYearAvg = getPriceOverEarningsAvg(
    $ciqStore.dailyMultiples?.["div-priceclose-eps normalized"]
  )
  const isModelDisabled = !checkStoreValidity(initFinancialsDataObj)
  const isDPSAvailable = checkDPSValidity(
    initEstimatesDataObj.dividendsPerShare,
    initFinancialsDataObj.dividendsPerShare
  )

  const estimatesCurrencies = getFormattedCurrencies(
    $ciqStore,
    "estimates",
    initEstimatesDataObj
  )
  const financialsCurrencies = getFormattedCurrencies(
    $ciqStore,
    "financials",
    initFinancialsDataObj
  )
  const allDatesEstimates = getAllDatesByType(
    $ciqStore,
    "estimates",
    utcEpochToShortDate,
    parseUtcDateTime
  )

  const allDatesFinancials = getAllDatesByType(
    $ciqStore,
    "financials",
    utcEpochToShortDate,
    parseUtcDateTime
  )

  const fullfilledEstimatesDates = getFullFilledDates(
    allDatesEstimates,
    amountOfYearsForward,
    utcEpochToShortDate
  )

  const fullfilledFinancialsDates = getFullFilledDates(
    allDatesFinancials,
    amountOfYearsForward,
    utcEpochToShortDate
  )

  const { data: initialVendorDataObjEstimates, labels: vendorLabels } =
    createVendorDataObj({
      initialData: initEstimatesDataObj,
      $store,
      mostRecentActual: mostRecentDateKeyEstimates,
      amountOfYearsForward,
      fullfilledDates: fullfilledEstimatesDates,
      valuationMetricsArr: valuationDataArr,
      computedMetricsArr,
      currentIso,
    })

  const { data: initialVendorDataObjFinancials } = createVendorDataObj({
    initialData: initFinancialsDataObj,
    $store,
    mostRecentActual: mostRecentDateKeyFinancials,
    amountOfYearsForward,
    fullfilledDates: fullfilledFinancialsDates,
    valuationMetricsArr: valuationDataArr,
    computedMetricsArr,
    currentIso,
  })

  const initialDateRangeEstimates = createInitialRange(fullfilledEstimatesDates)
  const initialDateRangeFinancials = createInitialRange(
    fullfilledFinancialsDates
  )

  const estimates = {
    initialData: initEstimatesDataObj,
    mostRecentActualDateKeyStr: mostRecentDateKeyEstimates,
    isStoreValid: isValidEstimatesStore,
    isValidLastActuals: isValidEstimateLastActual,
    allDates: allDatesEstimates,
    initialDateRange: initialDateRangeEstimates,
    fullfilledDates: fullfilledEstimatesDates,
    currencies: estimatesCurrencies,
    initVendorDataObj: initialVendorDataObjEstimates,
    labels: {
      ...estimatesLabels,
      ...vendorLabels,
    },
  }

  const financials = {
    initialData: initFinancialsDataObj,
    mostRecentActualDateKeyStr: mostRecentDateKeyFinancials,
    isStoreValid: isValidFinancialsStore,
    isValidLastActuals: isValidFinancialLastActual,
    allDates: allDatesFinancials,
    initialDateRange: initialDateRangeFinancials,
    fullfilledDates: fullfilledFinancialsDates,
    currencies: financialsCurrencies,
    initVendorDataObj: initialVendorDataObjFinancials,
    labels: {
      ...financialLabels,
      ...vendorLabels,
    },
  }

  const estimateStoreHasEnoughData = checkForMandatoryData(
    initEstimatesDataObj,
    requiredMetricKeys,
    mostRecentDateKeyEstimates
  )

  const initialDataRecommended = createRecommendedInitialData({
    estimates: initEstimatesDataObj,
    financials: initFinancialsDataObj,
    prioritarySourceName: estimateStoreHasEnoughData
      ? "estimates"
      : "financials",
  })

  const recommendedLabels = estimateStoreHasEnoughData
    ? estimatesLabels
    : financialLabels

  const {
    isValidStore: isValidRecommendedStore,
    isValidLastActual: isValidRecommendedActual,
  } = getStoreValidity(initialDataRecommended, mostRecentDateKeyEstimates)

  const { data: initialVendorDataObjMixed } = createVendorDataObj({
    initialData: initialDataRecommended,
    $store,
    mostRecentActual: mostRecentDateKeyEstimates,
    amountOfYearsForward,
    fullfilledDates: fullfilledEstimatesDates,
    valuationMetricsArr: valuationDataArr,
    computedMetricsArr,
    currentIso,
  })

  const recommended = {
    ...estimates,
    initialData: initialDataRecommended,
    isStoreValid: isValidRecommendedStore,
    isValidLastActuals: isValidRecommendedActual,
    initVendorDataObj: initialVendorDataObjMixed,
    labels: {
      ...recommendedLabels,
      ...vendorLabels,
    },
  }

  return {
    datasets: {
      estimates,
      financials,
      recommended,
    },
    recommendedDataset,
    mostRecentActualDateKeyStr,
    fullfilledDates,
    stockValues,
    isModelDisabled,
    isDPSAvailable,
    priceOverEarningsThreeYearAvg,
  }
}

const getCurrentDataset = (storeObj, dataset) => {
  if (!dataset) {
    return storeObj.recommendedDataset
  }
  return dataset
}

const createInitialRange = (datesArr = []) => {
  const datesLength = datesArr.length
  const initialIdx = datesLength - 7
  const finalIdx = datesLength - 1
  return [initialIdx, finalIdx]
}

const setDefaultRange = ({ datesArr, refToUpdate }) => {
  const range = createInitialRange(datesArr)
  set(refToUpdate.value, "dateRange", range)
}

const getUserInputDataObjsInitialValue = ({
  assumptionCases,
  vendorObj,
  userInputArray,
  mostRecentActualDateKeyStr,
  amountOfYearsForward,
  isDPSAvailable,
  priceOverEarningsThreeYearAvg,
}) => {
  const arr = []

  // if assumptionCases is empty, this will still loop once
  const cases = assumptionCases.length > 0 ? assumptionCases : [{}]
  const multipliersArr = []
  cases.forEach((assumptionCase) => {
    const multipliers = assumptionCase.multipliers
    const { data, assumptionCaseMultipliers } = getUserInputDataObject(
      vendorObj,
      userInputArray,
      mostRecentActualDateKeyStr,
      amountOfYearsForward,
      multipliers,
      isDPSAvailable,
      priceOverEarningsThreeYearAvg,
      assumptionCase.multipliersObj
    )
    multipliersArr.push(assumptionCaseMultipliers)
    arr.push(data)
  })
  return { data: arr, multipliersArr }
}

const checkForInvalidEPS = ({
  valuationOutputDataObject,
  $storeDataObject,
  currentDataset,
}) => {
  const epsObj = valuationOutputDataObject?.eps || {}
  const dataset = getCurrentDataset($storeDataObject, currentDataset)
  const estimations = Object.keys(epsObj).filter(
    (key) => key > $storeDataObject?.[dataset]?.mostRecentActualDateKeyStr
  )
  return estimations.some((key) => epsObj[key]?.v < 0)
}

const createTooltipForSingleDatapoint = ({
  label,
  fieldValue,
  fieldPercentageIncOrDec,
  irrPercentageIncOrDec,
  darkActive,
  positiveDiffColor = "#34A853",
  negativeDiffColor = "red",
  positiveChar = "↑",
  negativeChar = "↓",
  chartData,
  unauth,
}) => {
  const lockSign =
    "<i aria-hidden='true' class='v-icon notranslate mdi mdi-lock theme--light' style='font-size: 16px;'></i>"
  const fieldPrefix = fieldPercentageIncOrDec >= 0 ? positiveChar : negativeChar
  const irrPrefix = irrPercentageIncOrDec >= 0 ? positiveChar : negativeChar
  const formattedValue = unauth
    ? lockSign
    : chartData?.tooltipValueFormatter(fieldValue)

  const formatFloat = (val) => chartData?.floatFormatter(Math.abs(val))
  const bgColor = darkActive ? "#484848" : "#F8F8F8"
  const borderColor = darkActive ? "#555" : "#E6E6E6"

  const getFormattedPercentageValue = (
    unauth,
    fieldPercentageIncOrDec,
    fieldPrefix
  ) => {
    if (unauth) {
      return ""
    }
    const fieldDiffColor =
      fieldPercentageIncOrDec >= 0 ? positiveDiffColor : negativeDiffColor
    const formattedPercentage = formatFloat(fieldPercentageIncOrDec)
    return `<span style="color: ${fieldDiffColor}">${fieldPrefix} ${formattedPercentage}%</span>`
  }

  const formattedPercentageValue = getFormattedPercentageValue(
    unauth,
    fieldPercentageIncOrDec,
    fieldPrefix
  )

  const getFormattedIrr = (unauth, irrPercentageIncOrDec, irrPrefix) => {
    const irrDiffColor =
      irrPercentageIncOrDec >= 0 ? positiveDiffColor : negativeDiffColor
    const formattedIrr = formatFloat(irrPercentageIncOrDec * 100)
    if (unauth) {
      return lockSign
    }
    return `<span style="color: ${irrDiffColor}">
          ${irrPrefix} ${formattedIrr}%
        </span>`
  }

  const formattedIrrPercentage = getFormattedIrr(
    unauth,
    irrPercentageIncOrDec,
    irrPrefix
  )

  return `
    <div class="d-flex" style="gap: 8px">
      <span style="font-weight:bold; width: 34px; height: 34px; line-height:34px; border-radius: 4px; border: 1px solid ${borderColor}; background: ${bgColor}; text-align:center;">${label}</span>
      <div style="align-self:center;">
        Forecasted Return
        <br />
        ${formattedValue}
        ${formattedPercentageValue}
      </div>
      <div style="align-self:center" class="text-center">
        IRR
        <br />
        ${formattedIrrPercentage}
      </div>
    </div>
  `
}

const createSimpleTooltip = (title, content) => {
  return `
    <div class="text-center">
      <span class="pb-1 d-block">${title}</span>
      <strong>${content}</strong>
    </div>
  `
}

const checkDataPointsForColumn = (idx, data) => {
  return data.every((item) => {
    return Boolean(item.data[idx].v) === false || !isFinite(item.data[idx].v)
  })
}

const createHistoricalYearsAcc = ({
  historicalYearObjConfig,
  historicalTableData,
}) => {
  const validItems = historicalYearObjConfig.reduce((acc, item, idx) => {
    if (item.hideIfNotAvailable) {
      const isEveryDataPointInvalid = checkDataPointsForColumn(
        idx,
        historicalTableData
      )
      if (isEveryDataPointInvalid) {
        return acc
      }
    }
    acc.push({
      title: item.label,
      idx,
    })
    return acc
  }, [])

  // If all items are valid, return all items except the ones that are fallbacks
  if (validItems.length === historicalYearObjConfig.length) {
    return validItems.filter((_, idx) => {
      const obj = historicalYearObjConfig[idx]
      return !obj.useAsFallback
    })
  }

  return validItems
}

const createHistoricalTableFilteredData = ({
  historicalYears,
  historicalTableData,
}) => {
  const includedIndexes = historicalYears.map((item) => item.idx)
  return historicalTableData.reduce((acc, item) => {
    const dataObj = item.data.filter((_, i) => {
      return includedIndexes.includes(i)
    })

    acc.push({
      ...item,
      data: dataObj,
    })
    return acc
  }, [])
}

const calculatePercentageIncreaseOrDecrease = (from, to) => {
  return ((to - from) / from) * 100
}

const createChartOptions = ({ chartData, darkActive }) => {
  // create a ceiling checking both the rangeArr
  // and the seriesArr for the bigger value
  const ceiling = Math.max(
    Math.max(...chartData.rangeArr.map((item) => item[2])),
    Math.max(...chartData.seriesArr.map((item) => item[1]))
  )
  const firstAheadDataPointTimestamp = chartData.seriesArr[3][0]
  return {
    chart: {
      height: 256,
      style: {
        fontFamily: `"Roboto", sans-serif`,
      },
      zooming: {
        mouseWheel: false,
      },
    },
    tooltip: {
      borderWidth: 0,
      useHTML: true,
      shared: false,
      headerFormat: "",
      backgroundColor: darkActive ? "#383838" : "#FFF",
      style: {
        color: darkActive ? "#fff" : "#303030",
      },
    },
    title: {
      text: "",
    },
    stockTools: {
      gui: {
        enabled: false,
      },
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      linkedTo: 0,
      opposite: false,
      startOnTick: true,
      tickPositions: chartData.tickPositions,
      tickInterval: 365 * 24 * 3600 * 1000, // One year in milliseconds
      plotBands: [
        {
          from: chartData.plotBandsData.from,
          to: chartData.plotBandsData.to,
          color: darkActive
            ? "rgba(109, 83, 53, 0.15)"
            : "rgba(255, 208, 153, 0.15)",
          label: {
            text: "",
          },
        },
      ],
      lineColor: darkActive ? "#383838" : "#F1F1F1",
      categories: [],
      labels: {
        formatter: function () {
          return dayjs.utc(this.value).format("MMM'YY").replace("'", "'<br />")
        },
        autoRotation: false,
        style: {
          fontSize: "13px",
          fontWeight: "500",
          color: darkActive ? "#fff" : "#474747",
        },
      },
    },
    series: [
      {
        type: "arearange",
        showInLegend: false,
        linkedTo: null,
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#FF9C2B40"],
            [1, "#DA740016"],
          ],
        },
        data: chartData.rangeArr,
        marker: {
          symbol: "circle",
          fillColor: "transparent",
          states: {
            hover: {
              fillColor: "#FF9000",
            },
          },
        },
        tooltip: {
          useHTML: true,
          pointFormatter: function () {
            const index = this?.index
            const date = chartData.tickPositions[index]
            const formatted = chartData.dateFormatter(date)
            const dateString = `${formatted}E`
            const currentLow = chartData.rangeArr[index][1]
            const currentHigh = chartData.rangeArr[index][2]
            const latestStockPrice = chartData.stockPriceDataPoint[0][1]
            const irrLow = chartData.extraData?.[date]?.irrLow
            const irrHigh = chartData.extraData?.[date]?.irrHigh
            const unauth = chartData.extraData?.[date]?.unauth
            const highDiff = calculatePercentageIncreaseOrDecrease(
              latestStockPrice,
              currentHigh
            )
            const lowDiff = calculatePercentageIncreaseOrDecrease(
              latestStockPrice,
              currentLow
            )
            const highHtml = createTooltipForSingleDatapoint({
              label: "High",
              fieldValue: currentHigh,
              fieldPercentageIncOrDec: highDiff,
              irrPercentageIncOrDec: irrHigh,
              darkActive,
              chartData,
              unauth,
            })
            const lowHtml = createTooltipForSingleDatapoint({
              label: "Low",
              fieldValue: currentLow,
              fieldPercentageIncOrDec: lowDiff,
              irrPercentageIncOrDec: irrLow,
              darkActive,
              chartData,
              unauth,
            })
            return `
                <div>
                  <h3 style="padding-bottom:8px;">${dateString}</h3>
                  ${highHtml}
                  <div style="padding-top:10px">
                    ${lowHtml}
                  </div>
                </div>
                `
          },
        },
      },
      {
        xAxis: 0,
        type: "line",
        linkedTo: 0,
        showInLegend: false,
        data: chartData.seriesArr,
        pointStart: chartData.pointStart,
        zoneAxis: "x",
        zones: [
          {
            value: firstAheadDataPointTimestamp,
            color: "#bcb2b2",
          },
          {
            color: "#FF9000",
          },
        ],
        marker: {
          symbol: "circle",
        },
        tooltip: {
          useHTML: true,
          pointFormatter: function () {
            const value = this?.y
            const index = this.index
            const formattedValue = chartData.tooltipValueFormatter(value)
            const year = dayjs.utc(chartData.tickPositions[index]).year()
            const isStockPriceIdx = index === chartData.currentStockPriceIndex
            const isStockPriceAheadIdx =
              chartData.isAheadOfFirstForecast &&
              index === chartData.currentStockPriceIndex + 1
            if (index <= 1) {
              return createSimpleTooltip(year, formattedValue)
            }
            if (isStockPriceIdx) {
              const label = chartData.isAheadOfFirstForecast
                ? year
                : "Current Stock Price"
              return createSimpleTooltip(label, formattedValue)
            }
            if (isStockPriceAheadIdx) {
              return createSimpleTooltip("Current Stock Price", formattedValue)
            }

            const latestStockPrice = chartData.stockPriceDataPoint[0][1]
            const currentPriceObj = chartData.seriesArr[index]
            const currentPrice = currentPriceObj[1]
            const pctDiff = calculatePercentageIncreaseOrDecrease(
              latestStockPrice,
              currentPrice
            )
            const date = chartData.tickPositions[index - 1]
            const formattedDate = chartData.dateFormatter(date)
            const irrMid = chartData.extraData?.[date]?.irrMid
            const title = `${formattedDate}E`
            const unauth = chartData.extraData?.[date]?.unauth
            const tooltipData = createTooltipForSingleDatapoint({
              label: "Mid",
              fieldValue: value,
              fieldPercentageIncOrDec: pctDiff,
              irrPercentageIncOrDec: irrMid,
              darkActive,
              chartData,
              unauth,
            })
            return `
              <div>
                <h3 style="padding-bottom:8px;">${title}</h3>
                ${tooltipData}
              </div>
              `
          },
        },
      },
      {
        name: "Current Stock Price",
        showInLegend: false,
        data: chartData.stockPriceDataPoint,
        tooltip: {
          useHTML: true,
          pointFormatter: function () {
            const formattedValue = chartData.tooltipValueFormatter(this.y)
            return createSimpleTooltip("Current Price", formattedValue)
          },
        },
        marker: {
          symbol: "circle",
          fillColor: darkActive ? "white" : "black",
        },
      },
    ],
    yAxis: {
      // use the last value in the range array to determine the
      // ceiling of the y-axis
      ceiling,
      gridLineColor: darkActive ? "#383838" : "#F1F1F1",
      gridLineWidth: 1,
      labels: {
        formatter: function () {
          return chartData.yAxisLabelFormatter(this.value)
        },
        style: {
          fontSize: "12px",
          fontWeight: darkActive ? "normal" : "bold",
          color: darkActive ? "#fff" : "#474747",
        },
      },
      title: {
        text: null,
      },
    },
    plotOptions: {
      arearange: {
        lineColor: "transparent",
        stickyTracking: false,
      },
      line: {
        lineColor: "#FF9000",
        stickyTracking: false,
      },
    },
  }
}

const checkForRequiredKeys = (requiredKeys, initialDataSource) => {
  const hasEveryMetric = requiredKeys.every((key) => {
    return hasProp(initialDataSource, key)
  })
  return hasEveryMetric
}

const checkForRequiredDateKeys = (
  requiredKeys,
  initialDataSource,
  lastActualDateKeyStr
) => {
  // check if last actual and first forecast dateKey exists
  const [year] = lastActualDateKeyStr.split("##").map(Number)
  const firstEstimateYear = year + 1
  const keysToLookup = [lastActualDateKeyStr, `${firstEstimateYear}##FY`]
  const isEveryRequiredMetricAvailable = requiredKeys.every((key) => {
    const dataObject = initialDataSource[key]
    return (
      hasProp(dataObject, keysToLookup[0]) &&
      hasProp(dataObject, keysToLookup[1])
    )
  })
  return isEveryRequiredMetricAvailable
}

const createRecommendedInitialData = ({
  estimates,
  financials,
  prioritarySourceName,
}) => {
  const isPrioritaryEstimates = prioritarySourceName === "estimates"
  const prioritarySource = isPrioritaryEstimates ? estimates : financials
  return Object.keys(prioritarySource).reduce((acc, key) => {
    const primarySourceMetric = prioritarySource[key]

    const primaryKeys = Object.keys(primarySourceMetric)
    const mixedData = primaryKeys.reduce((acc2, key) => {
      const primarySourceData = primarySourceMetric[key]

      acc2[key] = {
        ...primarySourceData,
        sourceName: prioritarySourceName,
      }
      return acc2
    }, {})
    acc[key] = mixedData
    return acc
  }, {})
}

const createForecastTable = (
  assumptionCaseTemplatesArr,
  assumptionsOutputArr,
  filteringFn = () => true,
  storeDataObject,
  startYear,
  endYear,
  formatters,
  rowsTemplate = forecastRowsTemplate
) => {
  const filteredTemplates = assumptionCaseTemplatesArr.filter(filteringFn)
  const filteredOutput = assumptionsOutputArr.filter(filteringFn)

  const tableData = rowsTemplate.reduce((acc, item) => {
    const data = getForecastMetricData(
      filteredTemplates,
      filteredOutput,
      item,
      storeDataObject?.mostRecentActualDateKeyStr,
      formatters?.utcEpochToShortDate,
      formatters?.formatNumber,
      formatters?.formatPercent,
      formatters?.formatCurrency,
      storeDataObject?.fullfilledDates,
      startYear,
      endYear
    )
    acc.push({
      label: item.label,
      key: item.key,
      format: item?.format || "oneDigitPct",
      highlight: Boolean(item.highlight),
      data,
    })
    return acc
  }, [])

  const dateRange = {
    start: startYear,
    end: endYear,
  }

  return {
    dateRange,
    tableData,
  }
}

const getPrimitiveValues = (obj) => {
  if (!obj) {
    return {}
  }
  return Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] !== "object") {
      acc[key] = obj[key]
    }
    return acc
  }, {})
}

const getHistoricalDataObjKeys = ({
  label,
  historicalData,
  yearsToMatch,
  keyLookup,
}) => {
  return historicalData
    ?.find((item) => item.label === label)
    ?.data?.filter((item) => {
      const existingKey = keyLookup.find(
        (key) => item?.dependencies?.formulaStrOrObj?.[key]
      )
      return yearsToMatch.includes(
        item?.dependencies?.formulaStrOrObj?.[existingKey]
      )
    })
}

const saveMetrics = ({ outputObj, mostRecentActualDateKeyStr }) => {
  const metricsToSave = Object.keys(outputObj)
  const metrics = metricsToSave.reduce((acc, metricKey) => {
    const filteredKeys = Object.keys(outputObj[metricKey]).filter(
      (key) => key >= mostRecentActualDateKeyStr
    )
    const filteredObjs = filteredKeys.reduce((acc2, dateKey) => {
      acc2[dateKey] = outputObj[metricKey][dateKey]
      return acc2
    }, {})
    acc[metricKey] = filteredObjs
    return acc
  }, {})
  return metrics
}

const getHubData = ({
  allDates,
  assumptionsOutputArray,
  historicalTable,
  latestStockPriceObj,
  mostRecentActualDateKeyStr,
  amountOfYearsFwd,
  userInputs,
  fullfilledDates,
  initVendorDataObj,
  currencies,
}) => {
  if (!allDates.length) {
    return {}
  }
  const revenueCagrLabel = "Revenue Growth (CAGR)"
  const epsCagrLabel = "EPS Growth (CAGR)"
  const peChangeLabel = "P/E Change"
  const lastForecastYearObj = allDates?.[allDates?.length - 2]
  const lastForecastYear = lastForecastYearObj?.dateKey
  // mid assumption case as default
  const assumptionData = assumptionsOutputArray[1]
  const targetPrice =
    assumptionData.totalStockPriceIncludingDividends[lastForecastYear]
  const irr = assumptionData.annualizedReturn[lastForecastYear]
  const historicalData = historicalTable
  const historicalRevenueCagr = getHistoricalDataObjKeys({
    label: revenueCagrLabel,
    historicalData,
    yearsToMatch: historicalYearsToMatch,
    keyLookup: ["relativeDateKey", "startRelativePeriod"],
  })
  const metrics = saveMetrics({
    outputObj: initVendorDataObj,
    mostRecentActualDateKeyStr,
  })

  const historicalEpsCagr = getHistoricalDataObjKeys({
    label: epsCagrLabel,
    historicalData,
    yearsToMatch: historicalYearsToMatch,
    keyLookup: ["relativeDateKey", "startRelativePeriod"],
  })
  const historicalPeChange = getHistoricalDataObjKeys({
    label: peChangeLabel,
    historicalData,
    yearsToMatch: historicalYearsToMatch,
    keyLookup: ["relativeDateKey"],
  })

  // making sure we are only saving from current recentDateKeyStr and ahead
  const filteredFullfilledDates = fullfilledDates.filter(
    (item) => item.dateKey >= mostRecentActualDateKeyStr
  )

  return {
    lastStockPrice: getPrimitiveValues(latestStockPriceObj),
    irr: getPrimitiveValues(irr),
    targetPrice: getPrimitiveValues(targetPrice),
    realizedDate: getPrimitiveValues(lastForecastYearObj),
    historicalRevenueCagr,
    historicalEpsCagr,
    historicalPeChange,
    valuationOutput: metrics,
    amountOfYearsFwd,
    userInputs,
    mostRecentActualDateKeyStr,
    fullfilledDates: filteredFullfilledDates,
    currencies,
  }
}

const getUnauthPropByTier = (relativeKey, currentTier, restrictions) => {
  if (currentTier.endsWith("-free")) {
    return restrictions.free.historical.includes(relativeKey)
  }
  return false
}

export {
  createForecastTable,
  checkForInvalidEPS,
  createChartData,
  createChartOptions,
  createCurrencyFormatter,
  createHistoricalTable,
  createHistoricalTableFilteredData,
  createHistoricalYearsAcc,
  createInitialRange,
  createNumberFormatter,
  createPercentFormatter,
  createStoreDataObject,
  createVendorDataObj,
  getHubData,
  getInitialDateStartingPoint,
  getInitEstimatesDataObj,
  getInitFinancialsDataObj,
  getStockValues,
  getUserInputDataObjsInitialValue,
  getWarningMessageByCompanyDescription,
  setDefaultRange,
}
