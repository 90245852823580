import { cellCalculations } from "~/utils/valuationTransform/index"

const initVendorValuationDataArr = [
  {
    storeMetricKey: "priceclose",
    metricKey: "stockPrice",
    label: "Stock Price",
  },
  {
    storeMetricKey: "eps normalized",
    metricKey: "valuationEpsNormalized",
    label: "NTM Earnings Per Share",
    storeForecastFallback: "eps",
    cellCalc: cellCalculations.valuationEpsNormalized,
  },
]

const requiredMetrics = ["revenue", "ebt", "ebit", "netIncome", "eps"]

const userInputDataArr = [
  {
    metricKey: "revenueChangeYoY",
    unittypeid: 4,
    multiplyBy: 100,
  },
  {
    metricKey: "ebitMargin",
    unittypeid: 4,
    multiplyBy: 100,
  },
  {
    metricKey: "dsoChangeYoY",
    unittypeid: 4,
    multiplyBy: 100,
  },
  {
    metricKey: "dividendsPerSharePayoutRatio",
    unittypeid: 4,
    multiplyBy: 100,
    allowNegative: false,
  },
  {
    metricKey: "priceOverEarningsMultiple",
    defaultValue: 10,
  },
  {
    metricKey: "interestOther",
    unittypeid: 3,
  },
  {
    metricKey: "taxesPercentOfEBT",
    unittypeid: 4,
    multiplyBy: 100,
  },
]

const initVendorEstimatesDataArr = [
  {
    storeMetricKey: "revenue act.",
    financialFallbackKey: "total revenues 28",
    label: "Revenues",
    metricKey: "revenue",
    cellCalc: cellCalculations.revenue,
  },
  {
    storeMetricKey: "ebit act.",
    financialFallbackKey: "operating income 21",
    label: "EBIT",
    fallbackLabel: "Operating Income",
    metricKey: "ebit",
    cellCalc: cellCalculations.ebit,
  },
  {
    storeMetricKey: [
      { key: "ebt normalized act.", label: "EBT", isNormalized: true },
      { key: "ebt (gaap) actual act.", label: "EBT (GAAP)", isGaap: true },
    ],
    financialFallbackKey: "ebt excl. unusual items 4",
    label: "EBT",
    fallbackLabel: "EBT Excl. Unusual Items",
    metricKey: "ebt",
    cellCalc: cellCalculations.ebt,
  },
  {
    storeMetricKey: [
      {
        key: "net income normalized act.",
        label: "Net Income Normalized",
        isNormalized: true,
      },
      {
        key: "net income (gaap) act.",
        label: "Net Income (GAAP)",
        isGaap: true,
      },
    ],
    financialFallbackKey: "net income to common excl. extra items 379",
    label: "Net Income Normalized",
    fallbackLabel: "Net Income to Common Excl. Extra Items",
    metricKey: "netIncome",
    cellCalc: cellCalculations.netIncome,
  },
  {
    storeMetricKey: [
      {
        key: "eps normalized act.",
        label: "EPS Normalized",
        isNormalized: true,
      },
      { key: "eps (gaap) act.", label: "EPS (GAAP)", isGaap: true },
    ],
    financialFallbackKey: "diluted eps excl extra items 142",
    label: "EPS Normalized",
    fallbackLabel: "Diluted EPS Excl Extra",
    metricKey: "eps",
    cellCalc: cellCalculations.eps,
  },
  {
    storeMetricKey: "dividend per share act.",
    financialFallbackKey: "dividends per share 3058",
    label: "Dividends Per Share",
    metricKey: "dividendsPerShare",
    cellCalc: cellCalculations.dividendsPerShare,
  },
]

const changeYoYLabel = "% Change YoY"
const marginLabel = "% Margin"
const incrementalMarginLabel = "% Incremental Margin"

// newCalculationMetricKey must be unique for the dataVendorObject
const calculatedValuesToAddFromVendorData = [
  {
    metricKey: "revenue",
    newCalculatedMetricKey: "revenueChangeYoY",
    calculation: "yoy",
    label: changeYoYLabel,
  }, // yoy = dxdt
  {
    metricKey: "ebit",
    newCalculatedMetricKey: "ebitChangeYoY",
    calculation: "yoy",
    label: changeYoYLabel,
    cellCalc: cellCalculations.ebitChangeYoY,
  },
  {
    metricKey: "ebit",
    newCalculatedMetricKey: "ebitMargin",
    calculation: "DIV revenue",
    label: marginLabel,
  },
  {
    metricKey: "ebit",
    newCalculatedMetricKey: "ebitIncrementalMargin",
    calculation: "incrementalMargin revenue",
    label: incrementalMarginLabel,
    cellCalc: cellCalculations.ebitIncrementalMargin,
  },
  {
    metricKey: "netIncome",
    newCalculatedMetricKey: "netIncomeChangeYoY",
    calculation: "yoy",
    label: changeYoYLabel,
    cellCalc: cellCalculations.netIncomeChangeYoY,
  },
  {
    metricKey: "netIncome",
    newCalculatedMetricKey: "netIncomeMargin",
    calculation: "DIV revenue",
    label: marginLabel,
    cellCalc: cellCalculations.netIncomeMargin,
  },
  {
    metricKey: "eps",
    newCalculatedMetricKey: "epsChangeYoY",
    calculation: "yoy",
    label: changeYoYLabel,
    cellCalc: cellCalculations.epsChangeYoY,
  },
  {
    newCalculatedMetricKey: "interestOther",
    cascade: ["netIncome", "netIncomeChangeYoY"],
    calculation: "ebt MINUS ebit",
    label: "Interest / Other",
  },
  {
    newCalculatedMetricKey: "taxesOther",
    calculation: "netIncome MINUS ebt",
    cascade: ["netIncome", "netIncomeChangeYoY"],
    label: "Taxes / Other",
    cellCalc: cellCalculations.taxesOther,
  },
  {
    newCalculatedMetricKey: "taxesPercentOfEBT",
    calculation: "taxesOther DIV ebt",
    label: "% of EBT",
  },
  {
    newCalculatedMetricKey: "dso",
    calculation: "netIncome DIV eps",
    cascade: [
      "eps",
      "dsoChangeYoY",
      "epsChangeYoY",
      "dividendsPerShare",
      "dividendsPerShareChangeYoY",
    ],
    label: "Diluted Shares Outstanding",
    cellCalc: cellCalculations.dso,
  },
  {
    metricKey: "dso",
    newCalculatedMetricKey: "dsoChangeYoY",
    calculation: "yoy",
    label: changeYoYLabel,
  },
  {
    metricKey: "dividendsPerShare",
    newCalculatedMetricKey: "dividendsPerShareChangeYoY",
    calculation: "yoy",
    label: changeYoYLabel,
    cellCalc: cellCalculations.dividendsPerShareChangeYoY,
  },
  {
    metricKey: "dividendsPerShare",
    newCalculatedMetricKey: "dividendsPerSharePayoutRatio",
    calculation: "DIV eps",
    label: "% Payout Ratio",
  },
  {
    newCalculatedMetricKey: "priceOverEarningsMultiple",
    calculation: "stockPrice DIV valuationEpsNormalized",
    label: "NTM P/E Multiple",
  },
  {
    newCalculatedMetricKey: "impliedStockPrice",
    calculation: "priceOverEarningsMultiple TIMES eps",
    cascade: ["cumulativeDps"],
    label: "Implied Stock Price",
    cellCalc: cellCalculations.impliedStockPrice,
  },
  {
    newCalculatedMetricKey: "cumulativeDividendsPerShare",
    calculation: "cumulativeDps",
    label: "Cumulative Dividends/Share",
    cellCalc: cellCalculations.cumulativeDividendsPerShare,
  },
  {
    newCalculatedMetricKey: "totalStockPriceIncludingDividends",
    calculation: "impliedStockPrice PLUS cumulativeDividendsPerShare",
    label: "Total Stock Price + Dividends",
    cellCalc: cellCalculations.totalStockPriceIncludingDividends,
  },
  {
    newCalculatedMetricKey: "upsideOrDownsideToCurrentStockPrice",
    calculation: "totalStockPriceIncludingDividends DIVMINUSONE stockPrice",
    label: "Total Return",
    cellCalc: cellCalculations.upsideOrDownsideToCurrentStockPrice,
  },
  {
    newCalculatedMetricKey: "annualizedReturn",
    calculation: "annualizedReturn",
    label: "Total IRR (Annualized Return)",
    cellCalc: cellCalculations.annualizedReturn,
  },
]

const operatingDataDisplayedRowArr = [
  {
    key: "revenue",
    format: "currency",
    bold: true,
  },
  {
    key: "revenueChangeYoY",
    spaced: true,
    format: "pct",
    inputSuffix: "%",
  },
  { key: "blank" },
  {
    key: "ebit",
    format: "currency",
    bold: true,
  },
  { key: "ebitChangeYoY", spaced: true, format: "pct" },
  {
    key: "ebitMargin",
    spaced: true,
    format: "pct",
    inputSuffix: "%",
  },
  {
    key: "ebitIncrementalMargin",
    spaced: true,
    format: "pct",
  },
  { key: "blank" },
  {
    key: "interestOther",
    format: "currency",
    bold: true,
  },
  {
    key: "ebt",
    format: "currency",
  },
  {
    key: "taxesOther",
    format: "currency",
    bold: true,
  },
  {
    key: "taxesPercentOfEBT",
    spaced: true,
    format: "pct",
    inputSuffix: "%",
  },
  { key: "blank" },
  {
    key: "netIncome",
    format: "currency",
    bold: true,
  },
  {
    key: "netIncomeChangeYoY",
    spaced: true,
    format: "pct",
  },
  {
    key: "netIncomeMargin",
    spaced: true,
    format: "pct",
    inputSuffix: "%",
  },
  {
    key: "dso",
    format: "float",
    bold: true,
  },
  {
    key: "dsoChangeYoY",
    spaced: true,
    format: "pct",
    inputSuffix: "%",
  },
  {
    key: "eps",
    format: "currency",
    bold: true,
    highlight: true,
  },
  {
    key: "epsChangeYoY",
    spaced: true,
    format: "pct",
    bold: true,
    highlight: true,
  },
  { key: "blank" },
  {
    key: "dividendsPerShare",
    format: "currency",
    bold: true,
  },
  {
    key: "dividendsPerShareChangeYoY",
    spaced: true,
    format: "pct",
  },
  {
    key: "dividendsPerSharePayoutRatio",
    spaced: true,
    format: "pct",
    inputSuffix: "%",
  },
]

const summarydisplayedRowArr = [
  {
    key: "stockPrice",
    format: "currency",
    bold: true,
  },
  {
    key: "priceOverEarningsMultiple",
    format: "turns",
    bold: true,
    inputSuffix: "x",
  },
  {
    key: "valuationEpsNormalized",
    format: "currency",
    hideActuals: true,
  },
  {
    key: "impliedStockPrice",
    format: "currency",
    bold: true,
    hideActuals: true,
  },
  {
    key: "cumulativeDividendsPerShare",
    format: "currency",
  },
  {
    key: "totalStockPriceIncludingDividends",
    format: "currency",
    bold: true,
    highlight: true,
    hideActuals: true,
  },
  {
    key: "upsideOrDownsideToCurrentStockPrice",
    format: "pct",
    bold: true,
    highlight: true,
    hideActuals: true,
  },
  {
    key: "annualizedReturn",
    format: "pct",
    bold: true,
    highlight: true,
    hideActuals: true,
  },
]

const initialFilterState = {
  // First currency as default
  currency: 0,
  assumptionCase: 0,
  // Millions as default
  unit: 1,
  // One decimal as default
  decimals: 1,
  // Reverse disabled as default
  reverse: false,
  // Empty as default, will be updated after the first load
  dateRange: [],
}

const createInitialFilterState = (initialFilterState, assumptionCases) => {
  const initialFilterStateCopy = { ...initialFilterState }
  const hasAssumptionCases = assumptionCases && assumptionCases.length > 0
  if (hasAssumptionCases) {
    const indexOfDefaultState = assumptionCases.findIndex(
      (item) => item.isDefault
    )
    initialFilterStateCopy.assumptionCase = indexOfDefaultState
  }
  return initialFilterStateCopy
}

const mapUserObjToAssumption = (userInputArr, assumptionValue = 1) => {
  return userInputArr.reduce((acc, item) => {
    const key = item.metricKey
    acc[key] = assumptionValue
    return acc
  }, {})
}

const assumptionCases = [
  {
    id: "low",
    name: "Low",
    description: "Low Case",
    multiplierFn: (userInputArr) => {
      return userInputArr.reduce((acc, item) => {
        const key = item.metricKey
        if (key === "ebitMargin") {
          acc[key] = 0.95
        } else if (key === "interestOther") {
          acc[key] = 1
        } else if (key === "taxesPercentOfEBT") {
          acc[key] = 1.05
        } else if (key === "dsoChangeYoY") {
          acc[key] = (fieldValue) => {
            if (fieldValue < 0) {
              return 0.9
            }
            return 1.1
          }
        } else {
          acc[key] = 0.9
        }
        return acc
      }, {})
    },
  },
  {
    id: "mid",
    name: "Mid",
    isDefault: true,
    description: "Mid Case",
    multiplierFn: (userInputArr) => {
      return mapUserObjToAssumption(userInputArr)
    },
  },
  {
    id: "high",
    name: "High",
    description: "High Case",
    multiplierFn: (userInputArr) => {
      return userInputArr.reduce((acc, item) => {
        const key = item.metricKey
        if (key === "ebitMargin") {
          acc[key] = 1.05
        } else if (["interestOther", "taxesPercentOfEBT"].includes(key)) {
          acc[key] = 1
        } else if (key === "dsoChangeYoY") {
          acc[key] = (fieldValue) => {
            if (fieldValue < 0) {
              return 1.1
            }
            return 0.9
          }
        } else if (key === "dividendsPerSharePayoutRatio") {
          acc[key] = 1.05
        } else {
          acc[key] = 1.1
        }
        return acc
      }, {})
    },
  },
]

const defaultAssumptionCase = [
  {
    id: "default",
    name: "Mid",
    isDefault: true,
    description: "Mid",
    multiplierFn: (userInputArr) => {
      return mapUserObjToAssumption(userInputArr)
    },
  },
]

const threeYearCagr = {
  type: "cagr",
  startRelativePeriod: -3,
  endRelativePeriod: 0,
}

const fiveYearCagr = {
  ...threeYearCagr,
  startRelativePeriod: -5,
}
const tenYearCagr = {
  ...threeYearCagr,
  startRelativePeriod: -10,
}

const yoyOneYear = {
  type: "yoy",
  relativeDateKey: -1,
}

const divisionByValuationEpsNormalized = {
  type: "YoYdiv valuationEpsNormalized",
  relativeDateKey: -1,
}

const divisionByValuationEpsNormalizedThreeYearsAgo = {
  ...divisionByValuationEpsNormalized,
  relativeDateKey: -3,
}

const divisionByValuationEpsNormalizedFiveYearsAgo = {
  ...divisionByValuationEpsNormalized,
  relativeDateKey: -5,
}

const divisionByValuationEpsNormalizedTenYearsAgo = {
  ...divisionByValuationEpsNormalized,
  relativeDateKey: -10,
}

const historicalTableFormulas = [
  yoyOneYear,
  threeYearCagr,
  fiveYearCagr,
  tenYearCagr,
]
const priceOverEarningsFormulas = [
  divisionByValuationEpsNormalized,
  divisionByValuationEpsNormalizedThreeYearsAgo,
  divisionByValuationEpsNormalizedFiveYearsAgo,
  divisionByValuationEpsNormalizedTenYearsAgo,
]

const stockPriceFormulas = [
  {
    type: "latestForwardMinusOne",
    relativeDateKey: -1,
  },
  {
    type: "latestForwardMinusOne",
    relativeDateKey: -3,
  },
  {
    type: "latestForwardMinusOne",
    relativeDateKey: -5,
  },
  {
    type: "latestForwardMinusOne",
    relativeDateKey: -10,
  },
]

const irrHistoricalFormulas = [
  {
    type: "irr",
    relativeDateKey: -1,
  },
  {
    type: "irr",
    relativeDateKey: -3,
  },
  {
    type: "irr",
    relativeDateKey: -5,
  },
  {
    type: "irr",
    relativeDateKey: -10,
  },
]

const totalReturnHistoricalFormulas = [
  {
    type: "annualizedReturn",
    relativeDateKey: -1,
  },
  {
    type: "annualizedReturn",
    relativeDateKey: -3,
  },
  {
    type: "annualizedReturn",
    relativeDateKey: -5,
  },
  {
    type: "annualizedReturn",
    relativeDateKey: -10,
  },
]

const divisionByRevenueOneYearAgo = {
  type: "div revenue",
  relativeDateKey: -1,
}

const divisionByRevenueThreeYearsAgo = {
  ...divisionByRevenueOneYearAgo,
  relativeDateKey: -3,
}

const divisionByRevenueFiveYearsAgo = {
  ...divisionByRevenueOneYearAgo,
  relativeDateKey: -5,
}

const divisionByRevenueTenYearsAgo = {
  ...divisionByRevenueFiveYearsAgo,
  relativeDateKey: -10,
}

const netIncomeFormulas = [
  divisionByRevenueOneYearAgo,
  divisionByRevenueThreeYearsAgo,
  divisionByRevenueFiveYearsAgo,
  divisionByRevenueTenYearsAgo,
]

const revenueGrowthTemplate = {
  label: "Revenue Growth (CAGR)",
  key: "revenue",
}

const netIncomeTemplate = {
  label: "Net Income Margin",
  key: "netIncome",
}

const epsGrowthTemplate = {
  label: "EPS Growth (CAGR)",
  key: "eps",
}

const priceOverEarningsTemplate = {
  label: "P/E Change",
  key: "stockPrice",
}

const stockPriceTemplate = {
  label: "Stock Price",
  key: "stockPrice",
}

const irrTemplate = {
  label: "IRR (Annual Return)",
  key: "stockPrice",
}

const totalReturnTemplate = {
  label: "Total Return",
  key: "upsideOrDownsideToCurrentStockPrice",
}

const formatSummaryValue = (value, numFormatter, formatType) => {
  const initialValue = value < 0 ? Math.abs(value) : value
  const formattedValue = numFormatter(initialValue, formatType)
  return togglePositiveNegativeValue(value, formattedValue)
}

const historicalLabelFunctionsByTitle = {
  "Revenue Growth (CAGR)": ({ from, fromDate, to, toDate, numFormatter }) => {
    const fromValue = formatSummaryValue(from, numFormatter)
    const toValue = formatSummaryValue(to, numFormatter)
    return `Revenues went from ${fromValue} on ${fromDate} to ${toValue} on ${toDate}`
  },
  "Net Income Margin": ({
    from,
    fromDate,
    value,
    numFormatter,
    index,
    currentYearValue,
    currentYearDate,
  }) => {
    const pct = value ?? from
    const finalValue = formatSummaryValue(pct, numFormatter, "pct")
    const isOneYearDiff = index === 0
    const formatterCurrentYearValue = formatSummaryValue(
      currentYearValue,
      numFormatter,
      "pct"
    )
    if (isOneYearDiff) {
      return `Net Income Margins were ${finalValue} on ${fromDate} versus ${formatterCurrentYearValue} on ${currentYearDate}`
    }
    return `Net Income Margins were ${finalValue} on ${fromDate}`
  },
  "EPS Growth (CAGR)": ({ from, fromDate, to, toDate, numFormatter }) => {
    const finalFrom = formatSummaryValue(from, numFormatter, "currency")
    const finalTo = formatSummaryValue(to, numFormatter, "currency")
    return `EPS went from ${finalFrom} on ${fromDate} to ${finalTo} on ${toDate}`
  },
  "P/E Change": ({ from, fromDate, to, toDate, numFormatter }) => {
    const fromValue = formatSummaryValue(from, numFormatter)
    const toValue = formatSummaryValue(to, numFormatter)
    return `P/E multiple went from ${fromValue}x on ${fromDate} to ${toValue}x on ${toDate}`
  },
  "Stock Price": ({ toDate }) => {
    return `The stock price as of ${toDate}`
  },
  "Total Return": ({ fromDate, toDate, value, numFormatter }) => {
    const finalValue = formatSummaryValue(value, numFormatter, "pct")
    return `The stock price return was ${finalValue} from ${fromDate} to ${toDate}`
  },
  "IRR (Annual Return)": ({ value, fromDate, toDate, numFormatter }) => {
    const finalValue = formatSummaryValue(value, numFormatter, "pct")
    return `The Annualized Return was ${finalValue} from ${fromDate} to ${toDate}`
  },
}

const forecastLabelFunctionsByTitle = {
  "Revenue Growth (CAGR)": ({ from, fromDate, to, toDate, numFormatter }) => {
    const fromValue = formatSummaryValue(from, numFormatter)
    const toValue = formatSummaryValue(to, numFormatter)
    return `Revenues are forecasted to grow from ${fromValue} on ${fromDate} to ${toValue} on ${toDate} `
  },
  "Net Income Margin": ({ value, toDate, numFormatter }) => {
    const finalValue = formatSummaryValue(value, numFormatter, "pct")
    return `Net Income Margins are forecasted to be ${finalValue} on ${toDate}`
  },
  "EPS Growth (CAGR)": ({ from, fromDate, to, toDate, numFormatter }) => {
    const fromFinalValue = formatSummaryValue(from, numFormatter, "currency")
    const toFinalValue = formatSummaryValue(to, numFormatter, "currency")
    return `EPS is forecasted to grow from ${fromFinalValue} on ${fromDate} to ${toFinalValue} on ${toDate} `
  },
  "P/E Change": ({ from, fromDate, to, toDate, numFormatter }) => {
    const fromValue = formatSummaryValue(from, numFormatter)
    const toValue = formatSummaryValue(to, numFormatter)
    return `P/E multiple is forecasted to go from ${fromValue}x on ${fromDate} to ${toValue}x on ${toDate}`
  },
  "Stock Price": ({ toDate }) => {
    return `The forecasted stock price as of ${toDate}`
  },
  "Total Return": ({ value, numFormatter, fromDate, toDate }) => {
    const finalValue = formatSummaryValue(value, numFormatter, "pct")
    return `The total return is estimated to be ${finalValue} from ${fromDate} to ${toDate}`
  },
  "IRR (Annual Return)": ({ value, fromDate, toDate, numFormatter }) => {
    const finalValue = formatSummaryValue(value, numFormatter, "pct")
    return `The Annualized Return or IRR is forecasted to be ${finalValue} from ${fromDate} to ${toDate}`
  },
}

const historicalRowsTemplate = [
  {
    ...revenueGrowthTemplate,
    formulas: historicalTableFormulas,
    labelFn: historicalLabelFunctionsByTitle?.["Revenue Growth (CAGR)"],
  },
  {
    ...netIncomeTemplate,
    formulas: netIncomeFormulas,
    labelFn: historicalLabelFunctionsByTitle?.["Net Income Margin"],
  },
  {
    ...epsGrowthTemplate,
    formulas: historicalTableFormulas,
    labelFn: historicalLabelFunctionsByTitle?.["EPS Growth (CAGR)"],
  },
  {
    ...priceOverEarningsTemplate,
    formulas: priceOverEarningsFormulas,
    labelFn: historicalLabelFunctionsByTitle?.["P/E Change"],
  },
  {
    ...stockPriceTemplate,
    format: "twoDigitCurrency",
    formulas: stockPriceFormulas,
    labelFn: historicalLabelFunctionsByTitle?.["Stock Price"],
  },
  {
    ...totalReturnTemplate,
    formulas: totalReturnHistoricalFormulas,
    labelFn: historicalLabelFunctionsByTitle?.["Total Return"],
  },
  {
    ...irrTemplate,
    formulas: irrHistoricalFormulas,
    labelFn: historicalLabelFunctionsByTitle?.["IRR (Annual Return)"],
  },
]

const togglePositiveNegativeValue = (originalValue, formattedValue) => {
  return originalValue < 0
    ? `<span style="color: red;">(${formattedValue})</span>`
    : formattedValue
}

const hubForecastMetrics = [
  "Revenue Growth (CAGR)",
  "EPS Growth (CAGR)",
  "P/E Change",
]

const forecastRowsTemplate = [
  {
    ...revenueGrowthTemplate,
    formula: "cagr",
    labelFn: forecastLabelFunctionsByTitle?.["Revenue Growth (CAGR)"],
  },
  {
    label: "Net Income Margin",
    key: "netIncomeMargin",
    formula: "latestForwardValue",
    labelFn: forecastLabelFunctionsByTitle?.["Net Income Margin"],
  },
  {
    ...epsGrowthTemplate,
    formula: "cagr",
    labelFn: forecastLabelFunctionsByTitle?.["EPS Growth (CAGR)"],
  },
  {
    label: "P/E Change",
    key: "priceOverEarningsMultiple",
    useDatesFrom: "eps",
    formula: "cagr",
    labelFn: forecastLabelFunctionsByTitle?.["P/E Change"],
  },
  {
    label: "Stock Price",
    key: "totalStockPriceIncludingDividends",
    formula: "latestForwardMinusOne",
    format: "twoDigitCurrency",
    highlight: true,
    labelFn: forecastLabelFunctionsByTitle?.["Stock Price"],
  },
  {
    label: "Total Return",
    key: "upsideOrDownsideToCurrentStockPrice",
    formula: "latestForwardMinusOne",
    highlight: true,
    labelFn: forecastLabelFunctionsByTitle?.["Total Return"],
  },
  {
    ...irrTemplate,
    formula: "irr",
    highlight: true,
    labelFn: forecastLabelFunctionsByTitle?.["IRR (Annual Return)"],
  },
]

const hubForecastRowsTemplate = hubForecastMetrics.map((key) => {
  return forecastRowsTemplate.find((item) => item?.label === key)
})

const headerMetrics = [
  {
    title: "52 Week High",
    relativeDateKey: 0,
    metricKeys: [{ name: "high", source: "trading" }],
    formatType: "currency",
    formula: "val",
    hideForBusinessMode: true,
  },
  {
    title: "52 Week Low",
    relativeDateKey: 0,
    metricKeys: [{ name: "low", source: "trading" }],
    formatType: "currency",
    formula: "val",
    hideForBusinessMode: true,
  },
  {
    title: "Market Cap (MM)",
    relativeDateKey: 0,
    metricKeys: [{ name: "marketcap", source: "company" }],
    formatType: "currency",
    formula: "val",
    hideForBusinessMode: true,
  },
  {
    title: "Enterprise Value (MM)",
    relativeDateKey: 0,
    metricKeys: [{ name: "tev", source: "company" }],
    formatType: "currency",
    formula: "val",
    hideForBusinessMode: true,
  },
]

const templates = {
  assumptionCases,
  calculatedMetrics: calculatedValuesToAddFromVendorData,
  estimatesDataArray: initVendorEstimatesDataArr,
  userInputDataArray: userInputDataArr,
  valuationDataArray: initVendorValuationDataArr,
  requiredMetrics,
  operatingDataRowArray: operatingDataDisplayedRowArr,
  summaryDataRowArray: summarydisplayedRowArr,
  historicalRows: historicalRowsTemplate,
  forecastRows: forecastRowsTemplate,
  headerMetrics,
}

const forecastYearsAhead = [5, 3, 1]
const historicalYearsToMatch = [-5, -3, -1]

export {
  templates,
  forecastYearsAhead,
  headerMetrics,
  historicalYearsToMatch,
  calculatedValuesToAddFromVendorData,
  initialFilterState,
  initVendorValuationDataArr,
  initVendorEstimatesDataArr,
  operatingDataDisplayedRowArr,
  summarydisplayedRowArr,
  userInputDataArr,
  createInitialFilterState,
  assumptionCases,
  defaultAssumptionCase,
  historicalRowsTemplate,
  forecastRowsTemplate,
  hubForecastRowsTemplate,
  requiredMetrics,
  historicalLabelFunctionsByTitle,
  forecastLabelFunctionsByTitle,
}
