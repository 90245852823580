const tierRestrictions = {
  free: {
    models: 3,
    historical: [-10],
  },
  plus: {
    models: 20,
  },
  pro: {
    models: 50,
  },
}

const getModelCount = (allModelsObject = {}) => {
  try {
    return Object.keys(allModelsObject).reduce((acc, key) => {
      const amountOfModels = allModelsObject[key].length || 0
      acc = acc + amountOfModels
      return acc
    }, 0)
  } catch (error) {
    return 0
  }
}

const checkForModelLimit = ({ userTier, amountOfModels }) => {
  if (userTier.endsWith("-free")) {
    return amountOfModels >= tierRestrictions.free.models
  }
  if (userTier.endsWith("-plus")) {
    return amountOfModels >= tierRestrictions.plus.models
  }
  return amountOfModels >= tierRestrictions.pro.models
}

export { checkForModelLimit, getModelCount, tierRestrictions }
